import React, { useContext, useState } from "react";
import { AppContext } from "../../context/user/AppContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useEffect } from "react";
import swal from "sweetalert";
import Select from "react-select";

const ShipperRatesExport = () => {
  const { mainClassB, customerData, setApiCalling } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tariffExportList, setTariffExportList] = useState([]);
  const [selectedLIstId, setSelectedLIstId] = useState([]);
  const [currencyList, setCurrenctList] = useState([]);
  const [quantityUnitsList, setquantityUnitsList] = useState([]);

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      gridTemplateColumns: "0px 100%",
    }),
  };
  const [state, setState] = useState({
    destination: "",
    tariff_export_id: "",
    currency: "",
    km: "",
    unit: "",
    tariff: "",
  });
  const [errMsg, setErrMsg] = useState({
    destination: "",
    currency: "",
    km: "",
    unit: "",
    tariff: "",
  });
  useEffect(() => {
    getCurrencyDropDown();
    getOtherDropDown();
    TarrifExportList();
  }, [selectedLIstId]);
  const getCurrencyDropDown = () => {
    setApiCalling(true);
    PostCallWithErrorResponse(ApiConfig.CURRENCY_DROPDOWN)
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        setCurrenctList(
          res.json.currency_list.map((currency) => {
            return {
              value: currency.currency_id,
              label: currency.currency_name,
            };
          })
        );
      })
      .catch((err) => {
        setApiCalling(false);
        console.log(err);
      });
  };
  const getOtherDropDown = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_SHIPPER_DROPDOWN,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setApiCalling(false);
          setquantityUnitsList(
            res.json.quantity_units.map((unit) => {
              return { value: unit.unit_id, label: unit.unit_name };
            })
          );
        }
      })
      .catch((err) => {
        setApiCalling(false);
        console.log(err);
      });
  };
  const EditTarrifExport = () => {
    setApiCalling(true);

    if (state.destination === "") {
      setErrMsg({ ...errMsg, destination: "Destination is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.currency === "") {
      setErrMsg({ ...errMsg, currency: "currency is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.km === "") {
      setErrMsg({ ...errMsg, km: "Km is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.unit === "") {
      setErrMsg({ ...errMsg, unit: "Unit is required" });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.tariff === "") {
      setErrMsg({ ...errMsg, tariff: "Tariff is required" });
      // window.scrollTo(0, 0);
      return;
    } else {
      const formData = new FormData();
      formData.append("customer_id", customerData.customer_id);
      formData.append("user_id", customerData.user_id);
      formData.append("tariff_export_id", state.tariff_export_id);
      formData.append("api_key", customerData.api_key);
      formData.append("currency", state.currency);
      formData.append("destination", state.destination);
      formData.append("km", state.km);
      formData.append("unit", state.unit);
      formData.append("tariff", state.tariff);

      // setState({
      //   api_key: customerData.api_key,
      //   customer_id: customerData.customer_id,
      //   user_id: customerData.user_id,
      //   rate_chart_id: selectedLIstId.rate_chart_id,
      //   destination: state.destination,
      //   currency: state.currency,
      //   km: state.km,
      //   unit: state.unit,
      //   tariff: state.tariff,
      // });

      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.TARRIF_EXPORT_EDIT,
        JSON.stringify({ ...customerData, ...state })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          handleClose("");
          if (res.json.result) {
            swal({
              title: "success",
              text: res.json.message,
              icon: "success",
              button: true,
            });
          } else if (res.json.result === false)
            swal({
              title: "warning",
              text: res.json.message,
              icon: "warning",
              button: true,
            });
          TarrifExportList();

          setApiCalling(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const TarrifExportList = () => {
    setApiCalling(true);
    setState({
      destination: selectedLIstId.tariff_export_destination,
      currency: selectedLIstId.currency_code,
      unit: selectedLIstId.unit_name,
      tariff_export_id: JSON.stringify(selectedLIstId.tariff_export_id),
    });

    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_EXPORT_LIST,
      JSON.stringify({
        ...customerData,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffExportList(res.json.tariff_export_list);
      setApiCalling(false);
    });
  };

  const handleClick = () => {
    handleShow();
  };
  return (
    <main className={`contract-main-section ${mainClassB}`} id="cxMainB">
      <div className="rates-main " style={{ margin: "0 20px" }}>
        <div className="transporter">
          {/* <div className="heading-top">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search"
            />
          </div> */}
          <div className="offerLoadsMainLists">
            <div className="mainLoadTable" style={{ overflow: "auto" }}>
              <table className="dataList" style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No.</label>
                    </th>
                    <th>
                      <label>Destination</label>
                    </th>
                    <th>
                      <label>Km</label>
                    </th>
                    <th>
                      <label>Currency</label>
                    </th>

                    <th>
                      <label>Unit of Measurement</label>
                    </th>
                    <th className="">
                      <label>Tariff (Birr) </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tariffExportList.map((tariffExportListDAta, index) => {
                    return (
                      <tr
                        className="Rowbgcolor"
                        key={"tariffExportListDAta" + index}
                      >
                        <td>
                          <label>{index + 1}</label>
                        </td>
                        <td>
                          <label>
                            {tariffExportListDAta.tariff_export_destination}
                          </label>
                        </td>
                        <td>
                          <label>{tariffExportListDAta.tariff_export_km}</label>
                        </td>
                        <td>
                          <label>{tariffExportListDAta.currency_code}</label>
                        </td>

                        <td>
                          <label>{tariffExportListDAta.unit_name}</label>
                        </td>

                        <td>
                          <label>
                            {tariffExportListDAta.tariff_export_tariff}
                          </label>
                        </td>
                        <td>
                          <label>
                            <button
                              className="editBTN-rateTable"
                              onClick={() =>
                                handleClick(
                                  setSelectedLIstId(tariffExportListDAta)
                                )
                              }
                            >
                              Edit
                            </button>
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="modal-edit-rate"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Shipper Rate (Export)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="main-rate-edit">
                <div className="row">
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Destination
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      disabled
                      aria-describedby="emailHelp"
                      value={state.destination}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, destination: "" });
                        setState({ ...state, destination: e.target.value });
                      }}
                    />
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      KM
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={state.km}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, km: "" });
                        setState({ ...state, km: e.target.value });
                      }}
                    />
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Currency
                    </label>
                    <Select
                      options={currencyList}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, currency: "" });
                        setState({ ...state, currency: e.value });
                      }}
                      styles={customStyles}
                      value={currencyList.filter(
                        (option) => option.value === state.currency
                      )}
                    />
                    {errMsg.currency.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.currency}</span>
                      </div>
                    )}
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Unit of Measurement
                    </label>
                    <Select
                      options={quantityUnitsList}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, unit: "" });
                        setState({
                          ...state,
                          unit: e.value,
                        });
                      }}
                      styles={customStyles}
                      value={quantityUnitsList.filter((option) => {
                        return option.value === state.unit;
                      })}
                    />
                  </div>
                  <div class="mb-3 col-md-6">
                    <label for="exampleInputEmail1" class="form-label">
                      Tariff (Birr)
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={state.tariff}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, tariff: "" });
                        setState({ ...state, tariff: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} id="closebtnrate">
                Close
              </Button>
              <Button onClick={EditTarrifExport} id="savebtnrate">
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default ShipperRatesExport;
