import React, { useContext, useEffect, useState } from "react";
import profile from "../../assets/images/profile_pic.jpg";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import ic_options_menu_white from "../../assets/images/ic_options_menu_white.svg";
import { Link, useParams } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

const ShipperDetails = () => {
  const params = useParams();
  const pdfExportComponent = React.useRef(null);
  let id = params.id;
  const [shipperDetails, setShipperDetails] = useState({});
  const { mainClass, customerData, ApiCaaling, setApiCalling } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShipperDetails();
  }, []);

  const getShipperDetails = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.SHIPPER_DETAILS,
      JSON.stringify({ ...customerData, shipper_id: id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) setShipperDetails(res.json.shipper_details);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const blockShipper = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_SHIPPER,
      JSON.stringify({ ...customerData, shipper_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        getShipperDetails();
      }
    });
  };
  const unBlockShipper = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_SHIPPER,
      JSON.stringify({ ...customerData, shipper_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        getShipperDetails();
      }
    });
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    link.click();
  };
  return (
    <div>
      <main className={`main-profile-section ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="prfile-body-section common-body">
            <div className="row">
              <div className="profile-banner">
                <div className="banner-img">
                  <div
                    className="option-dropdown dropdown "
                    id="transporterDetail-dropdown"
                  >
                    <a href="" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src={ic_options_menu_white} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link to="#" className="text-decoration-none">
                          <button className="dropdown-item" type="button">
                            View Details
                          </button>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="text-decoration-none">
                          <button className="dropdown-item" type="button">
                            Vehicle Details
                          </button>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={"/ContractShipper/" + shipperDetails.user_id}
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item" type="button">
                            View Contract
                          </button>
                        </Link>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Call
                        </button>
                      </li>
                      {shipperDetails.user_status === "blocked" ? (
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={unBlockShipper}
                          >
                            Unblock
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={blockShipper}
                          >
                            Block
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="details-profile">
                  <div className="profile-img">
                    <img
                      src={
                        shipperDetails.user_profile_pic
                          ? ApiConfig.BASE_URL_FOR_IMAGES +
                            shipperDetails.user_profile_pic
                          : profile
                      }
                      alt=""
                    />{" "}
                    <span className="name-profile mt-2">
                      {shipperDetails.company_name}
                    </span>
                  </div>
                  <PDFExport
                    PDFExport
                    ref={pdfExportComponent}
                    paperSize="auto"
                    margin={40}
                    fileName={`Report for ${new Date().getFullYear()}`}
                    author="KendoReact Team"
                  >
                    {/* BASIC INFO */}
                    <div className=" profile-basic-info row mt-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-heading">Basic Info</h5>

                        <button
                          className="btn p-1 my-2 expdf-btn"
                          onClick={exportPDFWithComponent}
                        >
                          Export Pdf
                        </button>
                      </div>

                      <hr />
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Company Name</label>
                        <p className="data">{shipperDetails.company_name}</p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Business Area</label>
                        <p className="data">{shipperDetails.business_area}</p>
                      </div>
                    </div>
                    {/* CONTACT PORSON */}
                    <div className="profile-contact-person row mt-4">
                      <h5 className="card-heading">Contact Person</h5>
                      <hr />
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Name</label>
                        <p className="data">{shipperDetails.contact_person}</p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Responsibility</label>
                        <p className="data">
                          {shipperDetails.user_responsibility}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Phone Number</label>
                        <p className="data">
                          {shipperDetails.contact_person_phone}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Email</label>
                        <p className="data">
                          {shipperDetails.contact_person_email}
                        </p>
                      </div>
                    </div>
                    {/* ADDRESS */}
                    <div className="profile-address row mt-4">
                      <h5 className="card-heading">Address</h5>
                      <hr />
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Country</label>
                        <p className="data">{shipperDetails.user_country}</p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Region</label>
                        <p className="data">{shipperDetails.user_region}</p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Phone </label>
                        <p className="data">{shipperDetails.user_mobile}</p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Alternative Phone</label>
                        <p className="data">
                          {shipperDetails.user_alternate_phone}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">Email</label>
                        <p className="data">{shipperDetails.user_email}</p>
                      </div>
                      <div className="col-lg-3 col-md-6 mt-2">
                        <label htmlFor="">P.O.Box</label>
                        <p className="data">{shipperDetails.user_po_number}</p>
                      </div>
                    </div>
                    {/* Documents */}
                    <div className="profile-contact-person row mt-4">
                      <h5 className="card-heading">Documents</h5>
                      <hr />
                      <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                        <label htmlFor="">Business License</label>
                        <div className="d-flex mx-1">
                          <img
                            src={
                              shipperDetails.user_business_license_document
                                ? ApiConfig.BASE_URL_FOR_IMAGES +
                                  shipperDetails.user_business_license_document
                                : document_image
                            }
                            alt=""
                            className="doc-img-style"
                          />
                          <div className="option-dropdown dropdown">
                            <a
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={ic_options_menu}
                                className="dot-icon"
                                alt=""
                              />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  onClick={() => {
                                    handleDownload(
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                        shipperDetails.user_business_license_document
                                    );
                                  }}
                                  className="dropdown-item"
                                  type="button"
                                >
                                  Download
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                        <label htmlFor="">TIN </label>
                        <div className="d-flex mx-1">
                          <img
                            src={
                              shipperDetails.user_tn_document
                                ? ApiConfig.BASE_URL_FOR_IMAGES +
                                  shipperDetails.user_tn_document
                                : document_image
                            }
                            alt=""
                            className="doc-img-style"
                          />
                          <div className="option-dropdown dropdown">
                            <a
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={ic_options_menu}
                                className="dot-icon"
                                alt=""
                              />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  onClick={() => {
                                    handleDownload(
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                        shipperDetails.user_tn_document
                                    );
                                  }}
                                  className="dropdown-item"
                                  type="button"
                                >
                                  Download
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                        <label htmlFor="">Grade Certificate</label>
                        <div className="d-flex mx-1">
                          <img
                            src={
                              shipperDetails.user_grade_certificate_document
                                ? ApiConfig.BASE_URL_FOR_IMAGES +
                                  shipperDetails.user_grade_certificate_document
                                : document_image
                            }
                            alt=""
                            className="doc-img-style"
                          />
                          <div className="option-dropdown dropdown">
                            <a
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={ic_options_menu}
                                className="dot-icon"
                                alt=""
                              />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  onClick={() => {
                                    handleDownload(
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                        shipperDetails.user_grade_certificate_document
                                    );
                                  }}
                                  className="dropdown-item"
                                  type="button"
                                >
                                  Download
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PDFExport>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ShipperDetails;
