import React, { useContext, useEffect, useState } from "react";
import ic_vehicle from "../../assets/images/AddVehicles/ic_vehicle.svg";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
const OfferVehicleListing = () => {
  // $(document).ready(function () {
  //   $(".dataList").DataTable();
  // });
  const [fullscreenpopup, setFullscreenpopup] = useState(false);
  let data = JSON.parse(localStorage.getItem("currentVehicleRequest"))
  const navigate = useNavigate();
  const [offerVehicleDetails, SetOfferVehicleDetails] = useState({ ...data, vehicle_details: [] })
  const [fullscreen, setFullscreen] = useState(true);
  const { mainClass, customerData } = useContext(AppContext);
  const [vehicleList, setVehicleList,] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState({ vehicle_model_no: "" })
  const [vehicleTypes, setVehicleTypes] = useState([])
  function handleShowscreen(breakpoint) {
    setFullscreen(breakpoint);
    setFullscreenpopup(true);
  }
  const handlefullscreen = () => setFullscreenpopup(true);
  const handlefullscreenClose = () => setFullscreenpopup(false);
  useEffect(() => {
    getVehicleList();
    getVehicleTypes()
  }, [])
  const getVehicleTypes = () => {
    postWithAuthCallWithErrorResponse(ApiConfig.VEHICLE_DROPDOWNLIST, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setVehicleTypes(res.json.vehicle_name)

        }
      }).catch(err => {
        console.log(err);
      })
  }
  useEffect(() => {
  }, [selectedVehicle])
  const getVehicleList = () => {
    postWithAuthCallWithErrorResponse(ApiConfig.VEHICLE_LIST, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setVehicleList(res.json.vehicle_list)
        }
      }).catch(err => { console.log(err); })
  }
  const submitOffer = () => {
    if (!offerVehicleDetails.vehicle_details.length)
      swal("Please select Vehicles")
    else {

      let veicleDetails = offerVehicleDetails.vehicle_details.map(vehicle => {
        return { vehicle_type: vehicle.vehicle_type, plate_no: vehicle.plate_number }
      })
      let requirements = offerVehicleDetails.requirements.map(req => {
        return { vehicle_type: req.vehicle_name_id, capacity: req.capacity }
      })
      let formdata = new FormData();
      

      postWithAuthCallWithErrorResponse(ApiConfig.MYREQUEST_OFFER_VEHICLE, JSON.stringify({
        api_key: customerData.api_key,
        customer_id: customerData.customer_id,
        user_id: customerData.user_id,
        load_id: offerVehicleDetails.vehicleRequest.trip_id,
        vehicle_details: veicleDetails,
        requirements,
        vehicle_availability_date: offerVehicleDetails.vehicle_availability_date
      }))
        .then(res => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          if (res.json.result) {
            swal(res.json.message)
            navigate("/DirectOrderHeader")
          }
        }).catch(err => console.log(err))
    }
  }
  return (
    <main className={`OfferVehicleListing-main ${mainClass}`} id="cxMain">
      <div className="text-end mt-4 mb-4">
        <Link to="#">
          <button
            type="submit"
            className="offer-vehicle-btn"
            onClick={handlefullscreen}
          >
            Offer Vehicle
          </button>
        </Link>
      </div>
      <div
        style={{
          width: "100%",
          overflow: "auto",

        }}
      >
        <table
          // className=" dataList"
          style={{ minWidth: "920px" }}
        >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Reference Number</th>
              <th>Owner Name</th>
              <th>Vehicle Type</th>
              <th>Model</th>
              <th>Plate No.</th>
              <th>Driver Name</th>
            </tr>
          </thead>
          <tbody>
            {offerVehicleDetails.vehicle_details && offerVehicleDetails.vehicle_details.map((vehicle, index) => {
              return (
                <tr className="Rowbgcolor " key={"selectedvehicle" + index}>

                  <td>{index + 1}</td>
                  <td>{vehicle.vehicle_id}</td>
                  <td>{vehicle.owner_name}</td>
                  <td>{vehicle.vehicle_type}</td>
                  <td>{vehicle.vehicle_model_no}</td>
                  <td>{vehicle.plate_number}</td>
                  <td>{vehicle.driver_name}</td>

                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className="ms-5 ps-5"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img className="" src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link to="#" className="text-decoration-none">
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>
                      <li></li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="submit" className="cancel-btn">
              Cancel
            </button>
          </a>
          <Link to="#">
            <button
              type="submit"
              className="continue-btn"
              onClick={handlefullscreen}
            >
              Offer Vehicle
            </button>
          </Link>
          <Link to="#">
            <button type="submit" className="continue-btn">
              Assign
            </button>
          </Link>
        </div>
      </div>

      <Modal
        show={fullscreenpopup}
        fullscreen={fullscreen}
        onHide={() => setFullscreenpopup(false)}
        className="popup-offer-vehicle"
      >
        <div className="hr-line pb-2">
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="card-heading">Offer Vehicle</h5>
            </Modal.Title>
          </Modal.Header>

        </div>

        <Modal.Body>
          <section className="offer-vehicles" style={{ marginBottom: "80px" }}>
            <div className="row">
              <div className="col-lg-3 col-md-6 ">
                <div className="top-item mt-2">
                  <label className="" style={{ color: "#66739B" }}>
                    Upload Vehicle Images
                  </label>
                  <div className="border-block ">
                    <div className="uploading p-4">
                      <img src={ic_vehicle} alt="" />
                      <p className="m-0 mt-3 drag-drop">
                        Drag & Drop files to upload
                      </p>
                      <p className="drag-drop">OR</p>
                      <Link to="#" style={{ color: "#4FBFA4" }}>
                        Browse Files
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="uploaded_items d-flex mt-4">
                  {selectedVehicle.vehicle_images && selectedVehicle.vehicle_images.map((img, index) => {
                    return (
                      <div className="item_list">
                        <img width={72} height={72}
                          className="vehicle_img"
                          src={ApiConfig.BASE_URL_FOR_IMAGES + img.vehicle_image_url}
                          alt=""
                        />
                        {/* <a href="#" className="close_item">
                          <img src={circle_cross} alt="" />
                        </a> */}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-lg-9 col-md-6">
                <div className="row">
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">
                        Reference Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_id}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Plate Number</label>
                      <select
                        type="search"
                        className="form-select"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => {
                          setSelectedVehicle(vehicleList.filter(vehicle => vehicle.plate_number === e.target.value)[0])
                        }}
                      >
                        <option >Select the vehicle</option>
                        {vehicleList &&
                          vehicleList.length &&
                          vehicleList.map((vehicle) => {
                            return (
                              <option value={vehicle.plate_number}>
                                {" "}
                                {vehicle.plate_number}{" "}
                              </option>
                            );
                          })}

                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputEmail1">Model</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_model_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Vehicle Type</label>
                      <input
                        className="form-control"
                        aria-label="Default select example"
                        value={selectedVehicle.vehicle_type}
                      />

                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Year of Manufacture
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.year_manufacture}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Chassis Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_chaise_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Motor Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_motor_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Gross Weight</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_gross_weight}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Vehicle Load Capacity in Quintal
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_capacity}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Current km</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.vehicle_initial_km}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Driver Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.driver_name}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Driver Driving License
                      </label>
                      <input
                        type="search"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={selectedVehicle.driver_licence_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="">
                <button type="submit" className="cancel-btn">
                  Cancel
                </button>
              </a>
              {/* <Link to="/OfferVehicleListing"> */}
              <button type="submit" className="continue-btn" onClick={() => {
                let list = [...offerVehicleDetails.vehicle_details, selectedVehicle]
                SetOfferVehicleDetails({ ...offerVehicleDetails, vehicle_details: list })
                setVehicleList(vehicleList.filter(vehicle => vehicle.plate_number != selectedVehicle.plate_number));
                handlefullscreenClose();
              }}>
                Add Vehicle
              </button>
              {/* </Link> */}
              {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="fix-offer-vehicles">
        <div style={{ overflow: "auto", marginRight: "100px" }}>
          <div className="d-flex justify-content-between p-2 mb-4" style={{ minWidth: "950px" }}>
            <div className="">
              <label htmlFor="">Reference Number</label>
              <p className="data">{offerVehicleDetails.vehicleRequest && offerVehicleDetails.vehicleRequest.load_reference_no}</p>
            </div>
            <div className=" ">
              <label htmlFor="">Goods Name</label>
              <p className="data">{offerVehicleDetails.vehicleRequest && offerVehicleDetails.vehicleRequest.goods_name}</p>
            </div>
            <div className=" ">
              <label htmlFor="">Company Name</label>
              <p className="data">{offerVehicleDetails.vehicleRequest && offerVehicleDetails.vehicleRequest.company_name}</p>
            </div>
            <div className="">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{offerVehicleDetails.vehicleRequest && offerVehicleDetails.vehicleRequest.cargo_type}</p>
            </div>
            <div className="">
              <label htmlFor="">Quantity</label>
              <p>
                <span className="data m-0">{offerVehicleDetails.vehicleRequest && offerVehicleDetails.vehicleRequest.quantity}</span>{" "}
                <Link to="#" className="ms-2 ViewSpecifications-link">
                  View Specifications
                </Link>
              </p>
            </div>
            <div className="">
              <label htmlFor="">Delivery Type</label>
              <p className="data">{offerVehicleDetails.vehicleRequest && offerVehicleDetails.vehicleRequest.delivery_type}</p>
            </div>
          </div>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="">
                <button type="cancel" className="cancel-btn">
                  Cancel
                </button>
              </a>
              {/* <Link to="/DirectOrderHeader"> */}
              <button type="submit" className="continue-btn "
                onClick={submitOffer}

              >
                Send Offer
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </main >
  );
};

export default OfferVehicleListing;
