import React, { useState } from "react";
import seeker_default from "../../assets/images/seeker_default.svg";
import ic_call_big from "../../assets/images/ic_call_big.svg";

import ic_arrow from "../../assets/images/ic_arrow.svg";

import { Link } from "react-router-dom";

const FreightsDetails = () => {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
  

      <div className=" ">
        <div className=""></div>

          <div className="section-body " >
        <div className="section-bodyOnfremenu"></div>
        <div className="row ">
          <div className="toggle-parent">
            <div className="toggle_Click">
              <button className="btn">

              <img className="toggle_button" src={ic_arrow} alt="" />
              </button>
            </div>
          </div>

          <div className="ontitle text-center mt-2 pb-2">
            <h4 className="card-heading">Ongoing Freights</h4>
          </div>
          <div className="inside">
            <div className="d-flex  justify-content-between first_ongoing border-bottom">
              <img className="mb-4 " src={seeker_default} alt="" />
              <div className="m-3">
                <h6 className="card-title">My Freight</h6>
                <label>Ibris Brisbane - Ann St</label>

                <Link to="#" className="viewdetail">
                  {" "}
                  View Details
                </Link>
              </div>
              <a to="">
                <img className="mt-3 me-3" src={ic_call_big} alt="" />
              </a>
            </div>

            <div className="d-flex justify-content-between first_ongoing border-bottom">
              <img className="mb-4 " src={seeker_default} alt="" />
              <div className="m-3">
                <h6 className="card-title">My Freight</h6>
                <label>Ibris Brisbane - Ann St</label>

                <Link to="#" className="viewdetail">
                  {" "}
                  View Details
                </Link>
              </div>
              <Link to="">
                <img className="mt-3 me-3" src={ic_call_big} alt="" />
              </Link>
            </div>
            <div className="d-flex  justify-content-between first_ongoing border-bottom">
              <img className="mb-4 " src={seeker_default} alt="" />
              <div className="m-3">
                <h6 className="card-title">My Freight</h6>
                <label>Ibris Brisbane - Ann St</label>

                <Link to="#" className="viewdetail">
                  {" "}
                  View Details
                </Link>
              </div>
              <Link to="">
                <img className="mt-3 me-3" src={ic_call_big} alt="" />
              </Link>
            </div>
          </div>
        </div>
        </div> 
      </div>
    </React.Fragment>
  );
};

export default FreightsDetails;
