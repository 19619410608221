import React, { useContext } from "react";
// import "../../assets/css/AddGoods.scss";
import package_image_01 from "../../assets/images/AddVehicles/package_image_01.png";
import package_image_02 from "../../assets/images/AddVehicles/package_image_02.png";
import ic_vehicle from "../../assets/images/AddVehicles/ic_vehicle.svg";
import circle_cross from "../../assets/images/circle_cross.svg";
import drag_drop_default from "../../assets/images/drag_drop_default.svg";
import ic_remove from "../../assets/images/ic_remove.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";


const AddGoods = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={`main-section-AddGoods ${mainClass}`} id="cxMain">
        <div className="main-body-AddGoods">
          {/* From To section */}
          <section className="from-to-section">
            <div className="row">
              <div className="col-lg-6">
                {/* Left section */}
                <div className="left-section common-body p-3 mt-4">
                  <div className="row">
                    <div className="top-content">
                      <h5 className="card-heading">From </h5>
                      <div className="form-check  check-time">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Time
                        </label>
                      </div>
                      <hr />
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          Estimated Start Date{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          Estimated Start Time{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleFormControlTextarea1">Address</label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">City</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Country</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right section */}
              <div className="col-lg-6">
                <div className="right-section common-body p-3 mt-4">
                  <div className="row">
                    <div className="top-content">
                      <h5 className="card-heading">To </h5>
                      <div className="form-check  check-time">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Time
                        </label>
                      </div>
                      <hr />
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          Estimated End Date{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          Estimated End Time{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleFormControlTextarea1">Address</label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Country</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">City</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Goods Details */}
          <secton className="goods-details ">
            <div className=" common-body mt-4 p-3">
              <div className="row">
                <div className="top-content">
                  <h5 className="card-heading">Goods Details</h5>
                  <hr />
                </div>
                {/* left section */}
                <div className="col-lg-6 mt-4">
                  <div className="top-item">
                    <label style={{ color: "#66739B" }}>Upload Freight Images</label>
                    <div className="border-block">
                      <div className="uploading">
                        <img src={ic_vehicle} alt="" />
                        <p className="m-0 mt-3 drag-drop">
                          Drag & Drop files to upload
                        </p>
                        <p className="drag-drop">OR</p>
                        <a href="#" style={{ color: "#4D9DE7" }}>
                          Browse Files
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="uploaded_items d-flex mt-4">
                    <div className="item_list">
                      <img
                        className="vehicle_img"
                        src={package_image_01}
                        alt=""
                      />
                      <a href="#" className="close_item">
                        <img src={circle_cross} alt="" />
                      </a>
                    </div>
                    <div className="item_list">
                      <img
                        className="vehicle_img"
                        src={package_image_02}
                        alt=""
                      />
                      <a href="#" className="close_item">
                        <img src={circle_cross} alt="" />
                      </a>
                    </div>
                  </div>
                </div>{" "}
                {/* right section */}
                <div className="col-lg-6 mt-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Cargo Type</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Quantity</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Select Unit</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">
                          Expected Arrival Time
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </secton>
          {/* Documents section */}
          <section className="documents-section">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content mb-3">
                  <h5 className="card-heading">Documents</h5>
                  <hr />
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Packing List</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Bill Of Landing</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Insurance, Comprehensive & Cargo</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Tax Identification Number</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Trade License</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">VAT Registration Certificate</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Agreement With Abay</label>
                  <div className="drop_block mb-3 mt-3">
                    <div className="vertical_m ">
                      <img src={drag_drop_default} alt="" />
                    </div>
                  </div>
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>Image.Jpg</label>
                    <a href="#">
                      <img src={ic_remove} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="" >
              <button type="cancel" className="cancel-btn">
                Cancel
              </button>
            </a>
            <Link to="/GoodsDetails" >
              <button type="submit" className="continue-btn">
                Add
              </button>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddGoods;
