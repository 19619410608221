import React, { useContext } from "react";
import profile from "../../assets/images/Skyscrapers.jpg";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";

const Profile = () => {
  const { mainClass, profileDetails } = useContext(AppContext);
  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={`main-profile-section ${mainClass}`} id="cxMain">
        <div className="prfile-body-section common-body">
          <div className="row">
            <div className="profile-banner">
              <div className="banner-img"></div>
              <div className="details-profile">
                <div className="profile-img">
                  <img
                    src={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                      (profileDetails.profile && profileDetails.profile
                        ? profileDetails.profile.user_profile_pic
                        : profile)
                    }
                    alt=""
                  />{" "}
                  <span className="name-profile mt-2">{profileDetails.profile.user_name}</span>
                </div>
                {/* BASIC INFO */}
                <div className=" profile-basic-info row mt-4">
                  <h5 className="card-heading">Basic Info</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Company Name</label>
                    <p className="data">{profileDetails.profile.user_contact_name}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Company Type</label>
                    <p className="data">Logistics Services</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Total Fleet Size</label>
                    <p className="data">{profileDetails.profile.user_fleet_size}</p>
                  </div>
                </div>
                {/* CONTACT PORSON */}
                <div className="profile-contact-person row mt-4">
                  <h5 className="card-heading">Contact Person</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Name</label>
                    <p className="data">{profileDetails.profile.user_contact_name}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Responsibility</label>
                    <p className="data">Manager</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Phone Number</label>
                    <p className="data">{profileDetails.profile.user_po_number}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Email</label>
                    <p className="data">{profileDetails.profile.user_email}</p>
                  </div>
                </div>
                {/* ADDRESS */}
                <div className="profile-address row mt-4">
                  <h5 className="card-heading">Address</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Country</label>
                    <p className="data">{profileDetails.profile.user_country}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Region</label>
                    <p className="data">{profileDetails.profile.user_region}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Phone </label>
                    <p className="data">{profileDetails.profile.user_po_number}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Alternative Phone</label>
                    <p className="data">{profileDetails.profile.user_alternate_phone}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Email</label>
                    <p className="data">{profileDetails.profile.user_email}</p>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">P.O.Box</label>
                    <p className="data"></p>
                  </div>
                </div>
                {/* Documents */}
                <div className="profile-contact-person row mt-4">
                  <h5 className="card-heading">Documents</h5>
                  <hr />
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Business License</label>
                    <div className="menu-option d-flex align-items-start">
                      <img src={document_image} alt="" />
                      <img src={ic_options_menu} alt="" className="dot-icon" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">Grade Certificate</label>
                    <div className="menu-option d-flex align-items-start">
                      <img src={document_image} alt="" />
                      <img src={ic_options_menu} alt="" className="dot-icon" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-2">
                    <label htmlFor="">TIN</label>
                    <div className="menu-option d-flex align-items-start">
                      <img src={document_image} alt="" />
                      <img src={ic_options_menu} alt="" className="dot-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
