import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/Login/logo.svg";

const ForgotPassword = () => {
  return (
    <section className=" container-fluid login-main-section ">
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content setting-pass-left" >
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics</h3>
            <p className="left-login-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <div className="GobackBtn-title">
              <h3>Forgot Password</h3>
              <Link to="/" className="Go-back-btn">
                Go Back
              </Link>
            </div>
            <p className="welcome-login">
              Please enter your email id to continue
            </p>
            <form action="">
              <div className="mb-3 row mt-4">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-12">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    required
                  />
                  <span className="text-danger"></span>
                </div>
              </div>
              <div className="login-btn">
                <Link
                  to="/ForgotOTP"
                  className=" col-sm-12 col-md-12 col-lg-12"
                >
                  CONTINUE
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
