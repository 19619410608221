import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import ic_veh_id_image from "../../assets/images/AddVehicles/package_image_01.png";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import swal from "sweetalert";

const MatchVechicleWithCargo = () => {
  const [loading, setLoading] = useState(false);
  var commaNumber = require("comma-number");

  const navigate = useNavigate();
  const { mainClassB, customerData, ApiCaaling, setApiCalling } =
    useContext(AppContext);
  const veh_idiousData = JSON.parse(localStorage.getItem("request"));
  const [loadId, setLoadID] = useState(
    veh_idiousData ? veh_idiousData.trip_id : ""
  );
  const[matchingValue, setMatchingValue] = useState([{index: '', matchingVal: '', veh: {}}]);
  //const[matchingValue, setMatchingValue] = useState([]);
  const [unassignedCargo, setUnassignedCargo] = useState("");
  const [loadUnit, setLoadUnit] = useState("");
  const [tripCategory, setTripCategory] = useState("direct");
  const [vehicle_cargo_mappings, setVehicle_cargo_mappings] = useState([]);
  const [transporterVehicleList, setTransporterVehicleList] = useState([]);
  const [qtyErrro, setQtyError] = useState(false);
  const [unitType, setUnitType] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [totalEnteredQty, setTotalEnteredQty] = useState(0);
  const [containerType, setContainerType] = useState("");
  const [cargoType, setCargoType] = useState("");
  let vals = [];
  

  const quintalValue = Number(unassignedCargo) * 10;


  const submit = () => {
    let newMappingValues = [];
    let newMapping = {
      trip_vehicle_id: '',
      quantity: '',
      transporter_id: '',
      enteredValue: ''
   , 
   veh_capacity: '',
   vehicle_type: ''
 }; 
  let valid_entered_value = false;
  if (vehicle_cargo_mappings.length > matchingValue.length) {
      swal({
        text: 'Please enter all correct values.',
        icon: "warning",
        dangerMode: true,
      });
      valid_entered_value = false;
    }else if (vehicle_cargo_mappings.length === matchingValue.length) {
      valid_entered_value = true;
    }
    matchingValue.map(e=> { 
      let enteredValue = (e.matchingVal === '') ? '0' : e.matchingVal;
      if (valid_entered_value && isNaN(enteredValue)) {
        valid_entered_value = false;
        swal({
          text: 'Please enter correct value.',
          icon: "warning",
          dangerMode: true,
        });
        enteredValue = '';
      } 
      else if (valid_entered_value && enteredValue === '0') {
        valid_entered_value = false;
        swal({
          text: 'Please enter value other than 0.',
          icon: "warning",
          dangerMode: true,
        });
          enteredValue = '';
      }  
    });
    matchingValue.map(e=> {
      let enteredValue = (e.matchingVal === '') ? 0 : e.matchingVal;
      if (valid_entered_value && enteredValue <= 0) {
        swal({
          text: 'Please enter value greater than 0.',
          icon: "warning",
          dangerMode: true,
        });
      }
    });

    if (valid_entered_value) {

      matchingValue.forEach(e=> {  
        vehicle_cargo_mappings.map(v=>{
          if ((v.trip_vehicle_id === e.veh.trip_vehicle_id && 
              v.vehicle_type === e.veh.vehicle_type && 
              v.trip_vehicle_trailer_id === e.veh.trip_vehicle_trailer_id &&
              v.trip_vehicle_vehicle_id===e.veh.trip_vehicle_vehicle_id)) {
            newMapping = {
                trip_vehicle_id: e.veh.trip_vehicle_id,
                quantity: quintalValue,
                transporter_id: v.transporter_id,
                enteredValue: (e.matchingVal === '') ? 0 : e.matchingVal,
                veh_capacity: e.veh.veh_capacity,
                vehicle_type: v.vehicle_type
           }; 
           
           newMappingValues.push(newMapping)
          } 
          
        })
    });

      setVehicle_cargo_mappings(
        newMappingValues
      );
      
        newMappingValues.map((item, index) => {
          if (
            item.quantity >
            item.veh_capacity
          ) {
            setQtyError(true);
          } else {
            setQtyError(false);
          }
          setItemQuantity(
            item.quantity
          );
        });
        
        setApiCalling(true);
        postWithAuthCallWithErrorResponse(
          ApiConfig.VEHICLE_CARGO_MATCHMAKING,
          JSON.stringify({
            ...customerData,
            load_id: loadId,
            load_capacity: unassignedCargo,
            vehicle_cargo_mapping: newMappingValues,
            cargo_type: cargoType,
            container_type: containerType ? containerType : ""
          })
        ).then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setApiCalling(false);
          if (res.json.result) {
            swal(res.json.message);
            navigate("/DirectOrderHeader");
          } else if (res.json.remaining_quantity) {
            swal({
              title: `${
                "Remaining Quantity" +
                commaNumber(Math.round(res.json.remaining_quantity).toFixed(2))
              }`,
  
              text: res.json.message,
              icon: "warning",
              dangerMode: true,
            }).then(navigate("/VehicleRequest"));
          } else {
            if (res.json.message) {
            swal({
              text: res.json.message,
              icon: "warning",
              dangerMode: true,
              });
            }else {
            swal({
              text: 'Enter Correct Value',
              icon: "warning",
              dangerMode: true,
            });
          }
          }
        });
     }
  }

  const getTransporterVehicleList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.MATCHMAKING_TRANSPORTER_VEHICLE_LIST,
      JSON.stringify({ ...customerData, load_id: loadId })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        setTransporterVehicleList(res.json.transporter_list);
        setUnassignedCargo(res.json.load_capacity);
        setLoadUnit(res.json.unit);
        setTripCategory(res.json.trip_category);
        setContainerType(res.json.container_type);
        setCargoType(res.json.cargo_type);
        let vehicleData = [];
        res.json.transporter_list.map((vehicle) => {
          vehicle.vehicle_list.map((item) => {
            let vehiclelist = {

              trip_vehicle_id: item?.trip_vehicle_id,
              quantity: item?.vehicle_capacity,
              transporter_id: vehicle?.transporter_id,
              vehicle_type: item?.vehicle_type,
              trip_vehicle_vehicle_id: item.trip_vehicle_vehicle_id,
              trip_vehicle_trailer_id: item.trip_vehicle_trailer_id !== '' ? 
                  item.trip_vehicle_trailer_id : '',
            };
            vehicleData.push(vehiclelist);
          });
        });

        setVehicle_cargo_mappings(vehicleData);

        setUnitType(res.json.unit);
      }
    });
  };
  function matchingValues(mappingIndex, event, veh) {
    
    matchingValue.map(e=> {if (e.index===mappingIndex) {
      e.matchingVal = event.target.value;
      e.veh = veh;
      let matchingObject = {index: mappingIndex, matchingVal: event.target.value, veh: veh};
    }else{
      if (e.index === "") {
        e.index = mappingIndex;
        e.matchingVal = event.target.value;
        e.veh = veh;
      } else {
      let arrayValues = [];
      matchingValue.forEach(e=>{
        if(e.index !== mappingIndex) {
          arrayValues.push(e);
        }
      });
      let matchingObject = {index: mappingIndex, matchingVal: event.target.value, veh: veh};
      arrayValues.push(matchingObject);
      setMatchingValue(arrayValues);
    }
    }});
    let total = 0;
    matchingValue.forEach(e=> {
      total += parseInt(e.matchingVal);
      setTotalEnteredQty(total);
    })

  }
  useEffect(() => {
    getTransporterVehicleList();
    return () => {};
  }, []);
  const cancelAllRequests = ()=> {
    setApiCalling(true);
    
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_CARGO_CANCEL_ALL_VEHICLES,
      JSON.stringify({
        ...customerData,
        transporter_vehicle_list: transporterVehicleList,
        //vehicle_cargo_mapping: newMappingValues,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        navigate("/DirectOrderHeader");
      }
    });
  }

  const cancelVehicle = (vehicle)=> {
    setApiCalling(true);
  
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_CARGO_CANCEL_VEHICLE,
      JSON.stringify({
        ...customerData,
        trip_vehicle_id: vehicle.trip_vehicle_id,
        transporter_id: vehicle.transporter_id,
        load_id: vehicle.load_id,
        //vehicle_cargo_mapping: newMappingValues,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        navigate("/DirectOrderHeader");
      }
    });
  }
  return (
    <React.Fragment>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <main className={`direct-order-main ${mainClassB}`} id="cxMainB">
          <div style={{ margin: "20px" }}>
            <div className=" all-request">
              <div className="  ">
                <div className="offerLoadsMainLists">
                  <div className="mainLoadTable">
                    <table
                      className="dataList custom-table"
                      style={{ minWidth: "1170px" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <label>S.No</label>
                          </th>
                          <th>
                            <label>Transporter</label>
                          </th>
                          <th>
                            <label>Selected Vehicle</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transporterVehicleList?.map((list, index) => {
                          
                          return (
                            <tr key={"list" + index}>
                              <td className="custom-width1">{index + 1}</td>
                              <td className="custom-width">
                                <div className="transporter-details">
                                  <div className="row g-0 mb-3 align-items-center">
                                    <div className="col-sm-3">
                                      <div className="tr-img-box">
                                        <img
                                          src={
                                            list.user_profile_pic &&
                                            list.user_profile_pic.length > 0
                                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                list.user_profile_pic
                                              : ic_veh_id_image
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-9 ">
                                      <div className="tr-text-box">
                                        <div className="tr-name">
                                          <p className="px-2">
                                            {list.user_name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="custom-width">
                                
                                {list?.vehicle_list.map(
                                  (vehicle, innerIndex) => {
                                    const mappingIndex =
                                      (index + 1) * 1000 +
                                      innerIndex;

                                      //setMpindex(mappingIndex);
                                    return (
                                      <div
                                        className="transporter-details"
                                        key={"vehicle" + innerIndex}
                                      >
                                        <div className="row g-0 mb-3">
                                          <div className="col-sm-3">
                                            <div className="tr-img-box">
                                              <img
                                                src={
                                                  vehicle.vehicle_image &&
                                                  vehicle.vehicle_image.length >
                                                    0
                                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                      vehicle.vehicle_image
                                                    : ic_veh_id_image
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          {cargoType == "Container" ?
                                          <div className="col d-flex justify-content-between align-items-center ">
                                            <div className="tr-text-box">
                                              <div className="tr-tarrif">
                                                <p>
                                                  Vehicle Type:{" "}
                                                  <span>
                                                    {vehicle.vehicle_type}
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="tr-Destination">
                                                <p>
                                                  Plate No.:{" "}
                                                  <span>
                                                    {vehicle.plate_number}
                                                  </span>
                                                </p>
                                                
                                                {vehicle.vehicle_type === "Power" || 
                                                  vehicle.vehicle_type === "Truck Trailer" ? 
                                                    <p>Vehicle Capacity:{" "}
                                                <span>
                                                  {vehicle?.truck_cap}
                                                  (Quintals)
                                                </span>
                                              </p>:<></>}
                                              </div>
                                              {/* {cargoType==="Power" ?
                                              <div className="tr-Destination">
                                              <p>
                                                Vehicle Capacity:{" "}
                                                <span>
                                                  {vehicle.truck_cap} {" "}
                                                  (Quintals)
                                                </span>
                                              </p>
                                            </div>
                                            : <></>}
                                            {cargoType==="Truck Trailer" ?
                                              <div className="tr-Destination">
                                              <p>
                                                Vehicle Capacity:{" "}
                                                <span>
                                                  {vehicle.truck_cap} {" "}
                                                  (Quintals)
                                                </span>
                                              </p>
                                            </div>
                                            : <></>} */}
                                            {/* {cargoType==="Power" ?
                                              <div className="tr-Destination">
                                                <p>
                                                  Power Container Types:{" "}
                                                  <span>
                                                    <br></br>
                                                    {vehicle?.vehicle_offer_container_values[0] ?
                                                      "1. " + vehicle?.vehicle_offer_container_values[0] 
                                                        :  "-" } {" "}
                                                    <br></br>
                                                    {vehicle?.vehicle_offer_container_values[1] ? 
                                                      "2. " + vehicle.vehicle_offer_container_values[1] : "" }
                                                  </span>
                                                </p>
                                              </div>
                                              : <></>} */}
                                              {(cargoType==="Container" && (vehicle.vehicle_type === "Power"
                                                || vehicle.vehicle_type === "Truck Trailer")) ?
                                              <div className="tr-Destination">
                                                <p>
                                                  Container Type:{" "}
                                                  <span>
                                                    <br></br>
                                                    {vehicle?.vehicle_offer_container_values[0] ?
                                                     "1. " + vehicle?.vehicle_offer_container_values[0] : "" } {" "}
                                                    <br></br>
                                                    {vehicle?.vehicle_offer_container_values[1] ? 
                                                      "2. " + vehicle?.vehicle_offer_container_values[1] : "" } 
                                                    
                                                  </span>
                                                </p>
                                              </div>
                                              : <></>}
                                              {/* {containerType && <div className="tr-Destination">
                                                <p>
                                                  Container Type:{" "}
                                                  <span>
                                                    {containerType}
                                                    
                                                  </span>
                                                </p>
                                              </div>} */}
                                              {/* <div className="tr-Destination">
                                                <p>
                                                  Have Trailer:{" "}
                                                  <span>
                                                    {vehicle.trip_vehicle_trailer_id ? 'yes': 'no'}{" "}
                                                    
                                                  </span>
                                                </p>
                                              </div> */}
                                              <br></br>
                                              {vehicle.trailer_plate_no ? 
                                              <div className="tr-Destination">
                                                <p>
                                                  Vehicle Type:{" "}
                                                  <span>
                                                    Trailer
                                                  </span>
                                                </p>
                                                <p>
                                                  Trailer Plate No:{" "}
                                                  <span>
                                                    {vehicle?.trailer_plate_no}
                                                    
                                                  </span>
                                                </p>
                                                <p>
                                                Trailer Capacity:{" "}
                                                <span>
                                                  {vehicle?.trailer_cap}
                                                  (Quintals)
                                                </span>
                                              </p>
                                                <p>
                                                  Container Types:{" "}

                                                  <span>
                                                    <br></br>
                                                    {vehicle?.vehicle_offer_trailer_container_values[0] ?
                                                     "1. " + vehicle?.vehicle_offer_trailer_container_values[0] : "" } {" "}
                                                    <br></br>
                                                    {vehicle?.vehicle_offer_trailer_container_values[1] ? 
                                                      "2. " + vehicle?.vehicle_offer_trailer_container_values[1] : "" } 
                                                    
                                                  </span>
                                                </p>
                                              </div> : ""}
                                              
                                            </div>
                                            {cargoType === "Container" ?
                                            <div className="d-flex align-items-center">
                                              <div class="input-group mb-3">
                                              <select
                                                id={mappingIndex}
                                                className="form-select"
                                                aria-label="Default select example"
                                                onClick={(e) => {
                                                  matchingValues(mappingIndex, e, vehicle);
                                                }}
                                              >
                                                <option value="0" selected>
                                                  0
                                                </option>
                                                {((vehicle.vehicle_type === "High Bed"  || (vehicle.vehicle_type === "Low Bed")) 
                                                  && (vehicle.vehicle_offer_trailer_container_values[0] === "Open top 20ft"
                                                    || vehicle.vehicle_offer_trailer_container_values[0] === "Flat 20ft"
                                                    || vehicle.vehicle_offer_trailer_container_values[0] === "RF (Refer Container) 20ft"
                                                    || vehicle.vehicle_offer_trailer_container_values[0] === "Regular Container 20ft"
                                                  ) && (vehicle.vehicle_type === "Truck Trailer" )) ? <></> : <option value="1">1</option>}
                                                {(((vehicle?.vehicle_offer_trailer_container_values && vehicle?.vehicle_offer_trailer_container_values[0]) &&
                                                vehicle?.vehicle_offer_container_values[0]) || ((vehicle.vehicle_type === "High Bed" || vehicle.vehicle_type === "Low Bed")
                                                  && (vehicle.vehicle_offer_trailer_container_values[0] === "Open top 20ft"
                                                    || vehicle.vehicle_offer_trailer_container_values[0] === "Flat 20ft"
                                                    || vehicle.vehicle_offer_trailer_container_values[0] === "RF (Refer Container) 20ft"
                                                    || vehicle.vehicle_offer_trailer_container_values[0] === "Regular Container 20ft"
                                                  ))) ?
                                                  <option value="2">2</option> : 
                                                    <></>}
                                                    
                                              </select>
                                                
                                                <span class="input-group-text">
                                                  Containers
                                                </span>
                                                
                                                
                                                  
                                                <button className="btn-danger" onClick={(e) => {                
                                                    e.preventDefault();
                                                    cancelVehicle(vehicle);
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                                
                                              </div>

                                              <div className="assign-cargo">
                                                <p>
                                                  {
                                                    vehicle.cargo_details
                                                      ?.cargo_type_ref_no
                                                  }
                                                </p>
                                              </div>
                                            </div> : 
                                            <div className="d-flex align-items-center">
                                            <div class="input-group mb-3">
                                              <input
                                                value={matchingValue.matchingVal}
                                                type="text"
                                                class="form-control rounded-0"
                                                onKeyUp={(e) => {
                                                  matchingValues(mappingIndex, e, vehicle);
                                                }}
                                              />
                                              <span class="input-group-text">
                                                Quintals
                                              </span>
                                              
                                              
                                                
                                              <button className="btn-danger" onClick={(e) => {                
                                                  e.preventDefault();
                                                  cancelVehicle(vehicle);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              
                                            </div>

                                            <div className="assign-cargo">
                                              <p>
                                                {
                                                  vehicle.cargo_details
                                                    ?.cargo_type_ref_no
                                                }
                                              </p>
                                            </div>
                                          </div>
                                            }
                                            <div></div>
                                            {/* {(vehicle.trip_vehicle_trailer_id &&
                                              
                                              <div className="d-flex align-items-center">
                                              
                                              <p> Trailer </p>
                                              <div class="input-group mb-3">
                                                <input
                                                  value={matchingValue.matchingVal}
                                                  type="text"
                                                  class="form-control rounded-0"
                                                  onChange={(e) => {
                                                    matchingValues(mappingIndex, e, vehicle);
                                                  }}
                                                />
                                                <span class="input-group-text">
                                                  Quintals
                                                </span>
                                                
                                                
                                                  
                                                <button className="btn-danger" onClick={(e) => {                
                                                    e.preventDefault();
                                                    cancelVehicle(vehicle);
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                                
                                              </div>

                                              <div className="assign-cargo">
                                                <p>
                                                  {
                                                    vehicle.cargo_details
                                                      ?.cargo_type_ref_no
                                                  }
                                                </p>
                                              </div>
                                            </div> )} */}
                                          
                                          </div> : cargoType === "Vehicle" ? 
                                          <div className="col d-flex justify-content-between align-items-center ">
                                          <div className="tr-text-box">
                                            <div className="tr-tarrif">
                                              <p>
                                                Vehicle Type:{" "}
                                                <span>
                                                  {vehicle.vehicle_type}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="tr-Destination">
                                              <p>
                                                Plate No.:{" "}
                                                <span>
                                                  {vehicle.plate_number}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="tr-Destination">
                                              <p>
                                                Vehicle Capacity:{" "}
                                                <span>
                                                  {vehicle?.trailer_cap} {" "}
                                                  (Vehicles)
                                                </span>
                                              </p>
                                            </div>
                                            
                                            {/* {containerType && <div className="tr-Destination">
                                              <p>
                                                Container Type:{" "}
                                                <span>
                                                  {containerType}
                                                  
                                                </span>
                                              </p>
                                            </div>} */}
                                            {/* <div className="tr-Destination">
                                              <p>
                                                Have Trailer:{" "}
                                                <span>
                                                  {vehicle.trip_vehicle_trailer_id ? 'yes': 'no'}{" "}
                                                  
                                                </span>
                                              </p>
                                            </div> */}
                                            {vehicle.trailer_plate_no ? 
                                            <div className="tr-Destination">
                                              <p>
                                                Vehicle Type:{" "}
                                                <span>
                                                  Trailer
                                                </span>
                                              </p>
                                              <p>
                                                Trailer Plate No:{" "}
                                                <span>
                                                  {vehicle?.trailer_plate_no}
                                                  
                                                </span>
                                              </p>
                                              <p>
                                                Trailer Capacity:{" "}
                                                <span>
                                                  {vehicle?.trailer_cap}
                                                  (Vehicles)
                                                </span>
                                              </p>
                                            </div> : ""}
                                            
                                          </div>
                                          {cargoType === "Container" ?
                                          <div className="d-flex align-items-center">
                                            <div class="input-group mb-3">
                                              <input
                                                value={matchingValue.matchingVal}
                                                type="text"
                                                class="form-control rounded-0"
                                                onKeyUp={(e) => {
                                                  matchingValues(mappingIndex, e, vehicle);
                                                }}
                                              />
                                              <span class="input-group-text">
                                                Containers
                                              </span>
                                              
                                              
                                                
                                              <button className="btn-danger" onClick={(e) => {                
                                                  e.preventDefault();
                                                  cancelVehicle(vehicle);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              
                                            </div>

                                            <div className="assign-cargo">
                                              <p>
                                                {
                                                  vehicle.cargo_details
                                                    ?.cargo_type_ref_no
                                                }
                                              </p>
                                            </div>
                                          </div> : 
                                          <div className="d-flex align-items-center">
                                          <div class="input-group mb-3">
                                            <input
                                              value={matchingValue.matchingVal}
                                              type="text"
                                              class="form-control rounded-0"
                                              onKeyUp={(e) => {
                                                matchingValues(mappingIndex, e, vehicle);
                                              }}
                                            />
                                            {cargoType === "Vehicle" ?
                                            <span class="input-group-text">
                                              Vehicles
                                            </span>:<span class="input-group-text">
                                              Quintals
                                            </span>}
                                            
                                            
                                              
                                            <button className="btn-danger" onClick={(e) => {                
                                                e.preventDefault();
                                                cancelVehicle(vehicle);
                                              }}
                                            >
                                              Cancel
                                            </button>
                                            
                                          </div>

                                          <div className="assign-cargo">
                                            <p>
                                              {
                                                vehicle.cargo_details
                                                  ?.cargo_type_ref_no
                                              }
                                            </p>
                                          </div>
                                        </div>
                                          }
                                          <div></div>
                                          {/* {(vehicle.trip_vehicle_trailer_id &&
                                            
                                            <div className="d-flex align-items-center">
                                            
                                            <p> Trailer </p>
                                            <div class="input-group mb-3">
                                              <input
                                                value={matchingValue.matchingVal}
                                                type="text"
                                                class="form-control rounded-0"
                                                onChange={(e) => {
                                                  matchingValues(mappingIndex, e, vehicle);
                                                }}
                                              />
                                              <span class="input-group-text">
                                                Quintals
                                              </span>
                                              
                                              
                                                
                                              <button className="btn-danger" onClick={(e) => {                
                                                  e.preventDefault();
                                                  cancelVehicle(vehicle);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              
                                            </div>

                                            <div className="assign-cargo">
                                              <p>
                                                {
                                                  vehicle.cargo_details
                                                    ?.cargo_type_ref_no
                                                }
                                              </p>
                                            </div>
                                          </div> )} */}
                                        
                                        </div>
                                          : <div className="col d-flex justify-content-between align-items-center ">
                                          <div className="tr-text-box">
                                            <div className="tr-tarrif">
                                              <p>
                                                Vehicle Type:{" "}
                                                <span>
                                                  {vehicle.vehicle_type}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="tr-Destination">
                                              <p>
                                                Plate No.:{" "}
                                                <span>
                                                  {vehicle.plate_number}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="tr-Destination">
                                              <p>
                                                Vehicle Capacity:{" "}
                                                <span>
                                                  {vehicle.truck_cap} {" "}
                                                  (Quintals)
                                                </span>
                                              </p>
                                            </div>
                                            {/* {containerType && <div className="tr-Destination">
                                              <p>
                                                Container Type:{" "}
                                                <span>
                                                  {containerType}
                                                  
                                                </span>
                                              </p>
                                            </div>} */}
                                            {/* <div className="tr-Destination">
                                              <p>
                                                Have Trailer:{" "}
                                                <span>
                                                  {vehicle.trip_vehicle_trailer_id ? 'yes': 'no'}{" "}
                                                  
                                                </span>
                                              </p>
                                            </div> */}
                                            {vehicle.trailer_plate_no ? 
                                            <div className="tr-Destination">
                                              <p>
                                                Vehicle Type:{" "}
                                                <span>
                                                  Trailer
                                                </span>
                                              </p>
                                              <p>
                                                Trailer Plate No:{" "}
                                                <span>
                                                  {vehicle?.trailer_plate_no}
                                                  
                                                </span>
                                              </p>
                                              <p>
                                                Trailer Capacity:{" "}
                                                <span>
                                                  {vehicle?.trailer_cap}
                                                  (Quintals)
                                                </span>
                                              </p>
                                            </div> : ""}
                                            
                                          </div>
                                          {cargoType === "Container" ?
                                          <div className="d-flex align-items-center">
                                            <div class="input-group mb-3">
                                              <input
                                                value={matchingValue.matchingVal}
                                                type="text"
                                                class="form-control rounded-0"
                                                onKeyUp={(e) => {
                                                  matchingValues(mappingIndex, e, vehicle);
                                                }}
                                              />
                                              <span class="input-group-text">
                                                Containers
                                              </span>
                                              
                                              
                                                
                                              <button className="btn-danger" onClick={(e) => {                
                                                  e.preventDefault();
                                                  cancelVehicle(vehicle);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              
                                            </div>

                                            <div className="assign-cargo">
                                              <p>
                                                {
                                                  vehicle.cargo_details
                                                    ?.cargo_type_ref_no
                                                }
                                              </p>
                                            </div>
                                          </div> : 
                                          <div className="d-flex align-items-center">
                                          <div class="input-group mb-3">
                                            <input
                                              value={matchingValue.matchingVal}
                                              type="text"
                                              class="form-control rounded-0"
                                              onKeyUp={(e) => {
                                                matchingValues(mappingIndex, e, vehicle);
                                              }}
                                            />
                                            <span class="input-group-text">
                                              Quintals
                                            </span>
                                            
                                            
                                              
                                            <button className="btn-danger" onClick={(e) => {                
                                                e.preventDefault();
                                                cancelVehicle(vehicle);
                                              }}
                                            >
                                              Cancel
                                            </button>
                                            
                                          </div>

                                          <div className="assign-cargo">
                                            <p>
                                              {
                                                vehicle.cargo_details
                                                  ?.cargo_type_ref_no
                                              }
                                            </p>
                                          </div>
                                        </div>
                                          }
                                          <div></div>
                                          {/* {(vehicle.trip_vehicle_trailer_id &&
                                            
                                            <div className="d-flex align-items-center">
                                            
                                            <p> Trailer </p>
                                            <div class="input-group mb-3">
                                              <input
                                                value={matchingValue.matchingVal}
                                                type="text"
                                                class="form-control rounded-0"
                                                onChange={(e) => {
                                                  matchingValues(mappingIndex, e, vehicle);
                                                }}
                                              />
                                              <span class="input-group-text">
                                                Quintals
                                              </span>
                                              
                                              
                                                
                                              <button className="btn-danger" onClick={(e) => {                
                                                  e.preventDefault();
                                                  cancelVehicle(vehicle);
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              
                                            </div>

                                            <div className="assign-cargo">
                                              <p>
                                                {
                                                  vehicle.cargo_details
                                                    ?.cargo_type_ref_no
                                                }
                                              </p>
                                            </div>
                                          </div> )} */}
                                        
                                        </div>
                                          }
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {cargoType === "Container" ? 
                  <div>
                    <label>
                      Quantity of Cargo to pair:{" "}
                      <span className="text-danger">
                        {unassignedCargo}{" "}
                        (Containers) {" "}                       
                      </span>
                      - Total Entered Quantity: {" "}
                      <span className="text-danger">                       
                        {totalEnteredQty} {" "} (Containers) {" "}
                      </span>
                      = {" "}
                      Remaining Quantity: {" "}
                      <span className="text-danger">                       
                        {unassignedCargo-totalEnteredQty} {" "} (Containers) {" "}
                      </span>
                    </label>
                  </div> : cargoType === "Vehicle" ?
                  <div>
                  <label>
                    Quantity of Cargo to pair:{" "}
                    <span className="text-danger">
                      {unitType === "ton"
                        ? `${commaNumber(
                            Math.round(quintalValue).toFixed(2)
                          )}`
                        : unassignedCargo}{" "}
                      (Vehicles) {" "}                       
                    </span>
                    - Total Entered Quantity: {" "}
                    <span className="text-danger">                       
                      {totalEnteredQty} {" "} (Vehicles) {" "}
                    </span>
                    = {" "}
                    Remaining Quantity: {" "}
                    <span className="text-danger">                       
                      {unassignedCargo-totalEnteredQty} {" "} (Vehicles) {" "}
                    </span>
                  </label>
                </div>
                  : <div>
                    <label>
                      Quantity of Cargo to pair:{" "}
                      <span className="text-danger">
                        {unitType === "ton"
                          ? `${commaNumber(
                              Math.round(quintalValue).toFixed(2)
                            )}`
                          : unassignedCargo}{" "}
                        (Quintals) {" "}                       
                      </span>
                      - Total Entered Quantity: {" "}
                      <span className="text-danger">                       
                        {totalEnteredQty} {" "} (Quintals) {" "}
                      </span>
                      = {" "}
                      Remaining Quantity: {" "}
                      <span className="text-danger">                       
                        {unassignedCargo-totalEnteredQty} {" "} (Quintals) {" "}
                      </span>
                    </label>
                  </div>}
                  <div className="col-lg-4 mt-4">
                            <label htmlFor="">Cargo Type</label>
                            <p className="data">{cargoType}</p>
                          </div>
                          
                          {containerType && 
                      <div className="col-lg-4 mt-4">
                          <label htmlFor="">Container Type</label>
                                <p className="data">{containerType && containerType ? 
                                     containerType : "No Container"}</p>
                  </div>}

                  
                   <div className="bottom-btn-wrapper">
                    {/* <Link to="/DirectOrderConfirmation"> */}
                    {tripCategory==='direct' ? <button className="btn-danger" style={{color: 'darkred'}} onClick={(e) => {                
                        e.preventDefault();
                        cancelAllRequests();
                      }}
                    >
                      Cancel All Requests
                    </button>: ""}
                    <pre>  </pre>
                    <button className="btn-success" type="button" onClick={submit}>
                      Submit
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </React.Fragment>
  );
};

export default MatchVechicleWithCargo;
