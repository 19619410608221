import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/Transportvehicle.scss";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
const SelectVehiclelist = () => {
  const { mainClass } = useContext(AppContext);

  const [show, Setshow] = useState(false);

  function handleClick() {
    Setshow(!show);
  }

  return (
    <React.Fragment>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div className={`vehicle ${mainClass}`} id="cxMain">
        <div className="row">
          <div style={{ width: "100%", overflow: "auto", padding: "30px 0" }}>
            <table className="table-striped dataList">
              <thead>
                <tr>
                  <th></th>
                  <th>S.No</th>
                  <th>Owner Name</th>
                  <th>Vehicle Type</th>
                  <th>Model</th>
                  <th>Plate No.</th>
                  <th>Driver Name</th>
                  <th>Nationality</th>
                </tr>
              </thead>
              <tbody>
                <tr className="Rowbgcolor ">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>
                  <td>1</td>
                  <td id="Owner">Joshua Kimmich</td>
                  <td id="Vehicle">Truck - trailer</td>
                  <td id="Model">
                    Eicher pro 1110Xp<span className="etc">...</span>{" "}
                  </td>
                  <td id="Plate">TN No 2021</td>
                  <td id="DriverNAM">Robert Lewondoski</td>
                  <td id="Nationality">India</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className="ms-5 ps-5"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img className="" src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>
                      <li></li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
                <tr className="Rowbgcolor p-2">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>

                  <td>2</td>
                  <td id="Owner">Joshua Kimmich</td>
                  <td id="Vehicle">Truck - trailer</td>
                  <td id="Model">
                    Eicher pro 1110Xp<span className="etc">...</span>{" "}
                  </td>
                  <td id="Plate">TN No 2021</td>
                  <td id="DriverNAM">Robert Lewondoski</td>
                  <td id="Nationality">India</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
                <tr className="Rowbgcolor p-2">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>
                  <td>3</td>
                  <td id="Owner">Joshua Kimmich</td>
                  <td id="Vehicle">Truck - trailer</td>
                  <td id="Model">
                    Eicher pro 1110Xp<span className="etc">...</span>{" "}
                  </td>
                  <td id="Plate">TN No 2021</td>
                  <td id="DriverNAM">Robert Lewondoski</td>
                  <td id="Nationality">India</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
                <tr className="Rowbgcolor p-2">
                  <div className="form-check pt-2 ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                  </div>
                  <td>1</td>
                  <td id="Owner">Joshua Kimmich</td>
                  <td id="Vehicle">Truck - trailer</td>
                  <td id="Model">
                    Eicher pro 1110Xp<span className="etc">...</span>{" "}
                  </td>
                  <td id="Plate">TN No 2021</td>
                  <td id="DriverNAM">Robert Lewondoski</td>
                  <td id="Nationality">India</td>
                  <div className="option-dropdown dropdown p-2">
                    <a
                      href=""
                      className=""
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ic_options} alt="" />
                    </a>
                    <ul className="dropdown-menu ">
                      <li>
                        <button className="dropdown-item" type="button">
                          Edit{" "}
                          <img
                            src={dropdown_arrow_blue}
                            className="arrowvehicle"
                            alt=""
                          />
                        </button>
                      </li>
                      <li>
                        <Link
                          to="/ViewDetails"
                          className="text-decoration-none"
                        >
                          <button className="dropdown-item " type="button">
                            View Details
                          </button>
                        </Link>
                      </li>

                      <li>
                        <button className="dropdown-item" type="button">
                          Change Over
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          Block
                        </button>
                      </li>
                    </ul>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <a href="">
              <button type="submit" className="cancel-btn">
                Cancel
              </button>
            </a>
            <Link to="#">
              <button type="submit" className="continue-btn">
                Offer Vehicle
              </button>
            </Link>
            <Link to="#">
              <button type="submit" className="continue-btn">
                Assign
              </button>
            </Link>

          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default SelectVehiclelist;
