import GetStarted from "./sharedComponent/GetStarted";
import "./assets/css/main.scss";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./sharedComponent/Login";
import "./assets/css/style.scss";
import Dashboard from "./sharedComponent/Dashboard"
import AddVehicles from "./pages/Vehicles/AddVehicles";
import AddDriver from "./pages/Driver/AddDriver";
import VehicleDriverDetailsHeader from "./pages/Transporter/VehicleDriverDetailsHeader";
import DriverDetails from "./pages/Driver/DriverDetails";
import VehiclesDetails from "./pages/Vehicles/VehiclesDetails";
import AddVehicleOffer from "./pages/Transporter/AddVehicleOffer";
import "bootstrap/dist/css/bootstrap.min.css";
import AddGoods from "./pages/Transporter/AddGoods";
import GoodsDetails from "./pages/Transporter/GoodsDetails";
import FAQ from "./sharedComponent/FAQ";
import ContactUs from "./sharedComponent/ContactUs";
import TermsAndConditions from "./sharedComponent/TermsAndConditions";
import Notifications from "./sharedComponent/Notifications";
import AbhayHeader from "./sharedComponent/AbhayHeader";
import Sidebar from "./sharedComponent/Sidebar";
import CompleteFreights from "./pages/Freights/CompleteFreights";
import GoodsOffer from "./pages/Transporter/GoodsOffer";
import OngoingFreights from "./pages/Freights/OngoingFreights";
import TransistAuction from "./pages/Transporter/TransistAuction";
import TruckofferAuction from "./pages/Transporter/TruckofferAuction";
import UpcomingFreights from "./pages/Freights/UpcomingFreights";
import VehicalOffRecived from "./pages/Transporter/VehicalOffRecived";
import VehicalRequest from "./pages/Transporter/VehicalRequest";
import VehicleList from "./pages/Vehicles/VehicleList";
import VehicleOffer from "./pages/Transporter/VehicleOffer";
import DriverList from "./pages/Driver/DriverList";
import Cargo_Delivery from "./pages/Transporter/Cargo_Delivery";
import { Reports } from "./pages/Transporter/Reports";
import SelectReportTypes from "./pages/Transporter/SelectReportTypes";
import AuctionDetails from "./pages/OnlineAuction/AuctionDetails";
import ReportReason from "./pages/Transporter/ReportReason";
import Rating from "./pages/Transporter/Rating";
import Profile from "./pages/Transporter/Profile";
import SelectVehiclelist from "./pages/Transporter/SelectVehiclelist";
import GPS_Shipper from "./pages/LiveTracking/GPS_Shipper";
import MarketPlaVehicleOff from "./pages/Transporter/MarketPlaVehicleOff";
import OngoingFreightsMenu from "./pages/LiveTracking/OngoingFreightsMenu";
import OrderConfirmation from "./pages/Transporter/OrderConfirmation";
import TransporterOrderConfirm1 from "./pages/Transporter/TransporterOrderConfirm1";
import FreightsHeaders from "./pages/Freights/Freightsheader";
import DirectVehicleRequest from "./pages/Transporter/DirectVehicleRequest";
import ChangeDriver from "./pages/Transporter/ChangeDriver";
import Contracts from "./pages/Transporter/Contracts";
import ViewDetails from "./pages/Transporter/ViewDetails_Goods_Driver_Vehicle";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import TransporterLists from "./pages/Admin_Transporter/TransporterLists";
import ShippersLists from "./pages/Shipper/ShippersLists";
import ContractTransporter from "./pages/Admin_Transporter/ContractTransporter";
import AddTransporter from "./pages/Admin_Transporter/AddTransporter";
import AddNewTransporter from "./pages/Admin_Transporter/AddNewTransporter";
import AddShipper from "./pages/Shipper/AddShipper";
import AddTransporterSecond from "./pages/Admin_Transporter/AddTransporterSecond";
import AddShipperSecond from "./pages/Shipper/AddShipperSecond";
import ContractShipper from "./pages/Shipper/ContractShipper";
import TransporterDetails from "./pages/Admin_Transporter/TransporterDetails";
import ShipperDetails from "./pages/Shipper/ShipperDetails";
import VehicleRequest from "./pages/VehicleRequest/VehicleRequest";
import AddVehicleSpecification from "./pages/VehicleRequest/AddVehicleSpecification";
import SelectVehicleSpecificationsList from "./pages/Transporter/SelectVehicleSpecificationsList";
import CreateAuction from "./pages/OnlineAuction/CreateAuction";
import DirectOrderHeader from "./pages/DirectOrder/DirectOrderHeader";
import DirectOrderConfirmation from "./pages/DirectOrder/DirectOrderConfirmation";
import TransporterResponse from "./pages/DirectOrder/TransporterResponse";
import MatchMaking from "./pages/DirectOrder/MatchMaking";
import MatchVechicleWithCargo from "./pages/DirectOrder/MatchVechicleWithCargo";
import SelectTransporter from "./pages/VehicleRequest/SelectTransporter";
import VehicleRequestTransporterDetail from "./pages/Transporter/VehicleRequestTransporterDetail";
import OfferVehicleListing from "./pages/VehicleRequest/OfferVehicleListing";
import OnlineAuctionHeader from "./pages/OnlineAuction/OnlineAuctionHeader";
import LoadDetails from "./pages/VehicleRequest/LoadDetails";
import Auctions from "./pages/VehicleRequest/Auctions";
import { useContext, useEffect, useState } from "react";
import MatchMakingOnlineAuction from "./pages/OnlineAuction/MatchMakingOnlineAuction";
import { AppContext } from "./context/user/AppContext";
import ForgotOTP from "./sharedComponent/ForgotOTP";
import ForgotPassword from "./sharedComponent/ForgotPassword";
import ResetPassword from "./sharedComponent/ResetPassword";
import AdminSetting from "./pages/Transporter/AdminSetting";
import VehicleRequestsDirectOrder from "./pages/DirectOrder/VehicleRequestsDirectOrder";
import ApprovalHeader from "./pages/Approval/ApprovalHeader";
import EditApprovalHeader from "./pages/Approval/EditApprovalHeader";
import ShipperRates from "./pages/Rates/ShipperRatesImport";
import OfferVehicles from "./pages/DirectOrder/OfferVehicles";
import OnlineOfferLoads from "./pages/OnlineAuction/OnlineOfferLoads";
import OnlineOfferVehicles from "./pages/OnlineAuction/OnlineOfferVehicles";
import OnlineAuctionOrderConfirmation from "./pages/OnlineAuction/OnlineAuctionOrderConfirmation";
import OrderConfirmationForTransporter from "./pages/OnlineAuction/OrderConfirmationForTransporter";
import Approved_RejectList from "./pages/Approval/Approved_RejectList";
import TransporterRates from "./pages/Rates/TransporterRates";
import ShipperRatesExport from "./pages/Rates/ShipperRatesExport";
import ShipperRatesImport from "./pages/Rates/ShipperRatesImport";
import ApiConfig from "./api/ApiConfig";
import RegisterVehicle from "./sharedComponent/RegisterVehicle";
import RegisterDriver from "./sharedComponent/RegisterDriver";
import RegisteredVehiclesList from "./sharedComponent/RegisteredVehicleList";
import RegisteredDriversList from "./sharedComponent/RegisteredDriverList";
import { postWithAuthCallWithErrorResponse } from "./api/ApiServices";
import ServerError from "./sharedComponent/ServerError";
import PageNotFound from "./sharedComponent/PageNotFound";
import RejectedVehicleRequests from "./pages/DirectOrder/RejectedVehicleRequests";
import TransporterResponseMain from "./pages/DirectOrder/TransporterResponseMain";
import VehicleDriverDetails from "./sharedComponent/VehicleDriverDetails";
import OfferLoadVehiclesDetails from "./pages/Vehicles/OfferLoadVehicleDetails";
import OfferLoadDriverDetails from "./pages/Driver/OfferLoadDriverDetails";
import DirectOrderConfirmationHistory from "./pages/DirectOrder/DirectOrderConfirmationHistory";
import DirectOrderConfirmationDetails from "./pages/DirectOrder/DirectOrderConfirmationDetails";
import DirectOfferedVehicleHistory from "./pages/DirectOrder/DirectOfferedVehicleHistory";
import OnlineVehicleOffersHistory from "./pages/OnlineAuction/OnlineVehicleOffersHistory";
import OnlineOrderConfirmation from "./pages/OnlineAuction/OnlineOrderConfirmation";
import OnlineOrderConfirmationDetails from "./pages/OnlineAuction/OnlineOrderConfirmationDetails";
import DirectOfferedLoads from "./pages/DirectOrder/DirectOfferedLoads";
import OnlineOfferedLoads from "./pages/OnlineAuction/OnlineOfferdLoads";
import EditShipper from "./pages/Shipper/EditShipper";
import EditShipperSecond from "./pages/Shipper/EditShipperSecond";

function App() {
  const currentRoute = useLocation().pathname;
  const { ApiCaaling, setProfileDetails, customerData } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );

  useEffect(() => {
    if (!logedIn) {
      if (
        currentRoute.includes("ForgotOTP") ||
        currentRoute.includes("ForgotPassword") ||
        currentRoute.includes("ResetPassword")
      ) {
        navigate(currentRoute);
      }
      navigate("/");
    } else navigate("dashboard");
  }, [logedIn]);

  useEffect(() => {
 

    if (!logedIn) {   
      const pathvariable = window.location.pathname.toString();
      if (
        currentRoute === ("/ForgotOTP") ||
        currentRoute === ("/ForgotPassword") ||
        currentRoute === ("/ResetPassword") ||
        currentRoute === ("/ServerError")
      ) {
        navigate(currentRoute);
      } else if (pathvariable !== ("/ForgotOTP") ||
      pathvariable !== ("/ForgotPassword") ||
      pathvariable !== ("/ResetPassword") ||
      pathvariable !== ("/ServerError")
        ) {
        navigate("/")
      }
      else navigate("/");
    } else {
      navigate(currentRoute);
    }
  }, [currentRoute]);
  useEffect(() => {
    let buttonDisabled = document.getElementById("continue-btn");
    if (buttonDisabled)
      if (ApiCaaling) document.getElementById("continue-btn").disabled = true;
      else document.getElementById("continue-btn").disabled = false;
  }, [ApiCaaling]);

  useEffect(() => {
    getProfileDetails();
  }, []);
  const getProfileDetails = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.PROFILE,
      JSON.stringify({
        ...customerData,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) setProfileDetails(res.json);
    });
  };
  return (
    <div className="App">
      {currentRoute !== "/" &&
        currentRoute !== "/Login" &&
        currentRoute !== "/ForgotOTP" &&
        currentRoute !== "/ForgotPassword" &&
        currentRoute !== "/ResetPassword" && 
        currentRoute !== "/ServerError" &&(
          <>
            <Sidebar />
            <AbhayHeader setLoggedIn={setLoggedIn} />
          </>
        )}
      <Routes>
        <Route
          path={"/" || "/Login"}
          element={<Login setLoggedIn={setLoggedIn} />}
        />
        <Route path={"Dashboard" || "/"} element={<Dashboard />} />
        <Route path="AddVehicles/" element={<AddVehicles />}>
          <Route path=":id" element={<AddVehicles />} />
        </Route>
        <Route path="AddDriver" element={<AddDriver />}>
          <Route path=":id" element={<AddDriver />} />
        </Route>
        <Route
          path="VehicleDriverDetailsHeader/"
          element={<VehicleDriverDetailsHeader />}
        >
          <Route path=":id/:driver" element={<VehicleDriverDetailsHeader />} />
        </Route>
        <Route
          path="VehicleDriverDetails/"
          element={<VehicleDriverDetails />}
        >
          <Route path=":vehicle_id/:trailer_id/:driver_id" element={<VehicleDriverDetails />} />
        </Route>
        <Route path="OfferLoadDriverDetails" element={<OfferLoadDriverDetails />} />
        <Route path="OfferLoadVehiclesDetails" element={<OfferLoadVehiclesDetails />} />
        <Route path="DriverDetails" element={<DriverDetails />} />
        <Route path="VehiclesDetails" element={<VehiclesDetails />} />
        <Route path="OnlineOfferLoads" element={<OnlineOfferLoads />} />
        <Route path="OnlineOfferVehicles" element={<OnlineOfferVehicles />} />
        <Route path="RegisterVehicle" element={<RegisterVehicle />}>
            <Route path=":transporter_id" element={<RegisterVehicle/>}></Route>
        </Route>
        <Route path="RegisterDriver" element={<RegisterDriver />} >
          <Route path=":transporter_id" element={<RegisterDriver/>}/> 
        </Route>
        <Route
          path="OnlineAuctionOrderConfirmation"
          element={<OnlineAuctionOrderConfirmation />}
        />
        <Route
          path="OrderConfirmationForTransporter/:id"
          element={<OrderConfirmationForTransporter />}
        />
        <Route path="AddVehicleOffer" element={<AddVehicleOffer />} />
        <Route path="OfferVehicles" element={<OfferVehicles />} />
        <Route
          path="MatchVechicleWithCargo"
          element={<MatchVechicleWithCargo />}
        />
        <Route path="AddGoods" element={<AddGoods />} />
        <Route path="GoodsDetails" element={<GoodsDetails />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="Notifications" element={<Notifications />} />
        <Route path="AbhayHeader" element={<AbhayHeader />} />
        <Route path="CompleteFreights" element={<CompleteFreights />} />
        <Route path="GoodsOffer" element={<GoodsOffer />} />
        <Route path="OngoingFreights" element={<OngoingFreights />} />
        <Route path="UpcomingFreights" element={<UpcomingFreights />} />
        <Route path="OngoingFreightsMenu" element={<OngoingFreightsMenu />} />
        <Route path="TransistAuction" element={<TransistAuction />} />
        <Route path="TruckofferAuction" element={<TruckofferAuction />} />
        <Route path="VehicalOffRecived" element={<VehicalOffRecived />} />
        <Route path="VehicalRequest" element={<VehicalRequest />} />
        <Route path="Cargo_Delivery" element={<Cargo_Delivery />} />
        <Route path="VehicleList" element={<VehicleList />} />
        <Route path="SelectVehiclelist" element={<SelectVehiclelist />} />
        <Route path="OrderConfirmation" element={<OrderConfirmation />} />
        <Route
          path="TransporterOrderConfirm1"
          element={<TransporterOrderConfirm1 />}
        />
        <Route path="VehicleOffer" element={<VehicleOffer />} />
        <Route path="MarketPlaVehicleOff" element={<MarketPlaVehicleOff />} />
        <Route path="DirectVehicleRequest" element={<DirectVehicleRequest />} />
        <Route path="DriverList" element={<DriverList />} />
        <Route path="Reports" element={<Reports />} />
        <Route path="ReportReason" element={<ReportReason />} />
        <Route path="SelectReportTypes" element={<SelectReportTypes />} />
        <Route path="AuctionDetails" element={<AuctionDetails />} />
        <Route path="Rating" element={<Rating />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="GPS_Shipper" element={<GPS_Shipper />}>
          <Route path=":id" element={<GPS_Shipper />} />
        </Route>
        <Route path="FreightsHeaders" element={<FreightsHeaders />} />
        <Route path="ChangeDriver" element={<ChangeDriver />} />
        <Route path="Contracts" element={<Contracts />} />
        <Route path="ViewDetails/:id" element={<ViewDetails />} />
        <Route path="TransporterLists" element={<TransporterLists />} />
        <Route path="CargoDeleveryLists" element={<TransporterLists />} />
        <Route path="ShippersLists" element={<ShippersLists />} />
        <Route
          path="ContractTransporter/:id"
          element={<ContractTransporter />}
        />
        <Route path="AddTransporter/" element={<AddTransporter />}>
          <Route path=":id" element={<AddTransporter />} />
        </Route>
        <Route path="AddNewTransporter/" element={<AddNewTransporter />}>
          <Route path=":id" element={<AddNewTransporter />} />
        </Route>
        <Route path="AddShipper/" element={<AddShipper />}>
          <Route path=":id" element={<AddShipper />} />
        </Route>

        <Route path="EditShipper/" element={<EditShipper />}>
          <Route path=":id" element={<EditShipper />} />
        </Route>

        <Route
          path="AddTransporterSecond/"
          element={<AddTransporterSecond />}
        />
        <Route
          path="AddTransporterSecond/:id"
          element={<AddTransporterSecond />}
        />

        <Route path="AddShipperSecond" element={<AddShipperSecond />} />
        <Route path="EditShipperSecond" element={<EditShipperSecond />} />
        <Route path="ContractShipper/:id" element={<ContractShipper />} />
        <Route path="TransporterDetails/:id" element={<TransporterDetails />} />
        <Route path="ShipperDetails/:id" element={<ShipperDetails />} />
        <Route path="VehicleRequest" element={<VehicleRequest />} />
        <Route
          path="AddVehicleSpecification"
          element={<AddVehicleSpecification />}
        />
        <Route
          path="SelectVehicleSpecificationsList"
          element={<SelectVehicleSpecificationsList />}
        />
        <Route path="CreateAuction" element={<CreateAuction />} />
        <Route path="DirectOrderHeader" element={<DirectOrderHeader />} />
        <Route
          path="OnlineAuctionOrderConfirmation"
          element={<OnlineAuctionOrderConfirmation />}
        />
        <Route
          path="DirectOrderConfirmation"
          element={<DirectOrderConfirmation />}
        />
        <Route path="TransporterResponse/:id" element={<TransporterResponse />} />
        <Route path="MatchMaking" element={<MatchMaking />} />
        <Route path="SelectTransporter" element={<SelectTransporter />} />
        <Route
          path="VehicleRequestTransporterDetail"
          element={<VehicleRequestTransporterDetail />}
        />
        <Route path="OfferVehicleListing" element={<OfferVehicleListing />} />
        <Route path="OnlineAuctionHeader" element={<OnlineAuctionHeader />} />
        <Route path="LoadDetails" element={<LoadDetails />} />
        <Route path="Auctions" element={<Auctions />} />
        <Route
          path="MatchMakingOnlineAuction"
          element={<MatchMakingOnlineAuction />}
        />
        <Route path="ForgotOTP" element={<ForgotOTP />} />
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />
        <Route path="AdminSetting" element={<AdminSetting />} />
        <Route path="ApprovalHeader" element={<ApprovalHeader />} />
        <Route path="EditApprovalHeader" element={<EditApprovalHeader />} />
        <Route path="Approved_RejectList" element={<Approved_RejectList />} />
        <Route path="ShipperRatesImport" element={<ShipperRatesImport />} />
        <Route path="TransporterRates" element={<TransporterRates />} />
        <Route path="ShipperRatesExport" element={<ShipperRatesExport />} />
        <Route
          path="RejectedVehicleRequests"
          element={<RejectedVehicleRequests />}
        />
        <Route
          path="TransporterResponseMain"
          element={<TransporterResponseMain/>}
        />
        <Route
          path="VehicleRequestsDirectOrder"
          element={<VehicleRequestsDirectOrder />}
        />
        <Route path="RegisteredVehiclesList" element={<RegisteredVehiclesList />}>
          <Route path=":transporter_id" element={<RegisteredVehiclesList />} />
        </Route>
        <Route
          path="RegisteredDriversList"
          element={<RegisteredDriversList />}
        >
          <Route path=":transporter_id" element={<RegisteredDriversList />}/>
        </Route>
        <Route
          path="DirectOfferedVehicles"
          element={<DirectOfferedVehicleHistory />}
        />
        <Route
          path="DirectOrderConfirmationHistory"
          element={<DirectOrderConfirmationHistory />}
        />
        <Route
          path="DirectOrderConfirmationDetails"
          element={<DirectOrderConfirmationDetails />}
        />
        <Route
          path="OnlineVehicleOffersHistory"
          element={<OnlineVehicleOffersHistory />}
        />
        <Route
          path="OnlineOrderConfirmation"
          element={<OnlineOrderConfirmation />}
        />
        <Route
          path="OnlineOrderConfirmationDetails"
          element={<OnlineOrderConfirmationDetails />}
        />
        <Route
          path="DirectOfferedLoads"
          element={<DirectOfferedLoads />}
        />
        <Route 
          path="OnlineOfferedLoads" 
          element={<OnlineOfferedLoads />} 
        />
       <Route
          path="AuctionDetails"
          element={<AuctionDetails />}
        />
      <Route path="ServerError"  element={<ServerError/> }/>
        <Route path="*"  element={logedIn ? <PageNotFound/> : <Login setLoggedIn={setLoggedIn} /> } />
      </Routes>
    </div>
  );
}

export default App;
