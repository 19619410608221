import React, { useEffect } from 'react';
import drag_drop_default from "../assets/images/drag_drop_default.svg";
function DragAndDrop(props) {
    let id = props.id
    let randomId = "radom" + id + Math.random();
    useEffect(() => {
        initDragAndDrop();
    }, [])
    const initDragAndDrop = () => {
        var dropZone = document.getElementById(id);
        // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
        dropZone && dropZone.addEventListener('dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
        });
        dropZone && dropZone.addEventListener('drop', drop);
    }
    function drop(e) {
        e.stopPropagation();
        e.preventDefault();
        let file = "";
        if (e.target && e.target.files) {
            file = e.target.files[0];
        } else {
            file = e.dataTransfer.files[0]
        }
        props.setFile(file)

    }
    return (
        <>
            <div className="drop_block mb-3 mt-3" id={id}>
                <div className="vertical_m ">
                    <a href="#" style={{ color: "#4D9DE7" }} onClick={(e) => {
                        e.preventDefault();
                        document.getElementById(randomId).click()
                    }} >
                        <img src={drag_drop_default} alt="" />
                    </a>
                </div>
            </div>
            <input onChange={drop} accept="image/png, image/gif, image/jpeg" id={randomId} type="file" name="vehicle_images" style={{ display: "none" }} />
        </>
    );
}

export default DragAndDrop;