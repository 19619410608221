import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dropdown_arrow_blue from "../assets/images/dropdown_arrow_blue.svg";
import "../assets/css/Driverlist.scss";
import ic_add from "../assets/images/Trasnvehicle/ic_add.svg";
import ic_options from "../assets/images/Trasnvehicle/ic_options.svg";
import ic_import from "../assets/images/Trasnvehicle/ic_excel.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Loader from "../sharedComponent/Loader";
import swal from "sweetalert";
import WhiteTolltip from "../sharedComponent/WhiteTolltip";
import * as XLSX from "xlsx";

const RegisteredDriverList = () => {
  const [selected, setSelected] = useState("All");
  const navigate = useNavigate();
  const { mainClass, customerData, tp_id, currentId } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [driverList, setDriverList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  let params = useParams();
  let transporter_id = params.transporter_id;
 
  useEffect(() => {
    getDriverList();
  }, []);
  const getDriverList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.REGISTERED_DRIVER_LIST,
      JSON.stringify({ transporter_id: transporter_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setDriverList(res.json.driver_list);
          // setOriginalData(res.json.driver_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const register = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.SUBMIT,
      JSON.stringify({ transporter_id: transporter_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          if (res.json.result) {
            swal({
              title: "Transporter Registered Successfully!",
              text: "Kindly Wait for Approval",
              icon: "success",
              dangerMode: false,
              button: true,
            }).then(() => {
              localStorage.clear();
              navigate("/");
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNavigate = (e) => {
    e.preventDefault();
    if (currentId) {
      navigate("/AddTransporterSecond");
    } else {
      register();
    }
  };

  return (
    (params && transporter_id) ?
    <div>
      <div className={`vehicle ${mainClass}`} id="cxMain">
        <div className="d-flex  ">
          <div className="input-group  justify-content-end ">
            <div className="icon-group col-sm-3  mt-2">
              <ul className="d-flex list-unstyled  justify-content-end gap-3 ">
                <li>
                  <div className="form-outline searchBox  m-0 ">
                    <label htmlFor="select" className="mr-4">
                      Filter
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <option value={"select"}>Select</option>
                      <option value={"All"}>All</option>
                      <option value={"available"}>Available</option>
                      <option value={"Busy"}>Busy</option>
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>Inactive</option>
                    </select>
                  </div>
                </li>
                {/* <li className="nav-item icons p-2 me-3 " data-tip="Export">
                  <Link to="#">
                    <img
                      src={ic_export}
                      className="add mb-2"
                      alt="send-load-icon"
                    />
                  </Link>
                </li> */}
                {/* <li className="nav-item icons p-2 me-3" data-tip="Export to Excel">
                  <Link to="#" onClick={exportToexcel}>
                    <img

                      src={ic_import}
                      className="add"
                      alt="send-load-icon"
                    />
                  </Link>
                </li> */}
                <li className="nav-item icons" data-tip="Add Driver">
                  <Link to={"/RegisterDriver/" + transporter_id}>
                    <img src={ic_add} className="add" alt="send-load-icon" />
                  </Link>
                </li>
                <WhiteTolltip />
              </ul>
            </div>
          </div>
        </div>

        <div
          className=""
          style={{ width: "100%", overflow: "auto", padding: "30px 0" }}
        >
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <table className=" dataList " id="driverListe">
              <thead>
                <tr>
                  <th>
                    <label>S.No</label>
                  </th>
                  <th>
                    <label>Driver Name</label>
                  </th>
                  <th>
                    <label>Nationality</label>
                  </th>
                  <th>
                    <label>Email id</label>
                  </th>
                  <th>
                    <label>Mobile Number</label>
                  </th>
                  <th>
                    <label>License Number</label>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {driverList &&
                  driverList.map((driver, index) => {
                    return (
                      <tr className="Rowbgcolor p-2" key={"driver" + index}>
                        <td>{index + 1}</td>
                        <td id="DriverNAM">{driver.driver_name}</td>
                        <td id="Nationality">{driver.nationality}</td>
                        <td id="emial">{driver.email_id}</td>
                        <td id="mobileNO">{driver.mobile_number}</td>
                        <td id="LicenseNo">{driver.licence_number}</td>
                        <td>
                          <div className="option-dropdown dropdown p-2">
                            <a
                              href=""
                              className=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={ic_options} alt="" />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() =>
                                    navigate("/AddDriver/" + driver.driver_id)
                                  }
                                >
                                  Edit{" "}
                                  <img
                                    src={dropdown_arrow_blue}
                                    className="arrowvehicle"
                                    alt=""
                                  />
                                </button>
                              </li>
                              <li>
                                <Link
                                  to={
                                    "/VehicleDriverDetailsHeader/" +
                                    driver.driver_id
                                  }
                                  className="text-decoration-none"
                                  state={{ id: driver.driver_id, driver: true }}
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    View Details
                                  </button>
                                </Link>
                              </li>
                              {/* {driver.driver_status === "blocked" ? (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      unBlockDriver(driver.driver_id)
                                    }
                                  >
                                    Unblock
                                  </button>
                                </li>
                              ) : (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      blockDriver(driver.driver_id)
                                    }
                                  >
                                    Block
                                  </button>
                                </li>
                              )} */}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {driverList && driverList.length > 0 && (
        <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
          <div className="left"></div>
          <div className="right">
            <Link to="/RegisteredVehiclesList">
              <button type="submit" className="cancel-btn">
                Cancel
              </button>
            </Link>
            <Link to="#">
              <button
                onClick={(e) => handleNavigate(e)}
                type="submit"
                className="continue-btn"
              >
                Submit
              </button>
            </Link>
          </div>
        </div>
      )}
    </div> : <>{navigate("TransporterLists")}</>
  );
};

export default RegisteredDriverList;
