import React, { useContext, useEffect, useState } from "react";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link, useNavigate } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ic_dropdown_arrow from "../../assets/images/dropdown_arrow_blue.svg";

import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
const OnlineAuctionAllRequest = ({ All, searchText }) => {
  const [loading, setLoading] = useState(false);
  const [Requests, SetRequests] = useState([]);
  const { customerData } = useContext(AppContext);
  const [orginalList, setOrginalList] = useState([]);
  useEffect(() => {
    let list = orginalList.filter((data) =>
      data.load_reference_no.includes(searchText)
    );
    SetRequests(list);
  }, [searchText]);

  const navigate = useNavigate();
  useEffect(() => {
    getAllRequests();
  }, []);
  const getAllRequests = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      All
        ? ApiConfig.ONLINE_AUCTIONS_ALLREQUEST
        : ApiConfig.DIRECT_ORDER_MYREQUEST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          SetRequests(res.json.load_list);
          setOrginalList(res.json.load_list);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <main>
      <div style={{ margin: "20px" }}>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="offerLoadsMainLists">
                <div className="mainLoadTable">
                  <table className="dataList" style={{ minWidth: "1100px" }}>
                    <thead>
                      <tr>
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Reference No.</label>
                        </th>
                        <th>
                          <label>From-To</label>
                        </th>
                        <th>
                          <label>Offered By</label>
                        </th>
                        <th>
                          <label>Cargo Type</label>
                        </th>
                        <th>
                          <label>Quantity</label>
                        </th>
                        <th>
                          <label>Est.Start.Dt</label>
                        </th>
                        <th>
                          <label>Status</label>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
              {Requests.length > 0 ? (
                Requests.map((request, index) => {
                  return (
                    // <div className="col-lg-6 col-xl-4    mb-3 " key={"request" + index}>
                    //   <div className="card mb-2">
                    //     <div className="p-1">
                    //       <div className="d-flex justify-content-between">
                    //         <div className="d-flex justify-content-between align-items-center">
                    //           <img
                    //             src={ic_trucks}
                    //             alt=""
                    //             className="card-rsponsive-img vehicle-request-img"
                    //           />
                    //           <p className=" ms-3">{request.load_reference_no}</p>
                    //         </div>

                    //         <div className="ongoing-dropdown dropdown ">
                    //           <a
                    //             href=""
                    //             className=""
                    //             data-bs-toggle="dropdown"
                    //             aria-expanded="true"
                    //           >
                    //             <img className="" src={ic_options} alt="" />
                    //           </a>
                    //           <ul className="dropdown-menu ">
                    //             <li>
                    //               <Link
                    //                 to={"/ViewDetails/" + request.trip_id}
                    //                 className="text-decoration-none"
                    //               >
                    //                 <button className="dropdown-item " type="button">
                    //                   View Details
                    //                 </button>
                    //               </Link>
                    //             </li>
                    //             <li>
                    //               <Link
                    //                 to="/SelectReportTypes"
                    //                 className="text-decoration-none"
                    //               >
                    //                 <button className="dropdown-item " type="button">
                    //                   Reports
                    //                 </button>
                    //               </Link>
                    //             </li>
                    //           </ul>
                    //         </div>
                    //       </div>
                    //       <div className="row ms-5 me-2 responsive-card-body">
                    //         <div className="text-start">
                    //           <dd className="card-label">{request.trip_start_city} {request.trip_start_country} - {request.trip_end_city} {request.trip_end_country}</dd>
                    //           <dt className="small-card-data"></dt>
                    //         </div>
                    //         <div className="d-flex justify-content-between rsponsive-card-data">
                    //           <dd className="card-label">Offered By</dd>
                    //           <dt className="offered-by text-decoration-underline">
                    //             {request.offered_by}…
                    //           </dt>
                    //         </div>
                    //         <div className="d-flex justify-content-between rsponsive-card-data">
                    //           <dd className="card-label">Cargo Type</dd>
                    //           <dt className="small-card-data">{request.cargo_type}</dt>
                    //         </div>
                    //         <div className="d-flex justify-content-between rsponsive-card-data">
                    //           <dd className="card-label">Capacity</dd>
                    //           <dt className="small-card-data">{request.quantity} {request.unit}.</dt>
                    //         </div>
                    //         <div className="d-flex justify-content-between rsponsive-card-data">
                    //           <dd className="card-label">Est. Start Dt.</dd>
                    //           <dt className="small-card-data">{request.estimated_start_date}</dt>
                    //         </div>

                    //       </div>
                    //       <hr />
                    //       <div className="text-center ">
                    //         {
                    //           request.trip_status === "Transporters Assigned" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 Transporter’s Assigned
                    //               </button>
                    //             </Link> <br />

                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="WaitingforTransportersResponse-btn "
                    //               >
                    //                 (Waiting for Transporters Response)
                    //               </button>
                    //             </Link>
                    //           </>
                    //         }
                    //         {
                    //           request.trip_status === "assigned" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 {request.trip_status}
                    //               </button>
                    //             </Link> <br />

                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="WaitingforTransportersResponse-btn "
                    //               >
                    //                 {/* (Waiting for Transporters Response) */}
                    //               </button>
                    //             </Link>
                    //           </>
                    //         }
                    //         {
                    //           request.trip_status === "Order Sent to Shipper" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 {request.trip_status}
                    //               </button>
                    //             </Link> <br />

                    //             <Link to="#" >
                    //               <button
                    //                 type="button"
                    //                 className="WaitingforTransportersResponse-btn "
                    //               >
                    //                 (Waiting for Shipper’s Response)
                    //               </button>
                    //             </Link>
                    //           </>
                    //         }
                    //         {
                    //           request.trip_status === "shipper_accepted" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 Shipper has approved the offer
                    //               </button>
                    //             </Link> <br />

                    //             <Link to={"#"} onClick={(e) => {
                    //               e.preventDefault();
                    //               localStorage.setItem("request", JSON.stringify(request))
                    //               navigate("/MatchMakingOnlineAuction");
                    //             }}>
                    //               <button
                    //                 type="button"
                    //                 className="green-link-btn "
                    //               >
                    //                 Assign Vehicles to Cargo
                    //               </button>
                    //             </Link>
                    //           </>
                    //         }
                    //         {
                    //           request.trip_status === "shipper_rejected" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 Shipper has Rejected the Offer
                    //               </button>
                    //             </Link> <br />

                    //             <Link to={"#"} onClick={(e) => {
                    //               e.preventDefault();
                    //               localStorage.setItem("request", JSON.stringify(request))
                    //               navigate("/SelectTransporter");
                    //             }} >
                    //               <button
                    //                 type="button"
                    //                 className="green-link-btn "
                    //               >
                    //                 Reassign Transporter
                    //               </button>
                    //             </Link>
                    //           </>
                    //         }
                    //         {request.trip_status === "match_made" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 Matching Making has been completed
                    //               </button>
                    //             </Link> <br />

                    //             <Link to="#" onClick={(e) => {
                    //               e.preventDefault();
                    //               localStorage.setItem("request", JSON.stringify(request))
                    //               navigate("/OrderConfirmationForTransporter");
                    //             }} >
                    //               <button
                    //                 type="button"
                    //                 className="green-link-btn "
                    //               >
                    //                 Send Order Confirmation
                    //               </button>
                    //             </Link>
                    //           </>

                    //         }
                    //         {request.trip_status === "Transporters Responded" &&
                    //           <>
                    //             <Link to="#">
                    //               <button
                    //                 type="button"
                    //                 className="  text-center TransportersAssigned-btn"
                    //               >
                    //                 Transporter’s Have Responded
                    //               </button>
                    //             </Link> <br />

                    //             <Link to="#" onClick={(e) => {
                    //               e.preventDefault();
                    //               localStorage.setItem("request", JSON.stringify(request))
                    //               navigate("/Auctions");
                    //             }}>
                    //               <button
                    //                 type="button"
                    //                 className="green-link-btn "
                    //               >
                    //                 View Response
                    //               </button>
                    //             </Link>
                    //           </>

                    //         }

                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                    <tr className="Rowbgcolor">
                      <td>
                        <label>{index + 1}</label>
                      </td>
                      <td>
                        <label>
                          <img
                            src={
                              ApiConfig.BASE_URL_FOR_IMAGES +
                                request.trip_image || ic_trucks
                            }
                            alt=""
                            className="directLoad-img card-rsponsive-img vehicle-request-img"
                            style={{
                              height: "22px",
                              width: "22px",
                              marginRight: "7px",
                            }}
                          />
                          {request.load_reference_no}
                        </label>
                      </td>
                      <td>
                        <label>
                          {request.trip_start_city} {request.trip_start_country}{" "}
                          - {request.trip_end_city} {request.trip_end_country}
                        </label>
                      </td>
                      <td>
                        <label>{request.offered_by}</label>
                      </td>
                      <td>
                        <label>{request.cargo_type}</label>
                      </td>
                      <td>
                        <label>
                          {request.quantity} {request.unit}.
                        </label>
                      </td>
                      <td>
                        <label>{request.estimated_start_date}</label>
                      </td>
                      <td>
                        <label>
                          {request.trip_status === "Transporters Assigned" && (
                            <>
                              <Link to="#">
                                <button
                                  type="button"
                                  className="  text-center TransportersAssigned-btn"
                                >
                                  Transporter’s Assigned
                                </button>
                              </Link>{" "}
                              <br />
                            </>
                          )}
                          {request.trip_status === "Order Sent to Shipper" && (
                            <>
                              <Link to="#">
                                <button
                                  type="button"
                                  className="  text-center TransportersAssigned-btn"
                                >
                                  {request.trip_status}
                                </button>
                              </Link>{" "}
                              <br />
                            </>
                          )}
                          {request.trip_status ===
                                      "shipper_accepted" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Shipper has approved the offer
                                          </button>
                                        </Link>
                                        <br />

                                        <br />
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem(
                                              "request",
                                              JSON.stringify(request)
                                            );
                                            navigate("/MatchVechicleWithCargo");
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="green-link-btn "
                                          >
                                            Assign Vehicles to Cargo
                                          </button>
                                        </Link>
                                      </>
                                    )}
                          {request.trip_status === "shipper_rejected" && (
                            <>
                              <Link to="#">
                                <button
                                  type="button"
                                  className="  text-center TransportersAssigned-btn"
                                >
                                  Shipper has Rejected the Offer
                                </button>
                              </Link>{" "}
                              <br />
                              <Link
                                to="#"
                                nClick={(e) => {
                                  e.preventDefault();
                                  localStorage.setItem(
                                    "request",
                                    JSON.stringify(request)
                                  );
                                  navigate("/AddVehicleSpecification");
                                }}
                              >
                                <button
                                  type="button"
                                  className="green-link-btn "
                                >
                                  Reassign Transporter
                                </button>
                              </Link>
                            </>
                          )}
                          {request.trip_status === "match_made" && (
                            <>
                              <Link to="#">
                                <button
                                  type="button"
                                  className="  text-center TransportersAssigned-btn"
                                >
                                  Matching Making has been completed
                                </button>
                              </Link>{" "}
                              <br />
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  localStorage.setItem(
                                    "request",
                                    JSON.stringify(request)
                                  );
                                  navigate("/OrderConfirmationForTransporter");
                                }}
                              >
                                <button
                                  type="button"
                                  className="green-link-btn "
                                >
                                  Send Order Confirmation
                                </button>
                              </Link>
                            </>
                          )}
                          {request.trip_status === "Transporters Responded" && (
                            <>
                              <Link to="#">
                                <button
                                  type="button"
                                  className="  text-center TransportersAssigned-btn"
                                >
                                  Transporter’s Have Responded
                                </button>
                              </Link>{" "}
                              <br />
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  localStorage.setItem(
                                    "request",
                                    JSON.stringify(request)
                                  );
                                  navigate("/TransporterResponse");
                                }}
                              >
                                <button
                                  type="button"
                                  className="green-link-btn "
                                >
                                  View Response
                                </button>
                              </Link>
                            </>
                          )}
                          {request.trip_status === "assigned" && (
                            <>
                              <Link to="#">
                                <button
                                  type="button"
                                  className="  text-center TransportersAssigned-btn"
                                >
                                  {request.trip_status}
                                </button>
                              </Link>{" "}
                              <br />
                            </>
                          )}
                        </label>
                      </td>
                      <td>
                        <label>
                          <div className="option-dropdown dropdown ">
                            <Link
                              to="#"
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={ic_options} alt="" />
                            </Link>
                            <ul className="dropdown-menu ">
                              <li>
                                <Link
                                  to={"/ViewDetails/" + request.trip_id}
                                  className="text-decoration-none"
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    View Details
                                    <img
                                      src={ic_dropdown_arrow}
                                      className="arrowvehicle"
                                      alt=""
                                    />
                                  </button>
                                </Link>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item mt-2"
                                  type="button"
                                  to="#offcanvasDriverAccount"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasFrightStatus"
                                  aria-controls="offcanvasRightAccount"
                                >
                                  View Transporter Response
                                </button>
                              </li>
                            </ul>
                          </div>
                        </label>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div
                  className="text-danger"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  No Load Available
                </div>
              )}
             </tbody>
             </table>

            </div>
          </div>
        )}
        </div>
    </main>
  );
};

export default OnlineAuctionAllRequest;
