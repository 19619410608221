import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import swal from "sweetalert";

const OrderConfirmationForTransporter = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const {
    mainClass,
    customerData,
    setApiCalling,
    ApiCaaling,
    previousPageData,
  } = useContext(AppContext);
  const [transporterDetails, setTransporterDetails] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const previousdata = JSON.parse(localStorage.getItem("request"));
  const [orderConfirmationData, setOrderConfirmationData] = useState({});
  const [vehicleCargoQuantity, setVehicleCargoQuantity] = useState({});
  const [operation_no, setOperation_no] = useState("");
  const [cargoType, setCargoTypes] = useState([]);
  const [transporterList, setTransporterList] = useState([]);
  const [tripCategory, setTripCategory] = useState("");
  const [errMsg, setErrMsg] = useState({
    operation_no: "",
    total_price: "",
    unit_price: "",
    loading_price: "",
  });

  let myList = [];
  let vehicle = [];
  useEffect(() => {
    myList = transporterList?.map((item) => {
      return setTransporterDetails(item);
    });
    vehicle =
      transporterDetails?.vehicles &&
      transporterDetails?.vehicles.map((Vehicleitem) => {
        return setVehicleDetails(Vehicleitem);
      });
    return () => {};
  }, [vehicle]);

  useEffect(() => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DROPDOWN_CARGOTYPES,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setCargoTypes(res.json.cargo_types);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    postWithAuthCallWithErrorResponse(
      ApiConfig.ORDER_CONFIRMATION_TRANSPORTERLIST,
      JSON.stringify({
        ...customerData,
        load_id: id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setTransporterList(res.json.transporter_list);
          setTripCategory(res.json.trip_category);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getFrightDetails = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.FRIGHT_DETAILS,
      JSON.stringify({ ...customerData, load_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        setOrderConfirmationData(res.json.load_details);
        setVehicleCargoQuantity(res.json.vehicle_cargo_quantity);
      }
    });
  };
  useEffect(() => {
    getFrightDetails();
    if (ApiCaaling) document.getElementById("continue-btn").disabled = true;
    else document.getElementById("continue-btn").disabled = false;
  }, [ApiCaaling]);
  const sendOrderConfrmation = (e) => {
    e.preventDefault();
    setApiCalling(true);
    if (!orderConfirmationData.total_price) {
      if (!operation_no) {
        // setErrMsg({ ...errMsg, operation_no: "Operation Number is required" });
        swal("Operation Number is required");
        return;
      }
      if (!orderConfirmationData.total_price) {
        setErrMsg({ ...errMsg, total_price: "Total price is required" });
        return;
      }
    } else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.SEND_ORDER_CONFIRMATION_DIRECT_ORDER,
        JSON.stringify({
          ...customerData,
          operation_no: operation_no,
          load_id: orderConfirmationData.trip_id,
          total_price: orderConfirmationData.total_price_value,
          unit_price: orderConfirmationData.unit_price,
          loading_price: orderConfirmationData.loading_price,
          trip_start_date: orderConfirmationData.trip_start_date,
        })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          if (res.json.result) {
            swal(res.json.message);
            setApiCalling(false);
            navigate("/DirectOrderHeader");
          } else {
            setApiCalling(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <main
      className={`OrderConfirmationForTransporter-main ${mainClass}`}
      id="cxMain"
    >
      {/* Order confirmation for Transporter */}
      <section className="common-body mb-4">
        <h5 className="OrderConfirmation-heading">
          Order confirmation for Transporter
        </h5>
        <hr />
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Operation Number</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={operation_no}
                onChange={(e) => {
                  setErrMsg({ ...errMsg, operation_no: "" });

                  setOperation_no(e.target.value);
                }}
              />
              {errMsg.operation_no && errMsg.operation_no.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.operation_no}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Company Name (Shipper)</label>
              <input
                type="text"
                disabled
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                defaultValue={orderConfirmationData.shipper}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Cargo Type</label>
              <select
                className="form-select"
                aria-label="Default select example"
                disabled
                value={orderConfirmationData.cargo_type}
              >
                <option selected>Select Vehicle Type</option>
                {cargoType.map((cargo, index) => {
                  return (
                    <option value={cargo.cargo_name} key={"cargoType" + index}>
                      {cargo.cargo_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Quantity</label>
              <input
                type="number"
                disabled
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                defaultValue={orderConfirmationData.total_quantity_value}
              />
            </div>
          </div>
          {(tripCategory === 'online') ? (<>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Commission Fee({orderConfirmationData.trip_bid_commission})% (ETB)</label>
              <input
                disabled
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={((orderConfirmationData.total_price_value)*parseFloat(orderConfirmationData.trip_bid_commission)/100).toFixed(2)}
                onChange={(e) => {
                  setErrMsg({ ...errMsg, total_price: "" });

                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    total_price: e.target.value,
                  });
                }}
              />
              {errMsg.total_price && errMsg.total_price.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.total_price}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Unit Price (ETB)</label>
              <input
                disabled
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={orderConfirmationData.unit_price}
                onChange={(e) => {
                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    unit_price: e.target.value,
                  });
                }}
              />
              {errMsg.unit_price && errMsg.unit_price.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.unit_price}</span>
                </div>
              )}
            </div>
          </div>
          
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Total Price (ETB)</label>
              <input
                disabled
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={parseFloat(orderConfirmationData.total_price_value) + (((orderConfirmationData.total_price_value)*parseFloat(orderConfirmationData.trip_bid_commission)/100))}
                onChange={(e) => {
                  setErrMsg({ ...errMsg, total_price: "" });

                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    total_price: e.target.value,
                  });
                }}
              />
              {errMsg.total_price && errMsg.total_price.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.total_price}</span>
                </div>
              )}
            </div>
          </div></>):
          (<><div className="col-lg-3 col-md-6">
          <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">Unit Price (ETB)</label>
            <input
              disabled
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={orderConfirmationData.unit_price}
              onChange={(e) => {
                setOrderConfirmationData({
                  ...orderConfirmationData,
                  unit_price: e.target.value,
                });
              }}
            />
            {errMsg.unit_price && errMsg.unit_price.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.unit_price}</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">Total Price (ETB)</label>
            <input
              disabled
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={orderConfirmationData.total_price_value}
              onChange={(e) => {
                setErrMsg({ ...errMsg, total_price: "" });

                setOrderConfirmationData({
                  ...orderConfirmationData,
                  total_price: e.target.value,
                });
              }}
            />
            {errMsg.total_price && errMsg.total_price.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.total_price}</span>
              </div>
            )}
          </div>
        </div></>)}
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Loading Price (ETB)</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={orderConfirmationData.loading_price}
                onChange={(e) => {
                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    loading_price: e.target.value,
                  });
                }}
              />
              {errMsg.loading_price && errMsg.loading_price.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.loading_price}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Expected Arrival Date</label>
              <input
                type="date"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                disabled
                value={orderConfirmationData.trip_start_date}
                onChange={(e) => {
                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    trip_start_date: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <h4 className="card-heading-order-confirmation">
            Transporter Details
          </h4>
          {/* <button className="add-btn-orderConfirmation">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
          </button> */}
        </div>
        <div className="" style={{ width: "100%", overflow: "auto" }}>
          <table
            className="order-confirm-table "
            style={{ minWidth: "1050px" }}
          >
            <thead>
              <tr>
                <th>
                  <label>Transporter’s Name</label>
                </th>
                <th>
                  <label>Reference No. (Offer Vehicle)</label>
                </th>
                <th>
                  <label>Loading Truck Plate Number</label>
                </th>
                <th>
                  <label>Driver Name</label>
                </th>
                <th>
                  <label>Driver Cell Phone</label>
                </th>
                <th>
                  <label>Vehicle Cargo Qty</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {transporterList.map((trasporter, index) => {
                return (
                  <React.Fragment
                    className="tr-body"
                    key={"trasporter" + index}
                  >
                    {trasporter.vehicles &&
                      trasporter.vehicles.map((vehicle, index1) => {
                        return (
                          <tr key={"vehicle" + index1} className="tr-body">
                            <td>
                              <p>{index1 === 0 && trasporter.user_name}</p>
                            </td>
                            <td>
                              <p>{orderConfirmationData?.trip_reference_no}</p>
                            </td>
                            <td>
                              <p>{vehicle.plate_number}</p>
                            </td>
                            <td>
                              <p>{vehicle.driver_name}</p>
                            </td>
                            <td>
                              <p>{vehicle.driver_mobile}</p>
                            </td>
                            <td>
                              <p>{vehicle.vehicle_cargo_quantity}</p>
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>

      {/* Order Confirmation for Transport Market place and Delivery Order0 */}
      <section className="common-body " style={{ marginBottom: "80px" }}>
        <h5 className="OrderConfirmation-heading">
          Order Confirmation for Transport Market place and Delivery Order
        </h5>
        <hr />
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">
                Reference Number (Offer Goods)
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                value={orderConfirmationData?.trip_reference_no}
                aria-describedby="emailHelp"
                disabled
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">
                Company Name (Shipper)
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                defaultValue={orderConfirmationData.shipper}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Cargo Type</label>
              <select
                className="form-select"
                aria-label="Default select example"
                disabled
                value={orderConfirmationData.cargo_type}
              >
                <option selected>Select Vehicle Type</option>
                {cargoType.map((cargo, index) => {
                  return (
                    <option value={cargo.cargo_name} key={"cargoType" + index}>
                      {cargo.cargo_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Quantity</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                disabled
                defaultValue={orderConfirmationData.total_quantity_value}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Unit Price (ETB)</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                disabled
                value={orderConfirmationData.unit_price}
                onChange={(e) => {
                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    unit_price: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Total Price (ETB)</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                disabled
                value={orderConfirmationData.total_price_value}
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, total_price: "" });

                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    total_price: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Loading Price (ETB)</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                disabled
                value={orderConfirmationData.loading_price}
                onChange={(e) => {
                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    loading_price: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Expected Arrival Date</label>
              <input
                type="date"
                className="form-control"
                id="exampleInputEmail1"
                disabled
                aria-describedby="emailHelp"
                value={orderConfirmationData.trip_start_date}
                onChange={(e) => {
                  setOrderConfirmationData({
                    ...orderConfirmationData,
                    trip_start_date: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">
                Loading Truck Plate Number
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                defaultValue={vehicleDetails && vehicleDetails.plate_number}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Driver Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                defaultValue={vehicleDetails && vehicleDetails.driver_name}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Driver Cell Phone</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                defaultValue={vehicleDetails && vehicleDetails.driver_mobile}
              />
            </div>
          </div> */}
        </div>
      </section>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="cancel" className="cancel-btn">
              Cancel
            </button>
          </a>
          {/* <Link to="/DirectOrderHeader"> */}
          <button
            type="submit"
            className="continue-btn"
            id="continue-btn"
            onClick={sendOrderConfrmation}
          >
            Send
          </button>
          {/* </Link> */}
        </div>
      </div>

      {/* Modal */}
    </main>
  );
};

export default OrderConfirmationForTransporter;
