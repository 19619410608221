import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import EditTransporterApproval from "./EditTransporterApproval";
import EditShipperApproval from "./EditShipperApproval";
import EditDriverApproval from "./EditDriverApproval";
import EditVehicleApproval from "./EditVehicleApproval";

const EditApprovalHeader = () => {
  const { mainClassB } = useContext(AppContext);
  return (
    <main className={`direct-order-main ${mainClassB}`} id="cxMainB">
      <ul
        className="nav nav-pills mb-1 searchOrder"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <Link
            to="/VehiclesDetails"
            className="nav-link tab-link active"
            id="pills-driverdetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-driverdetails"
            type="button"
            role="tab"
            aria-controls="pills-driverdetails"
            aria-selected="true"
          >
            Transporter
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/DriverDetails"
            className="nav-link tab-link"
            id="pills-customerdetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-customerdetails"
            type="button"
            role="tab"
            aria-controls="pills-customerdetails"
            aria-selected="false"
          >
            Shipper
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/DriverDetails"
            className="nav-link tab-link"
            id="pills-vehicle-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-vehicle"
            type="button"
            role="tab"
            aria-controls="pills-vehicle"
            aria-selected="false"
          >
            Vehicle
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/DriverDetails"
            className="nav-link tab-link"
            id="pills-driver-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-driver"
            type="button"
            role="tab"
            aria-controls="pills-driver"
            aria-selected="false"
          >
            Driver
          </Link>
        </li>
      </ul>
      <div
        className="tab-content "
        id="pills-tabContent"
        style={{ marginBottom: "80px", overflow: "auto" }}
      >
        <div
          className="tab-pane fade show active"
          id="pills-driverdetails"
          role="tabpanel"
          aria-labelledby="pills-driverdetails-tab"
        >
          <EditTransporterApproval />
        </div>

        <div
          className="tab-pane fade"
          id="pills-customerdetails"
          role="tabpanel"
          aria-labelledby="pills-customerdetails-tab"
        >
          <EditShipperApproval />
        </div>
        <div
          className="tab-pane fade"
          id="pills-vehicle"
          role="tabpanel"
          aria-labelledby="pills-vehicle-tab"
        >
          <EditVehicleApproval />
        </div>
        <div
          className="tab-pane fade"
          id="pills-driver"
          role="tabpanel"
          aria-labelledby="pills-driver-tab"
        >
          <EditDriverApproval />
        </div>
      </div>
    </main>
  );
};

export default EditApprovalHeader;
