import React, {
  useContext,
  useEffect,
  useshipperDetails,
  useState,
} from "react";
import drag_drop_default from "../../assets/images/drag_drop_default.svg";
import ic_remove from "../../assets/images/ic_remove.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import default_profile from "../../assets/images/logo_default_bg.svg";
import ic_camera from "../../assets/images/camera.svg";
import { AppContext } from "../../context/user/AppContext";
import CountrySelect from "../../sharedComponent/CountrySelect";
import CitySelect from "../../sharedComponent/CitySelect";
import DragAndDrop from "../../sharedComponent/DragAndDrop";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { dataURLtoFile, toBase64 } from "../../fileservice/fileService";
import swal from "sweetalert";

const AddShipper = () => {
  const {
    mainClass,
    setApiCalling,
    customerData,
    shipperDetails,
    setShipperDetails,
  } = useContext(AppContext);
  const params = useParams();
  let id = params.id;
  const navigate = useNavigate();
  let previous = useLocation().shipperDetails;
  const [fromSeconpage, setFromSeconpage] = useState(
    previous ? previous : false
  );
  const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg"];

  const [country, setCountry] = useState("");
  useEffect(() => {
    if (id) {
      setApiCalling(true);
      postWithAuthCallWithErrorResponse(
        ApiConfig.SHIPPER_DETAILS,
        JSON.stringify({ ...customerData, shipper_id: id })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setApiCalling(false);
          if (res.json.result) {
            let shipper = res.json.shipper_details;
            setCountry(shipper.user_country);
            setShipperDetails({
              id: shipper.user_id,
              company_name: shipper.company_name,
              email: shipper.user_email,
              password: "",
              phone_no: shipper.user_mobile,
              city: shipper.user_city,
              region: shipper.user_region,
              country: shipper.user_country,
              po_number: shipper.user_po_number,
              contact_person: shipper.contact_person,
              contact_person_responsibility: shipper.user_responsibility,
              contact_person_phone: shipper.contact_person_phone,
              contact_person_email: shipper.contact_person_email,
              total_fleet_size: shipper.user_fleet_size,
              alternate_phone: shipper.user_alternate_phone,
              business_area: shipper.business_area,
              charge_type: shipper.charge_type,
              currency: shipper.currency,
              remarks: shipper.shipper_remarks,
              rateper_20gp: shipper.shipper_rateper_20gp,
              rateper_40gp: shipper.shipper_rateper_40gp,
              rateper_quintal: shipper.shipper_rateper_quintal,
              profile_picture: shipper.user_profile_pic,
              contract: shipper.shipper_contract,
              business_license: shipper.user_business_license_document,
              tn_document: shipper.user_tn_document,
              grade_certificate: shipper.user_grade_certificate_document,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(async () => {
    sessionStorage.setItem("shipperData", JSON.stringify(shipperDetails));
  }, [shipperDetails]);
  const [errMsg, setErrMsg] = useState({
    company_name: "",
    email: "",
    password: "",
    phone_no: "",
    city: "",
    region: "",
    country: "",
    po_number: "",
    contact_person: "",
    contact_person_responsibility: "",
    contact_person_phone: "",
    contact_person_email: "",
    total_fleet_size: "",
    business_area: "",
    charge_type: "",
    currency: "",
    remarks: "",
    rateper_20gp: "",
    rateper_40gp: "",
    rateper_quintal: "",
    // profile_picture: "",
    business_license: "",
    grade_certificate: "",
    tn_document: "",
    contract: "",
  });
  const goToNextStep = (e) => {
    e.preventDefault();
    if (
      // !shipperDetails.profile_picture ||
      !shipperDetails.company_name ||
      !shipperDetails.business_area ||
      !shipperDetails.contact_person ||
      !shipperDetails.contact_person_responsibility ||
      !shipperDetails.contact_person_phone ||
      !shipperDetails.contact_person_email ||
      (!shipperDetails.password && !id) ||
      !shipperDetails.country ||
      !shipperDetails.region ||
      !shipperDetails.city ||
      !shipperDetails.phone_no ||
      !shipperDetails.email ||
      !shipperDetails.po_number ||
      !shipperDetails.business_license ||
      !shipperDetails.tn_document ||
      !shipperDetails.grade_certificate
    ) {
      // if (!shipperDetails.profile_picture) {
      //   setErrMsg({
      //     ...errMsg,
      //     profile_picture: "Profile Picture is required",
      //   });
      //   window.scrollTo(0, 0);
      //   return;
      // }
      if (!shipperDetails.company_name) {
        setErrMsg({ ...errMsg, company_name: "Company name is required" });
        window.scrollTo(0, 0);
        return;
      }
      if (!shipperDetails.business_area) {
        setErrMsg({ ...errMsg, business_area: "Business Area is required" });
        window.scrollTo(0, 100);
        return;
      }
      if (!shipperDetails.contact_person) {
        setErrMsg({
          ...errMsg,
          contact_person: "Contact Person Name is required",
        });
        window.scrollTo(0, 100);
        return;
      }
      if (!shipperDetails.contact_person_responsibility) {
        setErrMsg({
          ...errMsg,
          contact_person_responsibility:
            "Contact Person responsibilty is required",
        });
        window.scrollTo(0, 200);
        return;
      }
      if (!shipperDetails.contact_person_phone) {
        setErrMsg({
          ...errMsg,
          contact_person_phone: "Contact Person phone is required",
        });
        window.scrollTo(0, 200);
        return;
      }
      if (!shipperDetails.contact_person_email) {
        setErrMsg({
          ...errMsg,
          contact_person_email: "Contact Person email id is required",
        });
        window.scrollTo(0, 300);
        return;
      }
      if (!shipperDetails.password && !id) {
        setErrMsg({ ...errMsg, password: "Password is required" });
        window.scrollTo(0, 300);
        return;
      }
      if (!shipperDetails.country) {
        setErrMsg({ ...errMsg, country: "Country is required" });
        window.scrollTo(0, 400);
        return;
      }
      if (!shipperDetails.region) {
        setErrMsg({ ...errMsg, region: "Region is required" });
        window.scrollTo(0, 400);
        return;
      }
      if (!shipperDetails.city) {
        setErrMsg({ ...errMsg, city: "City is required" });
        window.scrollTo(0, 500);
        return;
      }
      if (!shipperDetails.phone_no) {
        setErrMsg({ ...errMsg, phone_no: "Phone no is required" });
        window.scrollTo(0, 500);
        return;
      }

      if (!shipperDetails.email) {
        setErrMsg({ ...errMsg, email: "Email id is required" });
        window.scrollTo(0, 600);
        return;
      }
      if (!shipperDetails.po_number) {
        setErrMsg({ ...errMsg, po_number: "Po Box number id is required" });
        window.scrollTo(0, 700);
        return;
      }
      if (!shipperDetails.business_license) {
        setErrMsg({
          ...errMsg,
          business_license: "Business License is required",
        });
        window.scrollTo(0, 700);
        return;
      }
      if (!shipperDetails.tn_document) {
        setErrMsg({ ...errMsg, tn_document: "Tin Document is required" });
        return;
      }
      if (!shipperDetails.grade_certificate) {
        setErrMsg({
          ...errMsg,
          grade_certificate: "VAT Registration is required",
        });
        window.scrollTo(0, 800);
        return;
      }
    } else {
      if (!validateEmail(shipperDetails.contact_person_email)) {
        setErrMsg({
          ...errMsg,
          contact_person_email: "Enter  email id in proper format",
        });
        window.scrollTo(0, 800);
        return;
      }
      if (!validateEmail(shipperDetails.email)) {
        setErrMsg({ ...errMsg, email: "Enter email id in proper format" });
        window.scrollTo(0, 800);
        return;
      }

      navigate("/AddShipperSecond");
    }
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <main className={`main-section-AddVehicle ${mainClass}`} id="cxMain">
      <div className="main-body-AddVehicle">
        {/* Progress bar block */}
        <section className="progress-block">
          <div className="row">
            <div className="col-12">
              <div className="main-content p-4 row">
                <div className="left col-lg-9 col-md-8">
                  <h4 className="" id="heading-addVehicles">
                    ADD SHIPPER{" "}
                  </h4>
                  <div
                    className="progress"
                    style={{ height: "8px", margin: "10px" }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "3%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="right col-lg-3 col-md-4 mt-4 ">
                  <p className="progress-status">Shipper Details (1/2)</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Vehicle Info Add */}
        <section className="main-vehicle-info mt-4">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="cx-profile-pic">
                  <label htmlFor="file">
                    <img
                      className="cx-pdefault"
                      src={
                        !shipperDetails.profile_picture
                          ? default_profile
                          : shipperDetails.profile_picture.includes("base64")
                          ? shipperDetails.profile_picture
                          : ApiConfig.BASE_URL_FOR_IMAGES +
                            shipperDetails.profile_picture
                      }
                      style={{
                        display: "inline-block",
                        height: "120px",
                        width: "120px",
                        borderRadius: "50%",
                      }}
                    />
                  </label>
                  <div className="camera-icon">
                    <input
                      type="file"
                      accept="image/*"
                      id="file"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, profile_picture: "" });
                        let reader = new FileReader();
                        reader.onload = function (e) {
                          setShipperDetails({
                            ...shipperDetails,
                            profile_picture: reader.result,
                          });
                        };
                        if (
                          !allowedFileTypes.includes(e.target.files[0].type) ||
                          e.target.files[0].size > 5242880
                        ) {
                          swal({
                            icon: "error",
                            title: "Invalid file type",
                            text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                          });
                          setShipperDetails({
                            ...shipperDetails,
                            profile_picture: "",
                          });
                        } else reader.readAsDataURL(e.target.files[0]);
                        
                      }}
                    />
                    <label
                      htmlFor="file"
                      className="upload-profile-image"
                      style={{ cursor: "pointer" }}
                    >
                      <img className="cx-camera" src={ic_camera} /> Upload Logo
                    </label>
                  </div>
                </div>
                {/* {errMsg.profile_picture.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.profile_picture}</span>
                  </div>
                )} */}
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.company_name}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, company_name: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            company_name: e.target.value,
                          });
                        }}
                      />
                      {errMsg.company_name.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.company_name}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Business Area</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.business_area}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, business_area: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            business_area: e.target.value,
                          });
                        }}
                      />
                      {errMsg.business_area.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.business_area}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Contact Person</label>
                      <input
                        type="tel"
                        className="form-control"
                        value={shipperDetails.contact_person}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, contact_person: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            contact_person: e.target.value,
                          });
                        }}
                      />
                      {errMsg.contact_person.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Contact Person Responsibility
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.contact_person_responsibility}
                        onChange={(e) => {
                          setErrMsg({
                            ...errMsg,
                            contact_person_responsibility: "",
                          });
                          setShipperDetails({
                            ...shipperDetails,
                            contact_person_responsibility: e.target.value,
                          });
                        }}
                      />
                      {errMsg.contact_person_responsibility.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person_responsibility}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Contact Person Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        aria-describedby="emailHelp"
                        maxLength={10}
                        value={shipperDetails.contact_person_phone}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (!isNaN(e.target.value)) {
                            setErrMsg({ ...errMsg, contact_person_phone: "" });
                            setShipperDetails({
                              ...shipperDetails,
                              contact_person_phone: e.target.value,
                            });
                          } else {
                            setErrMsg({
                              ...errMsg,
                              contact_person_phone:
                                "Phone number does not contains a alphabet",
                            });
                          }
                        }}
                      />
                      {errMsg.contact_person_phone.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person_phone}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Contact Person Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.contact_person_email}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, contact_person_email: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            contact_person_email: e.target.value,
                          });
                        }}
                      />
                      {errMsg.contact_person_email.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.contact_person_email}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Login Password</label>
                      <input
                        type="password"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.password}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, password: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            password: e.target.value,
                          });
                        }}
                      />
                      {errMsg.password.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.password}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Address */}

        <section className="main-vehicle-info  mt-4">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <h5 className="card-heading">Address</h5>
                <hr />

                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <CountrySelect
                        setCountry={(country) => {
                          setErrMsg({ ...errMsg, country: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            country: country.value,
                          });
                          setCountry(country.isoCode);
                        }}
                        country={shipperDetails.country}
                      />
                      {errMsg.country.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.country}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Region</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.region}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, region: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            region: e.target.value,
                          });
                        }}
                      />
                      {errMsg.region.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.region}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <CitySelect
                        country={country}
                        setCity={(city) => {
                          setErrMsg({ ...errMsg, city: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            city: city.value,
                          });
                        }}
                        city={shipperDetails.city}
                      />
                      {errMsg.city.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.city}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        aria-describedby="emailHelp"
                        maxLength={10}
                        value={shipperDetails.phone_no}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (!isNaN(e.target.value)) {
                            setErrMsg({ ...errMsg, phone_no: "" });
                            setShipperDetails({
                              ...shipperDetails,
                              phone_no: e.target.value,
                            });
                          } else {
                            setErrMsg({
                              ...errMsg,
                              phone_no:
                                "Phone number does not contains a alphabet",
                            });
                          }
                        }}
                      />
                      {errMsg.phone_no.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.phone_no}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Alternate Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        aria-describedby="emailHelp"
                        maxLength={10}
                        value={shipperDetails.alternate_phone}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (!isNaN(e.target.value)) {
                            setErrMsg({ ...errMsg, alternate_phone: "" });
                            setShipperDetails({
                              ...shipperDetails,
                              alternate_phone: e.target.value,
                            });
                          } else {
                            setErrMsg({
                              ...errMsg,
                              alternate_phone:
                                "Phone number does not contains a alphabet",
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.email}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, email: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                      {errMsg.email.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.email}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">P.O.Box</label>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={shipperDetails.po_number}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, po_number: "" });
                          setShipperDetails({
                            ...shipperDetails,
                            po_number: e.target.value,
                          });
                        }}
                      />
                      {errMsg.po_number.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.po_number}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  Documents */}

        <section className="documents-section">
          <div className="common-body mt-4 p-3">
            <div className="row">
              <div className="top-content mb-3">
                <h5 className="card-heading">Documents</h5>
                <hr />
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">Business License</label>
                <DragAndDrop
                  setFile={(file) => {
                    setErrMsg({ ...errMsg, business_license: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setShipperDetails({
                        ...shipperDetails,
                        business_license: reader.result,
                      });
                    };
                    if (
                      !allowedFileTypes.includes(file.type) ||
                      file.size > 5242880
                    ) {
                      swal({
                        icon: "error",
                        title: "Invalid file type",
                        text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                      });
                      setShipperDetails({
                        ...shipperDetails,
                        business_license: "",
                      });
                    } else reader.readAsDataURL(file);
                  }}
                  id="business_license"
                />
                <div className="uploaded_file d-flex justify-content-between">
                  <label className="col-4 text-truncate">
                    {shipperDetails.business_license}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShipperDetails({
                        ...shipperDetails,
                        business_license: "",
                      });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {shipperDetails.business_license && (
                  <a
                    href={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                      shipperDetails.business_license
                    }
                    className="preview_link"
                    target="_blank"
                    // data-bs-toggle="modal"
                    // data-bs-target="#TIN_Modal"
                  >
                    Preview
                  </a>
                )}
                {errMsg.business_license.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.business_license}</span>
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">TIN (Tax Identification Number)</label>
                <DragAndDrop
                  setFile={(file) => {
                    setErrMsg({ ...errMsg, tn_document: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setShipperDetails({
                        ...shipperDetails,
                        tn_document: reader.result,
                      });
                    };
                    if (
                      !allowedFileTypes.includes(file.type) ||
                      file.size > 5242880
                    ) {
                      swal({
                        icon: "error",
                        title: "Invalid file type",
                        text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                      });
                      setShipperDetails({
                        ...shipperDetails,
                        tn_document: "",
                      });
                    } else reader.readAsDataURL(file);
                  }}
                  id="tn_document"
                />
                <div className="uploaded_file d-flex justify-content-between">
                  <label className="col-4 text-truncate">
                    {shipperDetails.tn_document}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShipperDetails({ ...shipperDetails, tn_document: "" });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {shipperDetails.tn_document && (
                  <a
                    href={
                      ApiConfig.BASE_URL_FOR_IMAGES + shipperDetails.tn_document
                    }
                    className="preview_link"
                    target="_blank"
                    // data-bs-toggle="modal"
                    // data-bs-target="#TIN_Modal"
                  >
                    Preview
                  </a>
                )}
                {errMsg.tn_document.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.tn_document}</span>
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">VAT Registration</label>
                <DragAndDrop
                  setFile={(file) => {
                    setErrMsg({ ...errMsg, grade_certificate: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setShipperDetails({
                        ...shipperDetails,
                        grade_certificate: reader.result,
                      });
                    };
                    if (
                      !allowedFileTypes.includes(file.type) ||
                      file.size > 5242880
                    ) {
                      swal({
                        icon: "error",
                        title: "Invalid file type",
                        text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                      });
                      setShipperDetails({
                        ...shipperDetails,
                        grade_certificate: "",
                      });
                    } else reader.readAsDataURL(file);
                  }}
                  id="grade_certificate"
                />
                <div className="uploaded_file d-flex justify-content-between">
                  <label className="col-4 text-truncate">
                    {shipperDetails.grade_certificate}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShipperDetails({
                        ...shipperDetails,
                        grade_certificate: "",
                      });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {shipperDetails.grade_certificate && (
                  <a
                    href={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                      shipperDetails.grade_certificate
                    }
                    className="preview_link"
                    target="_blank"
                    // data-bs-toggle="modal"
                    // data-bs-target="#TIN_Modal"
                  >
                    Preview
                  </a>
                )}
                {errMsg.grade_certificate.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.grade_certificate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="submit" className="cancel-btn">
              Cancel
            </button>
          </a>
          <Link to="#">
            <button
              type="submit"
              className="continue-btn"
              id="continue-btn"
              onClick={goToNextStep}
            >
              Continue
            </button>
          </Link>
          {/* <div className="login-btn">
                <button
                  type="submit"
                  
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}> Continue</span>
                </button>
              </div> */}
        </div>
      </div>
    </main>
  );
};

export default AddShipper;
