import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { AppContext } from "../context/user/AppContext";
import logo from "../assets/images/Login/logo.svg";
import Barloader from "./BarLoader";
const Login = ({ setLoggedIn }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
    device_token: "",
    device_id: "",
    app_version: "",
    app_type: "admin",
    device_os: "web",
  });
  const [errMsg, setErrMsg] = useState({ username: "", password: "" });
  const { setCustomerData, setCustomerDetails } = useContext(AppContext);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordLoadingMessage, setPasswordLoadingMessage] = useState(false);

  const login = (e) => {
    e.preventDefault();
    if (userDetails.username === "" || userDetails.password === "") {
      if (userDetails.username === "") {
        setErrMsg({ ...errMsg, username: "Enter Email ID " });
        return;
      }
      if (userDetails.password === "") {
        setErrMsg({ ...errMsg, password: "Enter Password " });
        return;
      }
    } else {
      setLoading(true);
      postWithAuthCallWithErrorResponse(
        ApiConfig.LOGIN,
        JSON.stringify({ ...userDetails })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setLoading(false);
          if (res.json.result) {
            localStorage.setItem("api_key", res.json.api_key);
            localStorage.setItem("customer_id", res.json.customer_id);
            localStorage.setItem("user_id", res.json.user_id);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.json.user_details)
            );
            localStorage.setItem("logedIn", true);
            setCustomerData({
              customer_id: res.json.customer_id,
              user_id: res.json.user_id,
              api_key: res.json.api_key,
            });
            setCustomerDetails({
              name: res.json.user_details.user_name,
              email: res.json.user_details.user_email,
            });
            setLoggedIn(true);
          } else swal(res.json.message);
        })
        .catch((err) => {
          navigate("/ServerError");
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(()=>{
    setPasswordLoadingMessage(false); 
  }, []);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/;
    if (passwordRegex.test(password)) {
      setPasswordLoadingMessage(false)
      setIsPasswordValid(true);
    } else {
      setPasswordLoadingMessage(true)
      setIsPasswordValid(false);
    }
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    const newPassword = event.target.value;
    setErrMsg({ ...errMsg, password: "" });
    setUserDetails({
      ...userDetails,
      password: newPassword,
    });
    validatePassword(newPassword);
  };

  return (
    <section className=" container-fluid login-main-section ">
      {loading && <Barloader />}
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content">
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics plc.</h3>
            <p className="left-login-paragraph">
              Abay Logistics PLC provides end-to-end logistics solutions by
              subcontracting from customers and helping them focus on their
              primary business activities.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <h3>Login</h3>

            <p className="welcome-login">
              Welcome to <span>Abay Logistics</span>
            </p>
            <form action="">
              <div className="mb-3 row">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-12">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    required
                    value={userDetails.username}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, username: "" });
                      setUserDetails({
                        ...userDetails,
                        username: e.target.value,
                      });
                    }}
                  />
                  {errMsg.username.length > 0 && (
                    <span className="text-danger">{errMsg.username}</span>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-12 col-form-label"
                >
                  Password
                </label>
                <div className="col-sm-12">
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    value={userDetails.password}
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                  />
                  {errMsg.password.length > 0 && (
                    <span className="text-danger">{errMsg.password}</span>
                  )}
                  {(!isPasswordValid && passwordLoadingMessage)&& (
                  <h6 className="my-2" style={{ color: "red" }}>
                    Password must be at least 8 characters long
                    <br /> Password must contain a lowercase letter, an
                    uppercase letter
                    <br />
                    Password must contain number or special character.
                  </h6>
                )}
                </div>
              </div>
              <div className="forgot">
                <Link to="/ForgotPassword">Forgot Password?</Link>
              </div>
              <div className="login-btn">
                <a
                  href="#"
                  className=" col-sm-12 col-md-12 col-lg-12"
                  onClick={login}
                >
                  Login
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
