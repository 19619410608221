import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import { AppContext } from '../context/user/AppContext';
const ServerError = () => {
    const { mainClass} = useContext(AppContext);

    return (
        <>
        <main className={`main-body-Notification ${mainClass}`} id="cxMain">
                <h3>Server Error</h3>
                <Link to="/" >
                    <h6>Go to Login</h6>
                </Link>
                </main>

        </>
    
    );
}
export default ServerError;