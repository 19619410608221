import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/BarLoader";
import remove from "../../assets/images/ic_remove_red.svg";
import { AppContext } from "../../context/user/AppContext";
import swal from "sweetalert";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

const DirectOfferedVehicleHistory = () => {
  const { customerData, mainClass } = useContext(AppContext);
  const [vehicleOffers, setVehicleOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getvehicleOffer();

    return () => {
      setVehicleOffers([]);
    };
  }, []);

  const getvehicleOffer = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DIRECT_ORDER_OFFERED_VEHICLE,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setVehicleOffers(res.json.offer_list);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });

  

  return (
    <main className={`vehicle-list  ${mainClass}`} id="cxMain">
      <div
        style={{ width: "100%", overflow: "auto" }}
        className="vehicle-offer-direct-order-table"
      >
        {loading ? (
          <Loader />
        ) : (
          <table
            className="dataList"
            id="example"
            style={{ minWidth: "2200px" }}
          >
            <thead>
              <tr>
                <th>
                  <label htmlFor="">S.No</label>
                </th>
                <th>
                  <label htmlFor="">Reference Number</label>
                </th>
                <th>
                  <label htmlFor="">Destination</label>
                </th>

                <th>
                  <label htmlFor="">Cargo Type</label>
                </th>
                <th>
                  <label htmlFor="">Container Type</label>
                </th>
                <th>
                  <label htmlFor="">Quantity</label>
                </th>
                <th>
                  <label htmlFor=""> Company Name</label>
                </th>
                {/* <th>
                  <label htmlFor=""> Vehicle Availability Date</label>
                </th> */}
                {/* <th>
                  <label htmlFor="">Unit</label>
                </th> */}
                <th>
                  <label htmlFor=""> Start Country</label>
                </th>
                <th>
                  <label htmlFor=""> End Country</label>
                </th>
                <th>
                  <label htmlFor=""> Start City</label>
                </th>
                <th>
                  <label htmlFor=""> End City</label>
                </th>
                <th>
                  <label htmlFor=""> Start Address</label>
                </th>
                <th>
                  <label htmlFor=""> End Address</label>
                </th>
                <th>
                  <label htmlFor=""> License No</label>
                </th>
                <td>
                  <label htmlFor="">Status</label>
                </td>
                {/* <th>
                  <label htmlFor="">Vehicles</label>
                </th> */}
                
                {/* <th>
                  <label htmlFor="">Total Price</label>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {vehicleOffers ? (
                vehicleOffers.length &&
                vehicleOffers.map((vehicleOffer, index) => {
                  return (
                    <tr className="t-row mb-3" key={"vehicleOffer" + index}>
                      <td>{index + 1}</td>
                      <td>{vehicleOffer.load_reference_no} </td>
                      <td>
                        {vehicleOffer.trip_start_city}{" "}
                        {vehicleOffer.trip_start_country} -{" "}
                        {vehicleOffer.trip_end_city}{" "}
                        {vehicleOffer.trip_end_country}
                      </td>

                      <td>
                                  <label className="data m-0">{vehicleOffer.cargo_type}</label>
                              </td>
                              <td>
                                {vehicleOffer.cargo_type == "Container" ? (
                                  <label className="data m-0">
                                    {vehicleOffer.container_type}
                                  </label>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                              {vehicleOffer.cargo_type == "Container" ? (
                                <label className="data m-0">{vehicleOffer.pending_quantity === "" ? vehicleOffer.quantity : vehicleOffer.pending_quantity} Quintals</label>
                              ):vehicleOffer.cargo_type == "Vehicle" ? (
                                <label className="data m-0">{vehicleOffer.pending_quantity === "" ? vehicleOffer.quantity : vehicleOffer.pending_quantity} Vehicles</label>
                              ):(<label className="data m-0">{vehicleOffer.pending_quantity === "" ? vehicleOffer.quantity : vehicleOffer.pending_quantity} Quintals</label>)}
                              </td>
                      <td>{vehicleOffer.user_name} </td>
                      {/* <td>{vehicleOffer.trip_vehicle_availability_date} </td> */}
                      {/* <td>{vehicleOffer.unit} </td> */}
                      <td>{vehicleOffer.trip_start_country} </td>
                      <td>{vehicleOffer.trip_end_country} </td>
                      <td>{vehicleOffer.trip_start_city} </td>
                      <td>{vehicleOffer.trip_end_city} </td>
                      <td
                        className="text-truncate"
                        style={{ maxWidth: "150px" }}
                      >
                        {vehicleOffer.trip_start_address}{" "}
                      </td>
                      <td
                        className="text-truncate"
                        style={{ maxWidth: "150px" }}
                      >
                        {vehicleOffer.trip_end_address}{" "}
                      </td>
                      <td>{vehicleOffer.vehicle_number} </td>
                      <td>{vehicleOffer.trip_status} </td>
                      {/* <td>
                        <Link
                          to="/SelectTransporter"
                          className="status-SeeVehicleOffers"
                          state={vehicleOffer}
                        >
                          View Details
                        </Link>
                      </td> */}
                      {/* <td>{vehicleOffer.total_price}</td> */}
                      {/* <td className="btn-position-style">
                        <Link
                          to="#"
                          className="accept-remove-btn"
                          onClick={() => {
                            rejectDirectOrder(vehicleOffer.trip_id);
                          }}
                        >
                          <img src={remove} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="accept-remove-btn"
                          onClick={() => {
                            acceptDirectOrder(vehicleOffer.trip_id);
                          }}
                        >
                          Accept
                        </Link>
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <span className="m-auto text-center text-danger">
                  no data found
                </span>
              )}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
};

export default DirectOfferedVehicleHistory;
