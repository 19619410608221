import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import remove from "../../assets/images/ic_remove_red.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

const OnlineVehicleOffersHistory = () => {
  const { customerData, mainClass } = useContext(AppContext);
  const [onlinevehicleOffers, setOnlineVehicleOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOnlineVehicleOffer();

    return () => {
      setOnlineVehicleOffers([]);
    };
  }, []);

  const getOnlineVehicleOffer = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ONLINE_VEHICLE_OFFER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setOnlineVehicleOffers(res.json.offer_list);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });

  return (
    <main className={`vehicle-list ${mainClass}`} id="cxMain">
      <div style={{ width: "100%", overflow: "auto" }}>
        {loading ? (
          <Loader />
        ) : (
          <table
            className="dataList vehicle-offer-direct-order-table"
            id="example"
            style={{ minWidth: "800px" }}
          >
            <thead>
              <tr>
                <th>
                  <label htmlFor="">S.No</label>
                </th>
                <th>
                  <label htmlFor="">Reference Number</label>
                </th>
                <th>
                  <label htmlFor="">Destination</label>
                </th>
                {/* <th>
                  <label htmlFor="">Vehicles</label>
                </th> */}
                <th>
                  <label htmlFor="">Best Bid</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {onlinevehicleOffers ? (
                onlinevehicleOffers.length &&
                onlinevehicleOffers.map((vehicleOffer, index) => {
                  return (
                    <tr className="t-row mb-3" key={"vehicleOffer" + index}>
                      <td>{index + 1}</td>
                      <td>{vehicleOffer.load_reference_no} </td>
                      <td>
                        {vehicleOffer.trip_start_city}{" "}
                        {vehicleOffer.trip_start_country} -{" "}
                        {vehicleOffer.trip_end_city}{" "}
                        {vehicleOffer.trip_end_country}
                      </td>
                      {/* <td>
                        <Link
                          to="/SelectTransporter"
                          className="status-SeeVehicleOffers"
                          state={vehicleOffer}
                        >
                          View Details
                        </Link>
                      </td> */}
                      <td>
                        
                        <Link
                          to="/AuctionDetails"
                          className="status-SeeVehicleOffers ms-2"
                          state={vehicleOffer}
                        >
                          View Details
                        </Link>
                      </td>
                      <td></td>
                      <td>
                        
                      </td>
                    </tr>
                  );
                })
              ) : (
                <span className="text-danger">no data found</span>
              )}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
};

export default OnlineVehicleOffersHistory;
