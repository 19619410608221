import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/Login/logo.svg";

const ForgotOTP = () => {
  return (
    <section className=" container-fluid login-main-section ">
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content">
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics</h3>
            <p className="left-login-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <div className="GobackBtn-title">
              <h3>Forgot Password</h3>
              <Link to="/ForgotPassword" className="Go-back-btn">
                Go Back
              </Link>
            </div>

            <p className="welcome-login">
              Enter the code sent your registered email id to continue
            </p>
            <form action="">
              <div className="mb-3 row">
                <div className="col-sm-12 mt-4">
                  <div id="otp-input-main">
                    <input
                      type="text"
                      className="form-control"
                      id="inputEmail"
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="inputEmail"
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="inputEmail"
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="inputEmail"
                      required
                      size="1"
                      maxLength="1"
                    />
                  </div>
                  <div className="resend-link-text mt-4" id="resend-link-text">
                    <p>
                      Didn't receive code? <Link to="#"> Resend</Link>
                    </p>
                  </div>
                  <span className="text-danger"></span>
                </div>
              </div>
              <div className="login-btn">
                <Link
                  to="/ResetPassword"
                  className=" col-sm-12 col-md-12 col-lg-12"
                >
                 VERIFY
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotOTP;
