import React, { useContext, useEffect, useState } from "react";
import right_arrow from "../../assets/images/ic_right_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import { Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { useLocation } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import Loader from "../../sharedComponent/Loader";

const Auctions = () => {
  const [show, setShow] = useState(false);
  const [vehicleModel, setvehicleModel] = useState(false);
  const [selectedBidIds, setSelectedBidIds] = useState([]);
  const [notSelectedBidIdValues, setNotSelectedBidIdValues] = useState([]);
  const [bidRateValue, setBidRateValue] = useState({new_bid_rate:0});
  const [bidCommissionValue, setBidCommissionValue] = useState({bid_commission: 0})
  const [vehicleBid, setVehicleBid] = useState({});
  const [bidRateIsSet, setBidRateIsSet] = useState(false);
  const navigate = useNavigate();
  const previousPageData = JSON.parse(localStorage.getItem("request"));
  const { mainClass, customerData, setApiCalling, ApiCaaling } =
    useContext(AppContext);
  const [auctionDetails, setAuctionDetails] = useState({
    ...previousPageData,
    bid_list: [],
    vehicle_Details: [],
  });
  const [selectedBid, seSelectedBid] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ONLINE_ORDERES_DETAILS,
      JSON.stringify({ ...customerData, load_id: auctionDetails.trip_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setAuctionDetails({
            ...auctionDetails,
            ...res.json.auction_details,
            bid_list: res.json.bid_list,
            vehicle_Details: res.json.bid_list[0].vehicle_list,
            //bid_commission: res.json.bid_list[0].vehicle_list.trip_bid_commission,
          });
          setBidCommissionValue({bid_commission: res.json.bid_list[0].vehicle_list[0].trip_bid_commission});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  const handleCheckboxChange = (event) => {
    const checkboxId = Number(event.target.id);
    if (event.target.checked) {
      setSelectedBidIds((prevIds) => [...prevIds, checkboxId]);
    } else {
      setSelectedBidIds((prevIds) => prevIds.filter((id) => id !== checkboxId));
    }
  };
  const editHandle = () => {
    const id = document.getElementsByClassName('bid_message')[0];
    const id_second = document.getElementsByClassName('bid_message')[1];   
    id.style.display = 'block';
    id_second.style.display = 'block';

    const commission_id = document.getElementsByClassName('bid_commission_message')[0];
    const commission_id_second = document.getElementsByClassName('bid_commission_message')[1];   
    commission_id.style.display = 'block';
    commission_id_second.style.display = 'block';
  }
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleChange = (event) => {
    let entered_val = event.target.value;
    if (isNaN(entered_val) || (entered_val + '').includes(' ') || parseInt(entered_val) === 0) {
      swal({
        text: 'Enter Correct Value',
        icon: "warning",
        dangerMode: true,
      });
      document.getElementsByClassName('bid_message')[1].value = '';
    }
    else {
    setBidRateValue({new_bid_rate:0})
    let value= event.target.value === '' ? 0 : event.target.value;
    setBidRateValue({new_bid_rate:value});
    }
  }

  const handleCommissionChange = (event) => {
    let entered_val = event.target.value;
    if (isNaN(entered_val) || (entered_val + '').includes(' ') || parseInt(entered_val) === 0) {
      swal({
        text: 'Enter Correct Commission Value',
        icon: "warning",
        dangerMode: true,
      });

      document.getElementsByClassName('bid_commission_message')[1].value = '';
    }
    else {
    let value = event.target.value=== ''? 10 : event.target.value;
    setBidCommissionValue({bid_commission: 10});
    setBidCommissionValue({bid_commission: value});
    //setBidRateValue((bidRateValue)=> {...bidRateValue, {}});
    }
  }
  const sendOffer = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.ONLINE_ORDERS_SEND_OFFER,
      JSON.stringify({ ...customerData, bid_id: selectedBid, 
        selected_bid_ids: selectedBidIds, 
          adjusted_bid:bidRateValue, 
            not_selected_bid_ids: notSelectedBidIdValues, 
              adjusted_commission: bidCommissionValue })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        if (res.json.result) {
          swal(res.json.message);
          navigate("/OnlineAuctionHeader");
        } else {
          swal({
            text: res.json.message,
            icon: "warning",
            dangerMode: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    bidRateIsSetCalc();
  }, [auctionDetails]);
  function bidRateIsSetCalc() {
    auctionDetails.bid_list.map(e => {
      if (e.trip_bid_isset)
        setBidRateIsSet(e.trip_bid_isset);
    });
  }
  
  useEffect(()=> {
    let notSelectedBidIds = [];
    auctionDetails.bid_list.map((e) => {
      if (!selectedBidIds.includes(e.bid_id)) {
        notSelectedBidIds.push(e.bid_id);
      }
    });
    setNotSelectedBidIdValues([]);
    setNotSelectedBidIdValues(notSelectedBidIds);
  }, [selectedBidIds]);
  
  return (
    <main className="Auctions-main">
      <div className={`main ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="details-main ">
            <div className="Auctioncards ">
              <div className="details-card ">
                <div className="row justify-content-center p-2 ">
                  {/* left card */}
                  <div className="col-lg-5  mt-2 ">
                    <div className="card card-style left-card ">
                      <div className="m-2">
                        <div className="row">
                          <div className=" ps-4  card_title d-flex justify-content-between align-items-start ">
                            <h4 className="card-gods ">Auction Details</h4>
                          </div>

                          <div className="user_block ">
                            <div
                              className=" justify-content-between align-items-start  "
                              id="short"
                            >
                              <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                                <h5 className="godside">Auction Name </h5>
                                <span className="allside">
                                  {auctionDetails.auction_name}
                                </span>
                              </div>
                            </div>
                            <div
                              className=" justify-content-between align-items-start  "
                              id="days"
                            >
                              <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                                <h5 className="godside">Auction Duration</h5>
                                <span className="allside">
                                  {" "}
                                  {auctionDetails.duration} days
                                </span>
                              </div>
                            </div>

                            <div
                              className=" justify-content-between align-items-start  "
                              id="short"
                            >
                              <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                                <h5 className="godside">Auction Type</h5>
                                <span className="allside">
                                  {auctionDetails.auction_type}
                                </span>
                              </div>
                            </div>

                            <div
                              className=" justify-content-between align-items-start  "
                              id="days"
                            >
                              <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                                <h5 className="godside"> Start Date</h5>
                                <span className="allside">
                                  {auctionDetails.auction_start_date}
                                </span>
                              </div>
                            </div>
                            <div
                              className=" justify-content-between align-items-start  "
                              id="short"
                            >
                              <div className="p-1 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                                <h5 className="godside">Expiry Date</h5>
                                <span className="allside">
                                  {auctionDetails.auction_end_date}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="row  make_bid m-1">
                              <button
                                type="button"
                                data-bs-dismiss="offcanvas"
                                className="btn-lg     d-flex
                            justify-content-between
                            align-items-center"
                                id="save"
                              >
                                <span>
                                  <Link
                                    to="/GoodsDetails"
                                    className="text-decoration-none"
                                    state={auctionDetails}
                                  >
                                    View Goods Details{" "}
                                  </Link>
                                </span>
                                <img src={right_arrow} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* RIGHT CARD  */}
                  <div className="col-lg-5  mt-2 ">
                    <div className="card card-style left-card ">
                      <div className="container-fluid ">
                        <div className="row pt-2">
                          <div className=" d-flex justify-content-between ">
                            <h4 className="card-gods">All Bids</h4>
                          </div>

                          {auctionDetails.bid_list.map((bid) => {
                            return (
                              

                              <div
                                className=" justify-content-between align-items-start mb-2 "
                                id="short"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={bid.bid_id}
                                  onChange={handleCheckboxChange}
                                  checked={selectedBidIds.includes(
                                    bid.bid_id
                                  )}
                                  
                                />
                                <div className="all-bids-items p-2">
                                  <div className="  d-flex justify-content-between  rsponsive-card-data">
                                    <span className="allside">
                                      {bid.user_name} - {bid.date_time}
                                    </span>
                                    <br></br>
                                    
                                  </div>
                                  {(bidRateValue.new_bid_rate !== 0) ?
                                  (<div className="  d-flex justify-content-between  rsponsive-card-data">                                
                                    <span className="allside">Bidding Rate:  
                                      {(parseInt(bidRateValue.new_bid_rate) + parseInt(bidRateValue.new_bid_rate)*parseInt(bidCommissionValue.bid_commission)/100).toFixed(2)}</span>
                                    <br></br>
                                    <br></br>
                                    <span className="allside">Added Commission ({bidCommissionValue.bid_commission}%):  
                                      {(parseInt(bidRateValue.new_bid_rate)*(parseInt(bidCommissionValue.bid_commission))/100).toFixed(2)}</span>
                                  </div>) :
                                  (<div className="  d-flex justify-content-between  rsponsive-card-data">                                
                                    <span className="allside">Bidding Rate:  
                                      {((parseInt(bid.rate))+(parseInt(bid.rate))*(parseInt(bidCommissionValue.bid_commission)/100)).toFixed(2)}</span>
                                    <br></br>
                                    <br></br>
                                    <span className="allside">Added Commission ({bidCommissionValue.bid_commission}%): {' ETB '} 
                                      {(parseInt(bid.rate)*(parseInt(bidCommissionValue.bid_commission)/100)).toFixed(2)}</span>
                                  </div>)}
                                  <hr></hr>
                                  <div className="  d-flex justify-content-between  rsponsive-card-data">                                 
                                    {( bidRateValue.new_bid_rate !== 0) ? 
                                      <span className="allside">Edited Bidding Rate: ETB {(bidRateValue.new_bid_rate*1.00).toFixed(2)}</span> 
                                      : <span className="allside">Transporter's Bidding Rate: ETB {(bid.rate*1.00).toFixed(2)}</span>}
                                  </div>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setvehicleModel(true);
                                      setVehicleBid({bid_id: bid.bid_id});
                                    }}
                                  >
                                    View Vehicle Details
                                  </Link>
                                  <hr />
                                  {/* <div className="text-end">
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        seSelectedBid(bid.bid_id);
                                        handleShow();
                                      }}
                                    >
                                      Send Offer
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            );
                          })}                          
                          <div className="">
                          
                          {(bidRateIsSet===true) ?
                            <></> : <label style={{display: 'none'}} className="bid_message">Enter Bid</label>}
                          
                          {(bidRateIsSet === true) ? <></>:
                          <>
                          <input 
                                      style={{display:'none'}}
                                      type="text"
                                      className="bid_message"
                                      name="message"
                                      onChange={e=>handleChange(e)}
                          />
                          <label style={{display: 'none'}} className="bid_commission_message">Bid Commission</label>
                          <input 
                          style={{display:'none'}}
                          type="text"
                          className="bid_commission_message"
                          name="commission_message"
                          onChange={e=>handleCommissionChange(e)}
                          /></>}
                          <br />
                          
                          {(bidRateIsSet===true) ? <>Rebid - </> : <a href='#' onClick={editHandle}>Edit</a>}
                          
                          <span> </span>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        //seSelectedBid(bid.bid_id);
                                        handleShow();
                                        // sendOffer();
                                      }}
                                    >
                                      Send Offer
                                    </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box vehicle-requests-main-modal "
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <p className="text-center popup-heading mt-4">
            Are you sure you want to send this offer to shipper?{" "}
          </p>
        </div>
        <div className="btn-popup-modal text-center">
          <button className="popup-reject-btn me-4 ">Reject </button>
          <button className="popup-approve-btn m-1 ">
            <Link
              to="#"
              className="text-decoration-none"
              style={{ color: "#4FBFA4" }}
              onClick={() => sendOffer()}
            >
              Send
            </Link>
          </button>
        </div>
      </Modal>
      <Modal
        show={vehicleModel}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={() => setvehicleModel(false)}>
          Vehicle Details
        </Modal.Header>
        <Modal.Body>
          {/* {console.log("act det1 " + JSON.stringify(vehicleBid))}
          {console.log("act det " + JSON.stringify(auctionDetails))}
          {console.log("fadsfa " + 
          JSON.stringify(auctionDetails?.bid_list.filter(e => {
            console.log("bid id " + e.bid_id);
            console.log("bid id2 " + vehicleBid.bid_id);
            if (e.bid_id===vehicleBid.bid_id) {
              console.log("bid id3 " + vehicleBid.bid_id);
              return true;
            } else
              return false;
          })[0].vehicle_list))
          // .vehicle_list?.map(f=>{
          //   console.log("ff " + JSON.stringify(f));
          // }))
          } */}
          {auctionDetails?.bid_list &&
            auctionDetails?.bid_list?.filter(e=>e.bid_id===vehicleBid.bid_id)[0]?.vehicle_list?.map((vehicle, index) => {
              return (
                // <>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">{index + 1}. Owner Name</h5>
                //     <span className="allside">{vehicle.owner_name}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Driver Name</h5>
                //     <span className="allside">{vehicle.driver_name}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Plate Number</h5>
                //     <span className="allside">{vehicle.plate_number}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Vehicle Capacity</h5>
                //     <span className="allside">{vehicle.vehicle_capacity}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Vehicle Type</h5>
                //     <span className="allside">{vehicle.vehicle_type}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Vehicle Model No</h5>
                //     <span className="allside">{vehicle.vehicle_model_no}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Nationality</h5>
                //     <span className="allside">{vehicle.nationality}</span>
                //   </div>
                //   { 
                //      (vehicle?.trailer_id !== "") ?
                //    (<><p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - </p>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Vehicle Type</h5>
                //     <span className="allside">Trailer</span>
                //   </div>      
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Plate Number</h5>
                //     <span className="allside">{vehicle?.trailer?.vehicle_number}</span>
                //   </div>
                //   <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                //     <h5 className="godside">Vehicle Capacity</h5>
                //     <span className="allside">{vehicle?.trailer?.vehicle_capacity}</span>
                //   </div>
                //   </>) : <></>}
                //   <hr />
                // </>
                <Table responsive>
                <thead>
                  <tr>
                    <th>Owner Name</th>
                    <th>Vehicle Type</th>
                    <th>Plate Number</th>
                    <th>Vehicle Capacity</th>
                    <th>Driver Name</th>
                    <th>Vehicle Model</th>
                    <th>Nationality</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{vehicle.owner_name}</td>
                    <td>{vehicle.vehicle_type}</td>
                    <td>{vehicle.plate_number}</td>
                    <td >{vehicle.vehicle_capacity}</td>
                    <td>{vehicle.driver_name}</td>
                    <td >{vehicle.vehicle_model_no}</td>
                    <td>{vehicle.nationality}</td>
                  </tr>
                  { 
                     (vehicle?.trailer_id !== "") ?
                   (
                   <tr>
                    <td className="allside">{vehicle.owner_name}</td>
                    <td className="allside">Trailer</td>     
                    <td className="allside">{vehicle?.trailer?.vehicle_number}</td>
                    <td className="allside">{vehicle?.trailer?.vehicle_capacity}</td>
                  </tr>
                  ) : <tr></tr>}
                </tbody>
              </Table>
              );
            })}
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Auctions;
