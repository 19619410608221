import React, { useContext, useState } from 'react'
import OnlineAuctionAllRequest from './OnlineAuctionAllRequest'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/user/AppContext'
const OnlineAuctionHeader = () => {
  const { mainClassB } = useContext(AppContext);
  const [searchText, setSearchText] = useState("")
  return (
    <main className='OnlineAuction-mai'>
      <div className={`header-body ${mainClassB}`} id="cxMainB">
        <ul className="nav nav-pills mb-1 searchOrder" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <Link
              to="/VehiclesDetails"
              className="nav-link tab-link active"
              id="pills-driverdetails-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-driverdetails"
              type="button"
              role="tab"
              aria-controls="pills-driverdetails"
              aria-selected="true"
            >
              All Requests
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              to="/DriverDetails"
              className="nav-link tab-link"
              id="pills-customerdetails-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-customerdetails"
              type="button"
              role="tab"
              aria-controls="pills-customerdetails"
              aria-selected="false"
            >
              My Requests
            </Link>
          </li>
          <li className="searchtab">
            <input type={"text"} className="form-control" placeholder="Search By Reference No" onChange={(e) => { setSearchText(e.target.value) }} />
          </li>
        </ul>
        <div
          className="tab-content "
          id="pills-tabContent"
          style={{ marginBottom: "80px", overflow: "auto" }}
        >
          <div
            className="tab-pane fade show active"
            id="pills-driverdetails"
            role="tabpanel"
            aria-labelledby="pills-driverdetails-tab"
          >
            <OnlineAuctionAllRequest All={true} searchText={searchText} />
          </div>

          <div
            className="tab-pane fade"
            id="pills-customerdetails"
            role="tabpanel"
            aria-labelledby="pills-customerdetails-tab"
          >
            <OnlineAuctionAllRequest searchText={searchText} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default OnlineAuctionHeader