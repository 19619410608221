import React, { useContext } from "react";
import "../../assets/css/truckAuction.scss";
import ic_right_arrow from "../../assets/images/truckoffer/ic_right_arrow.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";

const TruckofferAuction = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <React.Fragment className="h-100 w-100">
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <div className={`main ${mainClass}`} id="cxMain">
        <div className="details-main ">
          <div className="AuctionCards ">
            <div className="details-card ">
              <div className="row justify-content-center ">

                <div className="col-lg-5 col-md-8 col-sm-10 mt-2">
                  <div className="card card-style left-card ">
                    <div className="m-2">
                      <div className="row">
                        <div className=" ms-3  card_title  align-items-start mb-1">
                          <h4 className="Auction_duration">Auction Details</h4>
                        </div>

                        <div className="user_block ">
                          <div
                            className=" justify-content-between align-items-start p-2 "
                            id="short"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Auction Name</h5>
                              <span className="allside">Auction Name</span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start mb-2 "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  mt-2 rsponsive-card-data">
                              <h5 className="godside">Auction Duration</h5>
                              <span className="allside">3 days</span>
                            </div>
                          </div>

                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                              <h5 className="godside">Auction Type</h5>
                              <span className="allside">Short Term</span>
                            </div>
                          </div>

                          <div
                            className=" justify-content-between align-items-start mt-2  "
                            id="days"
                          >
                            <div className=" d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Start Date</h5>
                              <span className="allside">22th Dec 2022</span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                              <h5 className="godside">Expiray Date</h5>
                              <span className="allside">22th Dec 2022</span>
                            </div>
                          </div>

                          <h4 className="bestoffer ms-4">Best Offer</h4>

                          <div className="p-2" id="bids">
                            <div className=" d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Kevin Trapp .</h5>
                              <span className="allside"> 27 Dec 2021</span>
                              <p>$ 420</p>
                            </div>
                            <div className="ps-3  align-items-start  border-bottom mb-4 ">
                              <Link
                                to="/VehicleDriverDetailsHeader"
                                id="kevin"
                                className=" text-decoration-none "
                              >
                                View Vehicle Details
                              </Link>
                            </div>
                            <div className="row">
                              <div className="Auction_links">
                                <a href="" className="me-3 reject">
                                  REJECT
                                </a>
                                <a href="" className="me-3 accept">
                                  ACCEPT
                                </a>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className=" justify-content-between align-items-start viewArow  mt-4">
                        <a to="#">
                          <div
                            className=" d-flex align-items-middle justify-content-between p-3"
                            id="blank"
                          >
                            <Link
                              to="/GoodsDetails"
                              className="text-decoration-none"
                            >
                              <h5 className="All text-ceneter  mt-2">
                                View Goods Details
                              </h5>
                            </Link>
                            <img
                              src={ic_right_arrow}
                              style={{ width: "30px", height: "40px" }}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TruckofferAuction;
