import React, { useContext } from "react";
import "../../assets/css/transistauction.scss";
import { AppContext } from "../../context/user/AppContext";
const TransistAuction = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <React.Fragment className="h-100 w-100">

      <div className={`main ${mainClass}`} id="cxMain">
        <div className="details-main ">
          <div className="Auctioncards ">
            <div className="details-card ">
              <div className="row justify-content-center p-2">
                {/* left card */}
                <div className="col-lg-5  mt-2">
                  <div className="card card-style left-card ">
                    <div className="container-fluid ">
                      <div className="row pt-3">
                        <div className=" d-flex justify-content-between ">
                          <h4 className="card-gods ">Goods Details</h4>
                          <h5 id="All">View All</h5>
                        </div>
                        <div
                          className=" justify-content-between align-items-start mb-2 "
                          id="short"
                        >
                          <div className="p-1  d-flex justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Reference Number</h5>
                            <span className="allside">UEWRDF4353</span>
                          </div>
                        </div>
                        <div
                          className=" justify-content-between align-items-start  "
                          id="days"
                        >
                          <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Company Name</h5>
                            <span className="allside">Redbytes</span>
                          </div>
                        </div>

                        <div
                          className=" justify-content-between align-items-start mb-2 "
                          id="short"
                        >
                          <div className="p-1  d-flex align-items-middle justify-content-between rsponsive-card-data ">
                            <h5 className="godside">Cargo Type</h5>
                            <span className="allside">Break bulk</span>
                          </div>
                        </div>

                        <div
                          className=" justify-content-between align-items-start  "
                          id="days"
                        >
                          <div className=" p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Quantity</h5>
                            <span className="allside">10 Quintals</span>
                          </div>
                        </div>
                        <div
                          className=" justify-content-between align-items-start mb-2 "
                          id="short"
                        >
                          <div className="p-1 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                            <h5 className="godside">Expected Arrival Time</h5>
                            <span className="allside">22th Dec 2022, 10:30 AM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* RIGHT CARD  */}
                <div className="col-lg-5  mt-2">
                  <div className="card card-style left-card ">
                    <div className="m-2">
                      <div className="row">
                        <div className=" ps-4  card_title d-flex justify-content-between align-items-start ">
                          <h4 className="card-gods ">Auction Details</h4>
                        </div>

                        <div className="user_block ">
                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Auction Name </h5>
                              <span className="allside">Auction Name </span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Auction Duration</h5>
                              <span className="allside"> 3 days</span>
                            </div>
                          </div>

                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="p-1  d-flex align-items-middle justify-content-between  rsponsive-card-data">
                              <h5 className="godside">Auction Type</h5>
                              <span className="allside">Short Term</span>
                            </div>
                          </div>

                          <div
                            className=" justify-content-between align-items-start  "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Your Bid</h5>
                              <span className="allside">None</span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="short"
                          >
                            <div className="p-1 d-flex align-items-middle justify-content-between  rsponsive-card-data">
                              <h5 className="godside">Start Date</h5>
                              <span className="allside">22th Dec 2022</span>
                            </div>
                          </div>
                          <div
                            className=" justify-content-between align-items-start  "
                            id="days"
                          >
                            <div className="  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                              <h5 className="godside">Expiry Date</h5>
                              <span className="allside">25th Dec 2022</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="bottom">
                            <div className="text-center mb-2">
                              <label className="text-center"> Enter Bid Amount</label>

                            </div>
                            <div className="ms-2 me-2 mb-2 justify-content-center ">
                              <input className="form-control" type="number" min="1" max="1000" placeholder="450$">

                              </input>
                            </div>
                          </div>


                          <div className="row  make_bid m-1">

                            <button type="button"

                              data-bs-dismiss="offcanvas"
                              className="btn-lg " id="save">
                              Make a Bid
                            </button>

                          </div>





                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransistAuction;
