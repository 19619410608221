import React, { useContext, useState } from "react";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import { Link } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import ChangeDriver from "./ChangeDriver";
import { Modal, Button } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
const VehicleRequestTransporterDetail = () => {

  $(document).ready(function () {
    $(".dataList").DataTable();
  });
  const { mainClass } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (

    <main className="">
      <div
        className={`VehicleRequestTransporterDetail-main ${mainClass}`} id="cxMain"
        style={{ overflow: "auto", padding: "30px 0" }}
      >
        <table className="table-striped dataList table " id="">
          <thead>
            <tr>
              <th>
                <label>S.No</label>
              </th>
              <th>
                <label>Owner Name</label>
              </th>
              <th>
                <label>Driver Name</label>
              </th>
              <th>
                <label>Vehicle Type</label>
              </th>
              <th>
                <label>Model</label>
              </th>
              <th>
                <label>Plate No.</label>
              </th>
              <th>
                <label>Status</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="Rowbgcolor p-2">
              <td>1</td>
              <td id="">Joshua Kimmich </td>
              <td id="">
                <Link to="#">Leroy Sane</Link> </td>
              <td id="">
                Eicher pro 1110Xp<span className="etc">...</span>{" "}
              </td>
              <td id="">TN No 2021</td>
              <td id="">Robert Lewondoski</td>
              <td id="">Pune</td>

              <div className="option-dropdown dropdown p-2">
                <a href="" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleShow}>
                  <img src={ic_options} alt="" />
                </a>
                {/* <ul className="dropdown-menu ">
                  <li>
                    <button className="dropdown-item" type="button">
                      Edit{" "}
                      <img
                        src={dropdown_arrow_blue}
                        className="arrowvehicle"
                        alt=""
                      />
                    </button>
                  </li>
                  <li>
                    <Link to="/ViewDetails" className="text-decoration-none">
                      <button className="dropdown-item" type="button">
                        View Details
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/VehicleList" className="text-decoration-none">
                      <button className="dropdown-item " type="button">
                        View Vehicles
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ContractTransporter"
                      className="text-decoration-none"
                    >
                      <button className="dropdown-item" type="button">
                        View Contract
                      </button>
                    </Link>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button">
                      Call
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button">
                      Block
                    </button>
                  </li>
                </ul> */}
              </div>
            </tr>
            <tr className="Rowbgcolor p-2">
              <td>2</td>
              <td >Joshua Kimmich</td>
              <td ><Link to="#">Leroy Sane</Link></td>
              <td >
                Eicher pro 1110Xp<span className="etc">...</span>{" "}
              </td>
              <td >TN No 2021</td>
              <td >Robert Lewondoski</td>
              <td >Pune</td>

              <div className="option-dropdown dropdown p-2">
                <a href="" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleShow}>
                  <img src={ic_options} alt="" />
                </a>
                {/* <ul className="dropdown-menu ">
                  <li>
                    <button className="dropdown-item" type="button">
                      Edit{" "}
                      <img
                        src={dropdown_arrow_blue}
                        className="arrowvehicle"
                        alt=""
                      />
                    </button>
                  </li>
                  <li>
                    <Link to="/ViewDetails" className="text-decoration-none">
                      <button className="dropdown-item" type="button">
                        View Details
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/VehicleList" className="text-decoration-none">
                      <button className="dropdown-item " type="button">
                        View Vehicles
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ContractTransporter"
                      className="text-decoration-none"
                    >
                      <button className="dropdown-item" type="button">
                        View Contract
                      </button>
                    </Link>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button">
                      Call
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button">
                      Block
                    </button>
                  </li>
                </ul> */}
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-vehicle-popup"
      >
        <Modal.Header closeButton>
          <h4 className="card-heading">Vehicle Details</h4>
        </Modal.Header>
        <div>
          <hr />
          <section className="vehicle-detail-section mb-0 common-body p-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="image">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={Skyscrapers}
                          className="d-block "
                          alt={"..."}
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={Skyscrapers}
                          className="d-block "
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={Skyscrapers}
                          className="d-block "
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-section">
                  <div className="right-top-content d-flex justify-content-between">
                    <div className="title-vehicle">
                      <h4 className="name-vehicle">
                        Eicher Pro 1110XP
                      </h4>
                    </div>
                    <div className="icon-dropdown dropdown">
                      <a
                        href=""
                        className=""
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={ic_options_menu} alt="" height="24" />
                      </a>

                      <ul className="dropdown-menu">
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                          >
                            Edit{" "}
                            <img
                              src={dropdown_arrow_blue}
                              className="arrow"
                              alt=""
                            />
                          </button>
                        </li>
                        <li>
                          <Link
                            to="/ChangeDriver"
                            className="text-decoration-none"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasDriverAccount"
                            aria-controls="offcanvasRightAccount"
                          >
                            <button
                              className="dropdown-item "
                              type="button"
                            >
                              Change Driver
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                          >
                            Block
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom-content row mt-4">
                    <div className="col-lg-4 left item">
                      <label>Plate Number</label>
                      <p className="data">UI343546UF890</p>
                    </div>
                    <div className="col-lg-4 middel item">
                      <label>Vehicle Type</label>
                      <p className="data">Truck - Trailer</p>
                    </div>
                    <div className=" col-lg-4 right item">
                      <label>Year of Manufacture</label>
                      <p className="data">Reefer</p>
                    </div>
                  </div>
                  <div className="bottom-content row mt-3">
                    <div className="col-lg-4 left item">
                      <label>Chassis Number</label>
                      <p className="data">45678</p>
                    </div>
                    <div className="col-lg-4 middel item">
                      <label>Motor Number</label>
                      <p className="data">2345</p>
                    </div>
                    <div className="col-lg-4 right item">
                      <label>Gross Weight</label>
                      <p className="data">2345</p>
                    </div>
                  </div>
                  <div className="bottom-content row mt-3">
                    <div className="col-lg-4 left item">
                      <label>Vehicle Load Capacity</label>
                      <p className="data">10 Quintals</p>
                    </div>

                    <div className="col-lg-4 right item">
                      <label>Initial km</label>
                      <p className="data">1234</p>
                    </div>
                  </div>
                  <h5 className="card-heading">GPS Availability</h5>
                  <div className="bottom-content row mt-3">
                    <div className="col-lg-4 left item">
                      <label>Vendor Name</label>
                      <p className="data">Name</p>
                    </div>

                    <div className="col-lg-4 right item">
                      <label>Vendor Contact</label>
                      <p className="data">Contact</p>
                    </div>
                    <div className="col-lg-4 right item">
                      <label>Vendor Platform</label>
                      <p className="data">Platform</p>
                    </div>
                    <div className="col-lg-4 right item">
                      <label>Vendor Address</label>
                      <p className="data">Address</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ChangeDriver />
        </div>
      </Modal>
    </main>
  );
};

export default VehicleRequestTransporterDetail;
