export async function PostCallWithErrorResponse(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });
  
  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function multipartPostCallWithErrorResponse(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: requestBody,
  });

  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function getWithAuthCallWithErrorResponse(url) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function postWithAuthCallWithErrorResponse(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    body: requestBody,
  });

  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function putMultipartWithAuthCallWithErrorResponse(url, requestBody) {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Token " + localStorage.getItem("USER_AUTH_TOKEN"),
    },
    body: requestBody,
  });

  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function postMultipartWithAuthCallWithErrorResponse(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: requestBody,
  });

  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function simpleGetCallWithErrorResponse(url) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();

  return {
    response,
    json,
    error: !response.ok,
  };
}

export async function simpleGetCall(url) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response.text().then((result) => getResult(result));
}

export async function simplePostCall(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true,
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function simplePostCallAll(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true,
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function multipartPostCall(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function getWithAuthCall(url) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("USER_AUTH_TOKEN"),
    },
  });

  return response.text().then((result) => getResult(result));
}

export async function postWithAuthCall(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function putWithAuthCall(url, requestBody) {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function postMultipartWithAuthCall(url, requestBody) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function putMultipartWithAuthCall(url, requestBody) {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function updateProfile(url, requestBody) {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    body: requestBody,
  });

  return response.text().then((result) => getResult(result));
}

export async function getResult(data) {
  return JSON.parse(data.trim());
}
