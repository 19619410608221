import React, { useContext, useEffect, useState } from "react";

import "../../assets/css/style.scss";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const TransporterApproval = () => {
  const { customerData } = useContext(AppContext);
  const [rejectedUserLists, setApprovedList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRejectedUsersList = () => {
    var uri = ApiConfig.TRANSPORTER_REJECT_LIST;
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setApprovedList(res.json.transporter_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const deleteTransport = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETEREJECTED_TRANSPORTER,
      JSON.stringify({
        ...customerData,
        transporter_id: id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        swal(res.json.message);
        getRejectedUsersList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRejectedUsersList();
  }, []);

  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  {loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <table className="dataList" style={{ minWidth: "900px" }}>
                      <thead>
                        <tr>
                          <th>
                            <label>S.No</label>
                          </th>
                          <th>
                            <label>Company Type</label>
                          </th>
                          <th>
                            <label>Company Name</label>
                          </th>
                          <th>
                            <label>Contact Person</label>
                          </th>
                          <th>
                            <label>Fleet Size</label>
                          </th>
                          <th>
                            <label>Country</label>
                          </th>
                          <th>
                            <label>Contract</label>
                          </th>

                          <th>
                            <label>Status</label>
                          </th>
                          <th>
                            <label>Action</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rejectedUserLists &&
                          rejectedUserLists.map((rejectedUser, index) => {
                            return (
                              <tr className="Rowbgcolor">
                                <td>
                                  <label>{index + 1}</label>
                                </td>
                                <td>
                                  <label>{rejectedUser.company_type}</label>
                                </td>
                                <td>
                                  <label>{rejectedUser.company_name}</label>
                                </td>
                                <td>
                                  <label>
                                    {rejectedUser.contact_person_phone}
                                  </label>
                                </td>
                                <td>
                                  <label>{rejectedUser.user_fleet_size}</label>
                                </td>
                                <td>
                                  <label>{rejectedUser.user_country}</label>
                                </td>
                                <td>
                                  <label>{rejectedUser.contract}</label>
                                </td>
                                <td>
                                  <label>
                                    <span className="badge rounded-pill text-bg-danger text-capitalize regiBTN">
                                      {rejectedUser.user_status}
                                    </span>
                                  </label>
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deleteTransport(rejectedUser.user_id)
                                    }
                                    className="regiBTN"
                                    id="reject"
                                  >
                                    {/* <img src={tick_green} alt="" /> */}
                                    Delete
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default TransporterApproval;
