import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import user_1 from "../../assets/images/Dashboard/user_2.svg";
import document_image from "../../assets/images/document_image.png"
import { AppContext } from "../../context/user/AppContext";
const LoadDetails = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { mainClass } = useContext(AppContext);
  return (
    <main className={`LoadDetails-main ${mainClass}`} id="cxMain">
      <div
        className="tab-pane fade show active"
        id="pills-goodsdetails"
        role="tabpanel"
        aria-labelledby="pills-goodsdetails-tab"
      >
        {/* top  */}
        <div className="top-content common-body mt-4 d-flex justify-content-between align-items-center">
          <div className="driver-profile-details d-flex align-items-center">
            <div className="driver-name">
              <div className="title-driver">
                <h4 className="name-driver">Leroy Sane</h4>
              </div>
              <div className="rating">
                <img src={star_highlighted} alt="" />
                <img src={star_highlighted} alt="" />
                <img src={star_highlighted} alt="" />
                <img src={star_highlighted} alt="" />
                <img src={star_grey} alt="" />
                <span style={{color: "#191A1A", fontSize:"14px"}}>(320)</span>
              </div>
            </div>
          </div>
          <div className="view-detail-btn-load">
            <Link to="#" onClick={handleShow}>View Details</Link>
          </div>
        </div>
        {/* From details section */}
        <section className="from-details">
          <div className="common-body mt-4 p-3">
            <div className="row">
              <div className="top-content ">
                <h5 className="card-heading">From</h5>
                <hr />
              </div>
              <div className="col-lg-3 col-md-6 ">
                <label htmlFor="">Estimated Start Date</label>
                <p className="data">22-Feb-2022</p>
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">Estimated Start Time</label>
                <p className="data">10:30 AM</p>
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">Country</label>
                <p className="data">India</p>
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">City</label>
                <p className="data">Mumbai</p>
              </div>
              <div className="col-12">
                <label htmlFor="">Address</label>
                <p className="data">
                  No.2/28H, Near Majid, Sainiketan Colony, Kalas Area
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* To details section */}
        <section className="to-details">
          <div className="common-body mt-4 p-3">
            <div className="row">
              <div className="top-content ">
                <h5 className="card-heading">To</h5>
                <hr />
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">Estimated Start Date</label>
                <p className="data">22-Feb-2022</p>
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">Estimated Start Time</label>
                <p className="data">10:30 AM</p>
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">Country</label>
                <p className="data">India</p>
              </div>
              <div className="col-lg-3 col-md-6">
                <label htmlFor="">City</label>
                <p className="data">Mumbai</p>
              </div>
              <div className="col-12">
                <label htmlFor="">Address</label>
                <p className="data">
                  No.2/28H, Near Majid, Sainiketan Colony, Kalas Area
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Goods details */}
        <section className="goods-details mb-4">
          <div className="common-body mt-4 p-3">
            <div className="row">
              <div className="top-content ">
                <h5 className="card-heading">Goods Details</h5>
                <hr />
              </div>
              {/* left section */}
              <div className="col-lg-6">
                <div className="image">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={Skyscrapers}
                          className="d-block "
                          alt={"..."}
                        />
                      </div>
                      <div className="carousel-item">
                        <img src={Skyscrapers} className="d-block " alt="..." />
                      </div>
                      <div className="carousel-item">
                        <img src={Skyscrapers} className="d-block " alt="..." />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* right section */}
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-4 mt-4">
                    <label htmlFor="">Reference Number</label>
                    <p className="data">ERWER4354</p>
                  </div>
                  <div className="col-lg-4 mt-4">
                    <label htmlFor="">Company Name</label>
                    <p className="data">Goods & Services</p>
                  </div>
                  <div className="col-lg-4 mt-4">
                    <label htmlFor="">Cargo Type</label>
                    <p className="data">Container with Type</p>
                  </div>
                  <div className="col-lg-4 mt-4">
                    <label htmlFor="">Quantity</label>
                    <p className="data">
                      5{" "}
                      <Link to="#" style={{ color: "#4FBFA4" }}>
                        View Reference No.
                      </Link>
                    </p>
                  </div>
                  <div className="col-lg-4 mt-4">
                    <label htmlFor="">Delivery Type</label>
                    <p className="data">import</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Goods documents detail */}
        <section className="goods-documents-details">
          <div className="common-body mt-4 p-3">
            <div className="row">
              <div className="top-content">
                <h5 className="card-heading">Goods Details</h5>
                <hr />
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img" className="document-img" />
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img"  className="document-img"/>
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img"  className="document-img"/>
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img"  className="document-img"/>
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img"  className="document-img"/>
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img"  className="document-img"/>
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4">
                <label htmlFor="">Packing List</label>
                <div className="doc-img">
                  <img src={document_image} alt="document-img"  className="document-img"/>
                  <Link to="#">
                    <img src={ic_options_menu} alt="" width="24" height="24" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box popup-load-details "
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <h4 className="card-heading-ref-no">
          Reference Number


          </h4>
          <table >
            <thead>
              <tr>
                <td>
                  <label htmlFor="">S.No.</label>
                </td>
                <td>
                  <label htmlFor="">Reference No.</label>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="t-row">
                <td>
                  <p className="data m-0">1</p>
                </td>
                <td>
                  <p className="data m-0">ERWER4352334</p>
                </td>

              </tr>
              <tr className="t-row">
                <td>
                  <p className="data m-0">1</p>
                </td>
                <td>
                  <p className="data m-0">ERWER4352334</p>
                </td>

              </tr>
              <tr className="t-row">
                <td>
                  <p className="data m-0">1</p>
                </td>
                <td>
                  <p className="data m-0">ERWER4352334</p>
                </td>

              </tr>
              <tr className="t-row">
                <td>
                  <p className="data m-0">1</p>
                </td>
                <td>
                  <p className="data m-0">ERWER4352334</p>
                </td>

              </tr>

            </tbody>
          </table>
        </div>
      </Modal>
    </main>
  );
};

export default LoadDetails;
