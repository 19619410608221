import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
const ContractShipper = () => {
  const params = useParams();
  const [contract, setContract] = useState({})
  let id = params.id
  const { mainClass, customerData, setApiCalling } = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  $(document).ready(function () {
    $('').DataTable(

    );
  });
  useEffect(() => {
    getContracts();
  }, [])
  const getContracts = () => {
    setApiCalling(true)
    postWithAuthCallWithErrorResponse(ApiConfig.VIEW_CONTRACT_SHIPEER, JSON.stringify({ ...customerData, shipper_id: id }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false)
        if (res.json.result) {
          setContract(res.json.contract_details)
        }
      }).catch(err => {
        setApiCalling(false)
        console.log(err);
      })
  }
  return (
    <main className={`contract-main-section ${mainClass}`} id="cxMainB">

      {/* Shipper Contract Section */}
      <section className="shipper-contract-section  mb-4">
        <div className="contract-top-bar">
          <p className="contract-top-heading">
            Shippers Standard Contract Transport Market Place and Delivery
            System
          </p>
        </div>
        <div>
          <p className="contract-paragraph ">
            Shipper’s standard General contract that will be customized based on the type of required services whether it is Transport Market place or Delivery system.
          </p>
        </div>
        <div
          className="contract-rates"
          style={{ width: "100%", overflow: "auto" }}
        >
          <h4 className="shipper-rate-heading" >SHIPPING RATE(S)</h4>
          <table className="" style={{ width: "100%", overflow: "auto" }}>
            <thead>
              <tr>
                <td>
                  <label htmlFor="">S.No.</label>
                </td>
                <td>
                  <label htmlFor="">Type of Charges</label>
                </td>
                <td>
                  <label htmlFor="">
                    Per 20* if container are loaded paired
                  </label>
                </td>
                <td>
                  <label htmlFor="">Per 40gp</label>
                </td>
                <td>
                  <label htmlFor="">Per Quintal</label>
                </td>
                <td>
                  <label htmlFor="">Currency</label>
                </td>
                <td>
                  <label htmlFor="">Remarks</label>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="Rowbgcolor">
                <td>
                  <p className="data m-0">{1}</p>
                </td>
                <td>
                  <p className="data m-0">{contract.charge_type}</p>
                </td>
                <td>
                  <p className="data m-0">{contract.shipper_rateper_20gp}</p>
                </td>
                <td>
                  <p className="data m-0">{contract.shipper_rateper_40gp}</p>
                </td>
                <td>
                  <p className="data m-0">{contract.shipper_rateper_quintal}</p>
                </td>
                <td>
                  <p className="data m-0">{contract.currency}</p>
                </td>
                <td>
                  <p className="data m-0">{contract.shipper_remarks}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="button  text-right mt-4 mb-4" style={{ padding: "0 20px" }}>
          <a href={ApiConfig.BASE_URL_FOR_IMAGES + contract.shipper_contract} className="submit-btn " target="_blank">
            View Full Contract{" "}
          </a>
        </div>
      </section>
    </main>
  );
};

export default ContractShipper;
