import React, { useContext } from "react";
import { AppContext } from "../../context/user/AppContext";
const ReportReason = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <>
      {/* <Sidebar/>
         <AbhayHeader/> */}
      <main className={`main-section-reason ${mainClass}`} id="cxMain">
        <div className=" reportReason-mainBody row">
          <section className="reason-body col-md-4 ">
            <div className=" ">
              <span className="select-reason">Select a reason</span>
              <div className="check-reason col-12 d-flex justify-content-between mt-2">
                <p className="reason-paragraph">Lorem ipsum dolor sit amet.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </div>
              <hr className="hr" />
              <div className="check-reason col-12 d-flex justify-content-between mt-2">
                <p>Lorem ipsum dolor sit amet.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </div>
              <hr className="hr" />
              <div className="check-reason col-12 d-flex justify-content-between mt-2">
                <p>Lorem ipsum dolor sit amet.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </div>
              <hr className="hr" />
              <div className="check-reason col-12 d-flex justify-content-between mt-2">
                <p>Lorem ipsum dolor sit amet.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </div>
              <hr className="hr" />
              <div className="check-reason col-12 d-flex justify-content-between mt-2">
                <p>Lorem ipsum dolor sit amet.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </div>
              <hr className="hr" />
              <div className="check-reason col-12 d-flex justify-content-between mt-2">
                <p>Lorem ipsum dolor sit amet.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </div>
              <hr className="hr" />
              <div className="form-group mt-4">
                <label htmlFor="exampleFormControlTextarea1">Other</label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="2"
                  placeholder="Type here..."
                ></textarea>
              </div>
              <div className="button mt-4">
                <a href="" className="submit-btn">
                  Submit
                </a>
              </div>
            </div>
          </section>
        </div>
      </main>{" "}
    </>
  );
};

export default ReportReason;
