import React from 'react'

export default function OwnerDetails({ ownerDetails }) {
    return (
        < section className="owner-detail-section common-body p-3 mt-4 mb-4" >
            <div className="row">
                <div className="col-12">
                    <div className="top-content">
                        <h5 className="card-heading">Owner Details</h5>
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-sm-6 ">
                            <label>Full Name</label>
                            <p className="data">{ownerDetails && ownerDetails.user_name}</p>
                        </div>
                        <div className="col-lg-2 col-sm-6 ">
                            <label>Email</label>
                            <p className="data">{ownerDetails && ownerDetails.user_email}</p>
                        </div>
                        <div className="col-lg-2 col-sm-6 ">
                            <label>Phone Number</label>
                            <p className="data">{ownerDetails && ownerDetails.user_mobile}</p>
                        </div>
                        <div className="col-lg-2 col-sm-6 ">
                            <label>Mobile Number</label>
                            <p className="data">{ownerDetails && ownerDetails.user_mobile}</p>
                        </div>
                        <div className="col-lg-2 col-sm-6 ">
                            <label>City</label>
                            <p className="data">{ownerDetails && ownerDetails.user_city}</p>
                        </div>
                        <div className="col-lg-2 col-sm-6  ">
                            <label>Country</label>
                            <p className="data">{ownerDetails && ownerDetails.user_country}</p>
                        </div>
                        {/* <div className="col-lg-2 col-sm-6 ">
                            <label>Birthdate</label>
                            <p className="data">{ownerDetails && ownerDetails.user_dob}</p>
                        </div> */}
                        {/* <div className="col-lg-2 col-sm-6  ">
                            <label>Gender</label>
                            <p className="data">{ownerDetails && ownerDetails.user_gender}</p>
                        </div> */}
                        {/* <div className="col-lg-6 col-sm-6 ">
                            <label>Address</label>
                            <p className="data">
                                {ownerDetails && ownerDetails.user_address}
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
        </section >
    )
}
