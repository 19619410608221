import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import swal from "sweetalert";
const MatchMakingOnlineAuction = () => {
  const { customerData, setApiCalling, ApiCaaling } = useContext(AppContext);
  const [transporter, setTransporter] = useState([])
  const [cargoList, setCargoList] = useState([]);
  let data = JSON.parse(localStorage.getItem("request"))
  const [load_id, setLoadid] = useState(data.trip_id)
  const [vehicleList, setVehicleList] = useState([])
  const [vehicle_cargo_mapping, setVehicle_cargo_mapping] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const navigate = useNavigate()
  useEffect(() => {
  }, [vehicle_cargo_mapping])
  useEffect(() => {
    if (ApiCaaling) document.getElementById("continue-btn").disabled = true
    else document.getElementById("continue-btn").disabled = false
  }, [ApiCaaling])
  useEffect(() => {
    setApiCalling(true)
    postWithAuthCallWithErrorResponse(ApiConfig.MATCH_MAKING_TRANSPORTERLIST, JSON.stringify({ ...customerData, load_id }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false)
        if (res.json.result) {

          setTransporter(res.json.transporter_list[0])
          setCargoList(res.json.cargo_list)
          postWithAuthCallWithErrorResponse(ApiConfig.MATCH_MAKING_VEHICLES_BYTRANSPORT, JSON.stringify({ ...customerData, load_id, transporter_id: res.json.transporter_list[0].transporter_id }))
            .then(res => {
              if (res.json.message === 
                "Invalid user authentication,Please try to relogin with exact credentials.") {
                  localStorage.clear();
                  window.open("/login", "_self");
              }
              if (res.json.result) {
                setVehicleList(res.json.vehicle_list);
                setSelectedVehicle(res.json.vehicle_list[res.json.vehicle_list.length - 1].vehicle_id)
              }
            }).catch(err => {
              console.log(err);
            })
        }
      }).catch(err => {
        console.log(err);
      })
  }, [])

  const submit = () => {
    if (!vehicle_cargo_mapping.length) {
      swal("please assign the cargo atlease once")
    } else {
      setApiCalling(true)
      postWithAuthCallWithErrorResponse(ApiConfig.VEHICLE_CARGO_MATCHMAKING, JSON.stringify({ ...customerData, load_id: load_id, vehicle_cargo_mapping }))
        .then(res => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setApiCalling(false)
          if (res.json.result) {
            swal(res.json.message)
            navigate("/OnlineAuctionHeader")
          }
        })
    }

  }
  return (
    <main className="match-making cx-active-sidebar" id="cxMain">
      <div className="row justify-content-start " style={{ marginBottom: "80px" }}>
        {/* Select Vehicles tab */}
        <div
          className="tab-content match-making-tab-body-select-transporter col-lg-4"
          id="v-pills-tabContent1"
        >
          <h5
            className="match-making-card-heading mb-3"
            style={{ padding: "20px 20px 0 20px" }}
          >
            Select Vehicle
          </h5>
          <div className="sub-heading-matchmaking mb-3">
            <p>Transporter Details</p>
          </div>
          <div
            className="tab-pane fade show active mb-3"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <div
              className="tab-pane fade show "
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div
                className="nav flex-column nav-pills   "
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link "
                  id="v-pills-home-tab11"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home1"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home1"
                  aria-selected="true"
                >
                  <div className="d-flex ">
                    <div className="img-select-transport">
                      <img
                        src={ApiConfig.BASE_URL_FOR_IMAGES + transporter.user_profile_pic}
                        alt=""
                        className="rounded-circle"
                        height="36"
                        width="36"
                      />
                    </div>
                    <div className="ms-3">
                      <div className="text-start">

                        <span className="data-match-making " style={{ fontSize: "16px" }}>
                          {transporter.user_name}
                        </span>
                      </div>
                      <div className="text-start">
                        <label htmlFor="">Tariff</label>
                        <span className="data-match-making ms-2">
                          ${transporter.price}
                        </span>
                      </div>
                      <div className="text-start">
                        <label htmlFor="">Destination</label>
                        <span className="data-match-making ms-2">
                          {transporter.destinaiton}
                        </span>
                      </div>

                    </div>
                  </div>
                </button>



              </div>
            </div>
          </div>
          <div className="sub-heading-matchmaking mb-3">
            <p>Vehicle List</p>

          </div>

          <div
            className="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            {
              vehicleList.map((vehicle, index) => {
                return (
                  <div
                    className="tab-pane fade show "
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                    key={"vehicle" + index}
                  >
                    <div
                      className="nav flex-column nav-pills   "
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        onClick={() => setSelectedVehicle(vehicle.vehicle_id)}
                        className="nav-link "
                        id="v-pills-home-tab11"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home1"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home1"
                        aria-selected="true"
                      >
                        <div className="d-flex ">
                          <div className="img-select-transport">
                            <img
                              src={ApiConfig.BASE_URL_FOR_IMAGES + vehicle.vehicle_image}
                              alt=""
                              className="rounded-circle"
                              height="36"
                              width="36"
                            />
                          </div>
                          <div className="ms-3">
                            <div className="text-start">
                              <label htmlFor="">Reference Number</label>
                              <span className="data-match-making ms-2">
                                {vehicle.vehicle_id}
                              </span>
                            </div>
                            <div className="text-start">
                              <label htmlFor="">Model</label>
                              <span className="data-match-making ms-2">
                                {vehicle.vehicle_model_no}
                              </span>
                            </div>
                            <div className="text-start">
                              <label htmlFor="">Plate No.</label>
                              <span className="data-match-making ms-2">
                                {vehicle.plate_number}
                              </span>
                            </div>
                            <div className="text-start">
                              <label htmlFor="">Capacity (Quintals)</label>
                              <span className="data-match-making ms-2">{vehicle.vehicle_capacity} ton</span>
                            </div>
                          </div>
                        </div>
                      </button>
                      <hr />

                    </div>
                  </div>
                )

              })
            }

          </div>

        </div>

        {/* Assign Cargo card */}
        <div
          className="tab-content  match-making-tab-body-select-transporter col-lg-4"
          id="v-pills-tabContent"
        >
          <h5
            className="match-making-card-heading "
            style={{ padding: "20px 20px 0 20px" }}
          >
            Assign Cargo
          </h5>
          <hr />
          <div
            className="tab-pane fade show active"
            id="v-pills-home1"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab1"
          >
            {cargoList.map((cargo, index) => {
              return (
                <React.Fragment key={"cargo" + index}>
                  <div className="d-flex justify-content-between ps-4 pe-4 pt-4">
                    <p>{cargo.cargo_type_ref_no}</p>
                    <input type="checkbox" className="form-check-input" checked={
                      vehicle_cargo_mapping.length ? vehicle_cargo_mapping.filter(vehicle => {
                        return vehicle.cargo_id === cargo.cargo_type_id && vehicle.vehicle_id === selectedVehicle
                      }).length && true
                        : false

                    } name="" id="" onChange={(e) => {
                      if (e.target.checked) {
                        let mapping = {
                          vehicle_id: selectedVehicle,
                          cargo_id: cargo.cargo_type_id,
                          transporter_id: transporter.transporter_id
                        }
                        let mappingList = [...vehicle_cargo_mapping, mapping];
                        setVehicle_cargo_mapping(mappingList)
                      }
                      else {
                        let mappingList = vehicle_cargo_mapping.filter(vehicle => {
                          return vehicle.cargo_id !== cargo.cargo_type_id
                        }
                        )
                        setVehicle_cargo_mapping(mappingList)
                      }
                    }} />
                  </div>
                  <hr />
                </React.Fragment>
              )
            })}
          </div>
          <div
            className="tab-pane fade show "
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            ..222.
          </div>
          <div
            className="tab-pane fade show "
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            ..333.
          </div>
        </div>
      </div>

      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button type="cancel" className="cancel-btn">
              Cancel
            </button>
          </a>
          {/* <Link to="/OnlineAuctionHeader"> */}
          <button type="submit" className="continue-btn" id="continue-btn" onClick={submit}>
            Submit
          </button>
          {/* </Link> */}
        </div>
      </div>
    </main>
  );
};

export default MatchMakingOnlineAuction;
