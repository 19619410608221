import React from "react";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link } from "react-router-dom";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import ApiConfig from "../../api/ApiConfig";
import { useState } from "react";
import Loader from "../../sharedComponent/Loader";

const CompleteFreights = ({ frights }) => {
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        <div className="mb-4 d-flex justify-content-between freights-search-select-main ">
          <input
            className="form-control freights-search-select "
            placeholder="Search..."
            type="search"
            aria-label="default input example"
          />

          <select
            className="form-select freights-search-select "
            aria-label="Default select example"
          >
            <option selected>My Vehicles</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <React.Fragment>
          <div style={{ margin: "20px" }}>
            <div className="  col-md-6 ">
              <div className="">
                <div
                  className=""
                  style={{
                    width: "1140px",
                    overflow: "auto",
                    height: "83vh",
                  }}
                >
                  {/* <ProgressBar progress={progress} /> */}
                  {loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <table className="dataList">
                      <thead>
                        <tr>
                          <th>{/* <label>Start City</label> */}</th>
                          <th>
                            <label>Start City</label>
                          </th>
                          <th>
                            <label>Offered By</label>
                          </th>
                          <th>
                            <label>Cargo Type</label>
                          </th>
                          <th>
                            <label>Quantity</label>
                          </th>
                          <th>
                            <label>Est. Start Dt.</label>
                          </th>
                          <th>
                            <label>Started On</label>
                          </th>
                          <th>
                            <label>Status</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {frights.map((freight, index) => {
                          return (
                            <tr
                              className="Rowbgcolor p-2"
                              key={"freight" + index}
                            >
                              <td>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex justify-content-between">
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                      }}
                                      src={
                                        ApiConfig.BASE_URL_FOR_IMAGES +
                                        freight.trip_image
                                      }
                                      alt=""
                                      className="card-rsponsive-img"
                                    />
                                    <h5 className="my-free ms-3">
                                      {freight.trip_reference_no}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {`${freight.trip_start_city} ${freight.trip_start_country}-${freight.trip_end_city} ${freight.trip_end_country}`}
                              </td>
                              <td
                                className="offered-by text-decoration-underline"
                                id="trip_start_date"
                              >
                                {freight.offered_by} ...
                              </td>
                              <td id="trip_status">{freight.cargo_type}</td>
                              <td id="trip_status">
                                {" "}
                                {freight.quantity} {freight.unit}.
                              </td>
                              <td id="trip_status">
                                {" "}
                                {freight.trip_start_date}
                              </td>
                              <td id="trip_status">
                                {" "}
                                {freight.trip_start_date}
                              </td>
                              <td
                                id="trip_status"
                                className="freights-card-status"
                              >
                                {" "}
                                {freight.trip_status}
                              </td>

                              <td id="">
                                <div className="ongoing-dropdown dropdown ">
                                  <a
                                    href=""
                                    className=""
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <img className="" src={ic_options} alt="" />
                                  </a>
                                  <ul className="dropdown-menu ">
                                    <li>
                                      <Link
                                        to={"/ViewDetails/" + freight.trip_id}
                                        state={frights}
                                        className="text-decoration-none"
                                      >
                                        <button
                                          className="dropdown-item "
                                          type="button"
                                        >
                                          View Details
                                        </button>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/SelectReportTypes"
                                        className="text-decoration-none"
                                      >
                                        <button
                                          className="dropdown-item "
                                          type="button"
                                        >
                                          Reports
                                        </button>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default CompleteFreights;
