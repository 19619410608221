import React, { useContext } from "react";
import { Link } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import { AppContext } from "../../context/user/AppContext";
const SelectReportTypes = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <>
      {/* <Sidebar />
      <AbhayHeader /> */}
      <main
        className={`main-section-reportTypes ${mainClass}`}
        id="cxMainB"
      >
        <section className="report-type-body">
          <div className="row">
            {/* select report types section*/}
            <div className="reportTypes-section col-md-4">
              <div className="row">
                <div className="col-12">
                  <div className="top">
                    <h3 className="top-heading">Select Reports Type</h3>
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <div className="link-list ">
                    <Link to="/Reports" id="active-link">
                      Vehicle Tracking & Tracing
                    </Link>
                    <img src={dropdown_arrow_blue} className="arrow" alt="" />

                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <div className="link-list">
                    <a href="">Payment Status</a>
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <div className="link-list">
                    <a href="">Drivers Report</a>
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <div className="link-list">
                    <a href="">Vehicle Working With Abay</a>
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <div className="link-list">
                    <a href="">History</a>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            {/* reports section */}
            <div className="main-section-reports col-md-8">
              <main
                className={`main-section-reports ${mainClass}`}
                id="cxMain"
              >
                <section className="report-body">
                  <div className="row">
                    <div className="reports-body-section col-md-4 col-sm-5">
                      <h4 className="reports-card-heading">
                        Configure Reports
                      </h4>
                      <hr />
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">Report Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">From Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="exampleInputEmail1">To Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <a href="" className="report-btn col-12">
                        Generate
                      </a>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default SelectReportTypes;
