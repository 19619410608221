import React, { useContext, useEffect, useState } from 'react'
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from '../../context/user/AppContext';
import { Link, useParams } from 'react-router-dom';
import { postWithAuthCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import Loader from '../../sharedComponent/Loader';
const ContractTransporter = () => {
  const { mainClass, customerData, setApiCalling } = useContext(AppContext);
  const [contractDetails, setContractDetails] = useState({})
  const params = useParams();
  const [loading, setLoading] = useState(false)
  let id = params.id
  $(document).ready(function () {
    $('.dataList').DataTable(

    );
  });
  useEffect(() => {
    getContract()
  }, [])
  const getContract = () => {
    setApiCalling(true)
    postWithAuthCallWithErrorResponse(ApiConfig.TRANSPORTER_VIEW_CONTRACT, JSON.stringify({ ...customerData, transporter_id: id }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        if (res.json.result) {
          setContractDetails(res.json.contract_details)
        }
      }).catch(err => {
        setApiCalling(false);
        console.log(err);
      })
  }
  return (
    <main className={`contract-main-section ${mainClass}`} id="cxMainB">
      {/* Transporter Contract section */}
      {loading ? <Loader loading={loading} /> :
        <section className="transporter-contract-section">
          <div className="contract-top-bar">
            <p className="contract-top-heading">
              Transporter’s General Standard Contract (specifically for the
              Transporter)
            </p>
          </div>
          <div>
            <p className="contract-paragraph ">
              This Standard general agreement will be customized based on the
              type of agreement between transporters. It will be for one time or
              contract period; for transport Market place or Delivery of cargo;
              Customers who need the whole service of Abay or vehicle only.
            </p>
          </div>
          <div className="contract-rates" style={{ width: "100%", overflow: "auto" }} >
            <h4 className="shipper-rate-heading">TRANSPORTATION RATE(S)</h4>
            <table className=" " style={{ width: "100%", overflow: "auto" }}>
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">S.No.</label>
                  </td>
                  <td>
                    <label htmlFor="">Origin</label>
                  </td>
                  <td>
                    <label htmlFor="">Final Destination Point</label>
                  </td>
                  <td>
                    <label htmlFor="">
                      Weight (Metric, Ton, Quintals, etc.)
                    </label>
                  </td>
                  {/* <td>
                    <label htmlFor="">Transit time (Day)</label>
                  </td> */}
                  <td>
                    <label htmlFor="">Rate Per 20ft Container</label>
                  </td>
                  <td>
                    <label htmlFor="">Rate Per 40ft Container</label>
                  </td>
                  <td>
                    <label htmlFor="">Rate/Quintal</label>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr className="t-row">
                  <td>
                    <p className="data m-0">1</p>
                  </td>
                  <td>
                    <p className="data m-0">{contractDetails && contractDetails.transporter_orgin}</p>
                  </td>
                  <td>
                    <p className="data m-0">{contractDetails && contractDetails.transporter_final_destination}</p>
                  </td>
                  <td>
                    <p className="data m-0">{contractDetails && contractDetails.transporter_weight}</p>
                  </td>
                  {/* <td>
                    <p className="data m-0">10sdfddf</p>
                  </td> */}
                  <td>
                    <p className="data m-0">{contractDetails && contractDetails.transporter_rateper_20ft}</p>
                  </td>
                  <td>
                    <p className="data m-0">{contractDetails && contractDetails.transporter_rateper_40ft}</p>
                  </td>
                  <td>
                    <p className="data m-0">{contractDetails && contractDetails.transporter_rateper_quintal}</p>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div className="button  text-right mt-4 mb-4" style={{ padding: "0 20px" }}>
            <a href={ApiConfig.BASE_URL_FOR_IMAGES + contractDetails.transporter_contract} target="_blank" className="submit-btn ">
              View Full Contract            </a>
          </div>
        </section>
      }

    </main>
  )
}

export default ContractTransporter