import React, { useContext } from "react";
import user_1 from "../../assets/images/Dashboard/user_1.svg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import { AppContext } from "../../context/user/AppContext";

const Rating = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <main className={`rating-main ${mainClass}`} id="cxMain">
      <section className="rating-body">
        <div className="rating-content text-center">
          <h4 className="name-rating">RATE TRANSPORTER</h4>
          <div className="img-rating">
            <img src={user_1} alt="" />
          </div>
          <div>
            <p className="user-name mt-2">Albie Morkel <br /> <span className="user-location">Visharantwadi, Pune</span></p>
            <p ></p>
          </div>
          <div className="rating-star">
            <img src={star_highlighted} alt="" />
            <img src={star_highlighted} alt="" />

            <img src={star_highlighted} alt="" />
            <img src={star_highlighted} alt="" />
            <img src={star_grey} alt="" />
          </div>
          <label htmlFor="" className="mt-3 mb-3">GOOD EXPERIENCE</label>
          <div className="form-group mt-4">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="4"
              placeholder="Type your review"
            ></textarea>
          </div>
          <div className="button mt-4 text-center">
            <a href="" className="submit-btn ">
              Rate
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Rating;
