import React, { useContext, useState } from "react";
import { AppContext } from "../../context/user/AppContext";
import { Link } from "react-router-dom";
import arrow from "../../assets/images/dropdown_arrow_blue.svg";
import { postMultipartWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

const AdminSetting = () => {
  const [forgot, setforgot] = useState(false);
  const [otp, setotp] = useState(false);
  const handlestate = () => setotp(true);
  const { mainClassB, setApiCalling, customerData } = useContext(AppContext);
  const [state, setState] = useState({
    new_password: "",
    current_password: "",
  });
  const [errMsg, setErrMsg] = useState({
    new_password: "",
    current_password: "",
  });
  const changePassword = () => {
    setApiCalling(true);

    if (state.current_password === "") {
      setErrMsg({
        ...errMsg,
        current_password: "Current Password is required",
      });
      // window.scrollTo(0, 0);
      return;
    }
    if (state.new_password === "") {
      setErrMsg({ ...errMsg, new_password: "New password is required" });
      // window.scrollTo(0, 0);
      return;
    } else {
      setState({
        user_id: customerData.user_id,
        api_key: customerData.api_key,
        new_password: state.new_password,
        current_password: state.current_password,
      });
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.CHANGE_PASSWORD,
        JSON.stringify({ ...customerData, ...state })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          if (res.json.result) {
            swal({
              title: "success",
              text: res.json.message,
              icon: "success",
              button: true,
            });
          } else if (res.json.result === false)
            swal({
              title: "warning",
              text: res.json.message,
              icon: "warning",
              button: true,
            });

          setApiCalling(false);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <main className={`main-settings main-section-reports  ${mainClassB}`}>
      <div className="d-flex  " id="main-tab-setting">
        <div className="over-scroll">
          <div
            className="nav flex-column nav-pills reports-tab"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-password-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-password"
              type="button"
              role="tab"
              aria-controls="v-pills-password"
              aria-selected="true"
            >
              Update Profile Password
              <img src={arrow} alt="" className="arrow-orange" />
            </button>

            <button
              className="nav-link"
              id="v-pills-term-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-term"
              type="button"
              role="tab"
              aria-controls="v-pills-term"
              aria-selected="false"
            >
              Terms & Conditions
              <img src={arrow} alt="" className="arrow-orange" />
            </button>
            <button
              className="nav-link "
              id="v-pills-privacy-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-privacy"
              type="button"
              role="tab"
              aria-controls="v-pills-privacy"
              aria-selected="false"
            >
              Privacy Policy
              <img src={arrow} alt="" className="arrow-orange" />
            </button>
            <button
              className="nav-link "
              id="v-pills-about-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-about"
              type="button"
              role="tab"
              aria-controls="v-pills-about"
              aria-selected="false"
            >
              About Us
              <img src={arrow} alt="" className="arrow-orange" />
            </button>
          </div>
        </div>

        <div className="tab-content " id="v-pills-tabContent">
          <div
            className="tab-pane fade show active general-setting"
            id="v-pills-password"
            role="tabpanel"
            aria-labelledby="v-pills-password-tab"
          >
            <div
              className="main-updatePass-setting"
              id="main-updatePass-setting"
            >
              {/* <ForgotPassword />
              {state && <ForgotOTP />} */}

              <div className="right-content ">
                <p className="welcome-login">Please enter your Password</p>
                <form action="">
                  <div className="mb-3 row mt-4">
                    <label
                      htmlFor="inputEmail"
                      className="col-sm-12 col-form-label"
                    >
                      Current Password
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        required
                        value={state.current_password}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, current_password: "" });
                          setState({
                            ...state,
                            current_password: e.target.value,
                          });
                        }}
                      />
                      {errMsg.current_password.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.current_password}</span>
                        </div>
                      )}
                      <span className="text-danger"></span>
                    </div>
                  </div>
                  <div className="mb-3 row mt-4">
                    <label
                      htmlFor="inputEmail"
                      className="col-sm-12 col-form-label"
                    >
                      New Password
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        required
                        value={state.new_password}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, new_password: "" });
                          setState({
                            ...state,
                            new_password: e.target.value,
                          });
                        }}
                      />
                      {errMsg.new_password.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.new_password}</span>
                        </div>
                      )}
                      <span className="text-danger"></span>
                    </div>
                  </div>
                  <div className="login-btn mb-3">
                    <Link
                      to="#"
                      className=" col-sm-12 col-md-12 col-lg-12"
                      onClick={changePassword}
                    >
                      CONTINUE
                    </Link>
                  </div>
                </form>
                <div className="GobackBtn-title">
                  <Link to="#" className="Go-back-btn">
                    Go Back
                  </Link>
                </div>
              </div>

              {/* {setotp && (
                <div className="right-content ">
                  <p className="welcome-login">
                    Please enter your email id to continue
                  </p>
                  <form action="">
                    <div className="mb-3 row mt-4">
                      <label
                        htmlFor="inputEmail"
                        className="col-sm-12 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail"
                          required
                        />
                        <span className="text-danger"></span>
                      </div>
                    </div>
                    <div className="login-btn mb-3">
                      <Link
                        to="/ForgotOTP"
                        className=" col-sm-12 col-md-12 col-lg-12"
                      >
                        CONTINUE
                      </Link>
                    </div>
                  </form>
                  <div className="GobackBtn-title">
                    <Link to="#" className="Go-back-btn">
                      Go Back
                    </Link>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div
            className="tab-pane fade users-setting"
            id="v-pills-term"
            role="tabpanel"
            aria-labelledby="v-pills-term-tab"
          >
            <div className="term-setting-main p-4 ">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
            </div>
          </div>
          <div
            className="tab-pane fade users-setting"
            id="v-pills-privacy"
            role="tabpanel"
            aria-labelledby="v-pills-privacy-tab"
          >
            <div className="term-setting-main p-4 ">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
            </div>
          </div>
          <div
            className="tab-pane fade users-setting"
            id="v-pills-about"
            role="tabpanel"
            aria-labelledby="v-pills-about-tab"
          >
            <div className="term-setting-main p-4 ">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminSetting;
