import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ic_minus from "../../assets/images/ic_minus.svg";
import ic_add from "../../assets/images/ic_add.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

const AddVehicleSpecification = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { mainClass, customerData, sidebar, currentVehicleRequest, setApiCalling } = useContext(AppContext);
  let data = JSON.parse(localStorage.getItem("currentVehicleRequest"))
  const [vehicleRequest, setVehicleRequest] = useState(data ? { ...data } : {});
  const [vehicleList, setVehicleList] = useState([])
  const [vehicle_availability_date, setVehicle_availability_date] = useState("")
  const [selectedVehicle, setSelectdVehicle] = useState({})
  const [vehicleTypes, setVehicleTypes] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    getVehicleTypes();
  }, [])
  useEffect(() => {
  }, [selectedVehicle])

  const getVehicleTypes = () => {
    setApiCalling(true)
    postWithAuthCallWithErrorResponse(ApiConfig.VEHICLE_DROPDOWNLIST, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false)
        if (res.json.result) {
          setVehicleTypes(res.json.vehicle_name)
        }
      }).catch(err => {
        setApiCalling(false)
        console.log(err);
      })
  }
  return (
    <main className={`main-AddVehicleSpecification ${mainClass}`} id="cxMain">
      <section className="AddVehicleSpecification-top-btn">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="">Vehicle Availability Date</label>
              <td>
                <input
                  type="date"
                  className="form-control vehicleSpecification-input mt-2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => { setVehicle_availability_date(e.target.value) }}
                />
              </td>
            </div>
          </div>
        </div>
      </section>
      <section className="table-section">
        <div
          className=""
          style={{
            overflow: "auto",
            padding: "30px 0",
            height: "500px",
          }}
        >
          <table className=" " style={{ minWidth: "600px", width: "auto" }}>
            <thead>
              <tr>
                <td>
                  <label>S.No</label>
                </td>
                <td>
                  <label>Vehicle Type</label>
                </td>
                <td>
                  <label>Capacity</label>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td>
                  <input
                    type="text"
                    className="form-control vehicleSpecification-input"
                    id="vehicleSpecification-input"
                    aria-describedby="emailHelp"
                  />
                </td>
                <td>
                  <select
                    className="form-select vehicleSpecification-input"
                    aria-label="Default select example"
                    onChange={(e) => {
                      let value = vehicleTypes.filter(vehicle => vehicle.vehicle_name_id == e.target.value)[0]
                      setSelectdVehicle({ ...selectedVehicle, ...value })
                    }}
                  >
                    <option selected>Select Vehicle Type</option>
                    {vehicleTypes.map((vehicle, index) => {
                      return (
                        <option value={vehicle.vehicle_name_id} key={"vehicleType" + index}>{vehicle.vehicle_name_value}</option>
                      )
                    })}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control vehicleSpecification-input"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={selectedVehicle.capacity}
                    onChange={(e) => { setSelectdVehicle({ ...selectedVehicle, capacity: e.target.value }) }}

                  />
                </td>

                <td>
                  <button className="minus-icon" onClick={() => {
                    if (!selectedVehicle.capacity || !selectedVehicle.vehicle_name_value) {
                      swal("Please Add capacity and select vehicle type")
                    }
                    else {
                      let list = [...vehicleList, selectedVehicle]
                      setVehicleList(list)
                      setSelectdVehicle({ ...selectedVehicle, capacity: "", })
                    }
                  }}>
                    <img src={ic_add} alt="" />
                  </button>
                </td>
              </tr>
              {vehicleList.map((veh, index) => {
                return (
                  <tr className="" key={"vehicles" + index}>
                    <td>
                      <input
                        type="text"
                        className="form-control vehicleSpecification-input"
                        id="vehicleSpecification-input"
                        value={index + 1} readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control vehicleSpecification-input"
                        value={veh.vehicle_name_value} readOnly
                      />

                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control vehicleSpecification-input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={veh.capacity} readOnly
                      />
                    </td>

                    <td>
                      <button className="minus-icon" onClick={() => {
                        let list = vehicleList.filter((vehicle, filterindex) => filterindex !== index);
                        setVehicleList(list)
                      }}>
                        <img src={ic_minus} alt="" />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="fix-offer-vehicles" style={{ width: !sidebar ? "95%" : "80%" }}>
          <div className="d-flex p-2 mb-4 justify-content-between">
            <div className=" ">
              <label htmlFor="">Reference Number</label>
              <p className="data">{vehicleRequest.load_reference_no}</p>
            </div>
            {/* <div className=" ">
                <label htmlFor="">Goods Name</label>
                <p className="data">{vehicleRequest.goods_name}</p>
              </div> */}
            <div className=" ">
              <label htmlFor="">Company Name</label>
              <p className="data">{vehicleRequest.company_name}</p>
            </div>
            <div className="">
              <label htmlFor="">Cargo Type</label>
              <p className="data">{vehicleRequest.cargo_type}</p>
            </div>
            <div className="">
              <label htmlFor="">Quantity</label>
              <p className="data ">{vehicleRequest.quantity}</p>
              {/* <Link to="#" className="ms-2 ViewSpecifications-link">
                  View Specifications
                </Link> */}
            </div>
            <div className="">
              <label htmlFor="">Delivery Type</label>
              <p className="data ">{vehicleRequest.delivery_type}</p>
            </div>
          </div>
          <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
            <div className="left"></div>
            <div className="right">
              <a href="">
                <button type="cancel" className="cancel-btn">
                  Cancel
                </button>
              </a>
              <Link to="#" onClick={handleShow}>
                <button type="submit" className="continue-btn">
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>


      </section>

      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box vehicle-requests-assign-transporter-popup "
      >
        <Modal.Header closeButton></Modal.Header>
        <div>
          <p className="text-center popup-heading mt-4 mb-4">
            Select what you want to do
          </p>
        </div>
        <div className="btn-popup-modal text-center">
          <button className="popup-reject-btn m-1 " onClick={() => {
            if (!vehicleList.length || !vehicle_availability_date)
              swal("Select vehicle and Vehicle Availability Date")
            else {
              localStorage.setItem("request", JSON.stringify({ requirements: vehicleList, vehicle_availability_date, vehicleRequest }))
              navigate("/SelectTransporter")
            }
          }}>
            Assign Transporters
            {/* <Link to="/SelectTransporter" ></Link> */}
          </button> <br />
          <button className="popup-approve-btn m-1 " onClick={() => {
            if (!vehicleList.length || !vehicle_availability_date.length)
              swal("Select vehicle and Vehicle Availability Date")
            else {
              localStorage.setItem("request", JSON.stringify({ requirements: vehicleList, vehicle_availability_date, vehicleRequest }))
              navigate("/OfferVehicleListing")
            }
          }}>
            Offer Vehicles
            {/* <Link
              to="/OfferVehicleListing"
              className="text-decoration-none"
              style={{ color: "#4FBFA4" }}
            >
              
            </Link> */}
          </button>
        </div>
      </Modal>
    </main >
  );
};

export default AddVehicleSpecification;
